import React from 'react';
import PropTypes from 'prop-types';

import CategoryTitle from '../CategoryTitle';

import { Container } from './styles';

export default function SimpleNew({
  banner,
  title,
  isVertical,
  date,
  url,
  ...rest
}) {
  return (
    <Container
      to={`/noticias/${url}`}
      isvertical={String(isVertical)}
      {...rest}
    >
      <div className="img-wrapper">
        <img src={banner} alt="Notícia destaque" />
      </div>

      <div className="content">
        <CategoryTitle withoutCategory title="Esportes" date={date} />

        <h3>{title}</h3>
      </div>
    </Container>
  );
}

SimpleNew.defaultProps = {
  isVertical: false,
  date: '',
  url: 'https://abqm.com.br/abqm_site/',
};

SimpleNew.propTypes = {
  banner: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isVertical: PropTypes.bool,
  date: PropTypes.string,
  url: PropTypes.string,
};
