import styled from 'styled-components';
import media from 'styled-media-query';
import { FaPlay } from 'react-icons/fa';
import IframeLib from 'react-iframe';

import { pxToRem } from '@abqm/functions';
import { colors, metrics, fonts } from '@abqm/theme';
import { ContentWrap as Content } from '@abqm/components';

import ButtonGradient from '../ButtonGradient';

export const Container = styled(Content)`
  margin: 0 auto;
  padding: 0 ${metrics.baseSpacing(10)} ${pxToRem(150)} ${metrics.baseSpacing(
  10
)};

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  text-align: center;
  color: ${colors.white};

  ${media.lessThan('large')`
    display: flex;
    flex-direction: column;

    text-align: left;
  `}

  ${media.lessThan('medium')`
    padding: ${pxToRem(60)} ${metrics.baseSpacing(2)};
  `}

  > .left {
    width: 38%;
    margin-right: 2%;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    ${media.lessThan('large')`
      width: 100%;
      margin-right: 0;
    `}

    > .logo {
      height: ${pxToRem(140)};
      margin-bottom: ${metrics.baseSpacing(6)};

      ${media.lessThan('large')`
        margin-bottom: ${metrics.baseSpacing(3)};
      `}

      ${media.lessThan('small')`
        height: ${pxToRem(120)};
      `}
    }

    > .title {
      position: relative;

      margin-bottom: ${metrics.baseSpacing(10)};

      ${fonts.size7};
      font-weight: 100;
      line-height: 1.19;
      color: ${colors.fadeGrey};
      font-family: SFProDisplay-Thin, sans-serif;

      ${media.lessThan('large')`
        margin-bottom: ${metrics.baseSpacing()};
      `}

      ${media.lessThan('small')`
        ${fonts.size6};
        font-weight: 600;
        line-height: 1.21;
        font-family: SFProDisplay-SemiBold, sans-serif;
      `}

      &:after {
        position: absolute;
        bottom: ${pxToRem(-41)};
        left: 50%;
        transform: translate(-50%, -50%);

        height: 2px;
        width: 100px;
        border-radius: 4px;

        display: block;

        content: '';
        background: ${colors.fadedGrey};

        ${media.lessThan('large')`
          display: none;
        `}
      }
    }

    > .description {
      width: 85%;
      margin-bottom: ${metrics.baseSpacing(5)};

      display: -webkit-box;

      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      ${fonts.size3};
      line-height: 1.69;
      color: ${colors.fadeGrey};
      font-family: SFProDisplay-Regular, sans-serif;

      ${media.lessThan('large')`
        width: 100%;
      `}

      ${media.lessThan('small')`
        opacity: 0.6;
      `}
    }
  }

  > .right {
    width: 60%;

    ${media.lessThan('large')`
      width: 100%;
    `}

    > .video-shade {
      height: ${pxToRem(539)};
      padding: ${metrics.baseSpacing()};
      border-radius: ${metrics.borderRadius()};

      background: rgba(255, 255, 255, 0.18);

      ${media.lessThan('large')`
        height: ${pxToRem(452)};
      `}

      ${media.lessThan('medium')`
        height: ${pxToRem(418)};
      `}

      ${media.lessThan('small')`
        height: ${pxToRem(202)};
      `}

      > .video {
        width: 100%;
        height: 100%;
        border-radius: ${metrics.borderRadius()};

        display: flex;
        align-items: center;
        justify-content: center;

        background: #151515;

        > .play {
          border-radius: 50%;
          width: ${pxToRem(130)};
          height: ${pxToRem(130)};

          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;
          transition: 0.3s;
          background: rgba(255, 255, 255, 0.47);

          &:hover {
            transform: scale(1.05);
          }

          ${media.lessThan('small')`
            width: ${pxToRem(60)};
            height: ${pxToRem(60)};
          `}
        }
      }
    }
  }
`;

export const Button = styled(ButtonGradient)`
  height: ${pxToRem(43)};
  width: ${pxToRem(152)};

  display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};

  ${fonts.size3};

  ${media.lessThan('large')`
    font-size: 1.1rem;
    font-family: SFProDisplay-Regular;
    margin: ${metrics.baseSpacing(3)} auto 0;
    display: ${({ isMobile }) => (!isMobile ? 'none' : 'flex')};
  `}
`;

export const Icon = styled(FaPlay)`
  margin-left: ${metrics.baseSpacing()};

  opacity: 0.65;
  font-size: ${pxToRem(70)};

  ${media.lessThan('small')`
    font-size: ${pxToRem(32)};
  `}
`;

export const Iframe = styled(IframeLib)`
  border: none;
  border-radius: ${metrics.borderRadius()};
`;
