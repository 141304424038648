import styled from 'styled-components';
import media from 'styled-media-query';

import { fonts, metrics } from '@abqm/theme';
import { pxToRem } from '@abqm/functions';
import MoreButtonComponent from '../MoreButton';

export const Container = styled.div`
  position: relative;

  margin-bottom: ${metrics.baseSpacing(3)};

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &:after {
    position: absolute;
    bottom: -${metrics.baseSpacing(0.5)};

    display: block;

    width: 100%;
    height: ${pxToRem(1)};

    content: '';
    opacity: 0.2;
    background: ${({ color }) => color};
  }

  > h1 {
    ${fonts.size8};
    font-weight: 600;
    color: ${({ color }) => color};
    font-family: SFProDisplay-Semibold, sans-serif;

    ${media.lessThan('medium')`
      ${fonts.size7};
    `}
  }
`;

export const MoreButton = styled(MoreButtonComponent)`
  ${media.lessThan('large')`
    display: none;
  `}
`;
