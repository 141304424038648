import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { metrics, colors, fonts } from '@abqm/theme';

export const Container = styled.section`
  position: relative;

  padding: 0 ${pxToRem(80)};
  margin: ${metrics.baseSpacing(5)} auto 0;

  display: grid;
  grid-template-columns: 1fr 1fr;

  ${media.lessThan('huge')`
    padding: 0;
    max-width: ${pxToRem(1240)};
  `}

  ${media.lessThan('large')`
    display: flex;
    flex-direction: column-reverse;

    padding: 0;
    margin-top: ${metrics.baseSpacing(2)};
  `}

  > .left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding-right: ${metrics.baseSpacing(2)};
  }

  > .right {
    overflow: hidden;

    ${media.lessThan('large')`
      width: 100%;
      margin-bottom: ${metrics.baseSpacing()};
    `}

    img {
      width: 100%;
      height: 100%;
      border-radius: ${metrics.borderRadius()};

      object-fit: cover;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  ${media.lessThan('large')`
    margin-bottom: 0;
  `}
`;

export const FeaturedNew = styled.div`
  display: flex;
  flex-direction: column;

  > h2 {
    margin-bottom: ${metrics.baseSpacing(2)};

    display: -webkit-box;

    ${fonts.size8};
    line-height: 1.1;
    font-weight: 100;
    letter-spacing: -1px;
    color: ${colors.black};
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-family: SFProDisplay-Semibold, sans-serif;

    ${media.lessThan('huge')`
      ${fonts.size7};
    `}

    ${media.lessThan('large')`
      margin-bottom: ${metrics.baseSpacing(0.5)};
    `}

    &:hover {
      text-decoration: underline;
    }
  }

  > p {    
    height: auto;
    margin-bottom: ${metrics.baseSpacing(2)};

    display: -webkit-box;

    ${fonts.size5};
    line-height: 1.43;
    overflow: hidden;
    color: ${colors.grey};
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    font-family: SFProDisplay-Regular;

    ${media.lessThan('huge')`
      ${fonts.size4};
    `}

    ${media.lessThan('1370px')`      
      -webkit-line-clamp: 4;
    `}

    ${media.lessThan('large')`
      ${fonts.size3};
      line-height: 1.56;
    `}
  }
`;

export const SecondaryNews = styled.div`
  height: ${pxToRem(180)};
  /* margin-top: ${metrics.baseSpacing(2)}; */

  display: flex;
  justify-content: space-between;

  ${media.lessThan('1370px')`
    height: ${pxToRem(130)};
  `}

  ${media.lessThan('large')`
    height: ${pxToRem(420)};

    flex-direction: column;
  `}

  ${media.lessThan('medium')`
    margin-top: 32px;
    position: relative;

    height: 100%;
    padding-bottom: 8px;
        
    flex-direction: column;
  `}

  &:before {    
    position: absolute;
    left: 0;
    bottom: ${pxToRem(5)};
    z-index: -1;

    width: 120vw;
    height: 200px;
    margin-left: -24%;

    display: block;

    content: '';
    background: ${colors.darkWhite};    

    ${media.greaterThan('1900px')`
      bottom: ${pxToRem(37)};
    `}

    ${media.lessThan('1380px')`
      height: ${pxToRem(160)};
    `}

    ${media.between('medium', '1000px')`
      bottom: ${pxToRem(-83)};

      margin-left: -1.6%;
      height: ${pxToRem(290)};
    `}

    ${media.between('1000px', 'large')`
      bottom: ${pxToRem(44)};

      margin-top: auto;
      margin-left: -2.5%;
      height: ${pxToRem(410)};
    `}

    ${media.lessThan('medium')`
      bottom: -${pxToRem(12)};

      margin-left: -3.2%;      
      height: 100%;
      top: -30px;
    `}

    ${media.lessThan('small')`      
      top: -24px;
    `}
  }
`;

export const SkeletonContainer = styled.div`
  /* Mobile first */

  display: grid;
  grid-row-gap: 8px;
  grid-template-rows: 8fr 1fr 1fr 6fr;

  height: 75vh;
  margin-top: ${pxToRem(16)};

  span {
    height: 100%;
  }

  > span:nth-child(3) {
    margin-bottom: ${pxToRem(8)};
  }

  .featured-title + .featured-title {
    margin-top: ${pxToRem(6)};
  }

  ${media.greaterThan('medium')`
    grid-template-rows: 12fr 1fr 6fr 4fr;

    height: 80vh;
  `}

  ${media.greaterThan('large')`
    grid-column-gap: 16px;
    grid-template-rows: 1fr 12fr 12fr;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
      'header image'
      'title image'
      'secondary image';
    
    height: 50vh;
    margin-top: ${pxToRem(40)};

    
    span:nth-child(1) {
      grid-area: header;
    }

    .featured-new-title {
      grid-area: title;
      margin-top: ${pxToRem(16)};

      .featured-title + .featured-title {
        margin-top: ${pxToRem(4)};
      }

      .description {
        margin-top: ${pxToRem(16)};
      }
    }

    .secondary-news {
      grid-area: secondary;

      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 100%;
      width: 100%;      

      div {
        height: 60%;
        width: 100%;

        display: flex;
        align-items: center;

        > span {
          height: 100%;
          width: 100%;          

          + span {
            margin-left: ${pxToRem(8)}
          } 
        }

        .secondary-title {          
          margin-top: ${pxToRem(4)};
        }

        + div {         
          margin-left: ${pxToRem(16)}        
        }        
      }
    }

    .image {
      height: 100%;

      grid-area: image;
      margin-right: ${pxToRem(-16)};
    }
  `}
`;
