import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import {
  FaFacebookSquare,
  FaWhatsappSquare,
  FaTwitterSquare,
} from 'react-icons/fa';

import shareLink from '@abqm/theme/assets/images/icones/link-share.svg';

import { Container } from './styles';

import { urls } from '@abqm/services';

export default function Share({ isVertical, ...rest }) {
  const currentUrl = window.location.href;

  const waShareLink = currentUrl.substring(currentUrl.lastIndexOf("/") + 1, currentUrl.length)

  function handleCopyLink() {
    toast.info('Link copiado');
  }

  return (
    <>
      <Container isVertical={isVertical} {...rest}>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookSquare size={35} />
        </a>

        {/* {!isVertical && ( */}
        <a
          href={`https://api.whatsapp.com/send?text=${urls.whatsAppShareURL}${waShareLink}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsappSquare size={35} />
        </a>
        {/* )} */}

        <a
          href={`https://twitter.com/share?&url=${currentUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitterSquare size={35} />
        </a>

        <div className="alert-clipboard">
          <CopyToClipboard
            text={currentUrl}
            message="Copiado"
            onCopy={handleCopyLink}
          >
            <img src={shareLink} alt="Compartilhar link" />
          </CopyToClipboard>
        </div>
      </Container>
    </>
  );
}

Share.propTypes = {
  isVertical: PropTypes.bool,
};

Share.defaultProps = {
  isVertical: true,
};
