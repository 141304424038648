import styled from 'styled-components';
import media from 'styled-media-query';

// import { pxToRem } from '@abqm/functions';
// import { metrics } from '@abqm/theme';

export const Page = styled.div``;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;

  ${media.lessThan('large')`
    padding: 0;
  `}

  ${media.between('medium', 'large')`
    padding: 0;
  `}
`;

export const Iframe = styled.iframe.attrs({
  frameBorder: 0,
})`
  position: relative;
  width: 100%;
  height: calc(100vh - 145px);
  ${media.lessThan('large')`
  height: calc(100vh - 84px);
  `}
`;
