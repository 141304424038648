import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazy-load';

import { withSizes } from 'react-sizes';

import { colors } from '@abqm/theme';
import { api, indexes } from '@abqm/services';
import {
  ChannelsWrapper,
  Footer,
  BlackContainer,
  BannerMagazine,
} from '@abqm/components';
import Modal from '../../components/Modal';

import {
  Play,
  FeaturedNews,
  Advertisement,
  WhatsAppFloating,
  AuctionsAndMarket,
} from '../../components';

import {
  Page,
  Container,
  CategoryNews,
  GreenContainer,
  Advertisements,
  Header,
} from './styles';

function Main({ isMobile }) {
  const defaultState = {
    rows: [],
  };

  const containerRef = useRef();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [auctions, setAuctions] = useState(defaultState);
  const [institutionalNews, setRecentNews] = useState(defaultState);
  const [sportsNews, setSportsNews] = useState(defaultState);
  const [resultsNews, setResultsNews] = useState(defaultState);
  const [featuredNews, setFeaturedNews] = useState(defaultState);

  const [featuredNewsIsLoading, setFeaturedNewsIsLoading] = useState(true);
  const [sportsNewsIsLoading, setSportsNewsIsLoading] = useState(true);
  const [resultNewsLoading, setResultNewsLoading] = useState(true);
  const [auctionsIsLoading, setAuctionsIsLoading] = useState(true);
  const [institutionalNewsIsLoading, setInstitutionalNewsIsLoading] = useState(
    true
  );

  const [auctionsRender, setAuctionsRender] = useState(0);
  const [institutionalNewsRender, setInstitutionalNewsRender] = useState(0);
  const [sportsNewsRender, setSportsNewsRender] = useState(0);
  const [resultsNewsRender, setResultsNewsRender] = useState(0);
  const [featuredNewsRender, setFeaturedNewsRender] = useState(0);

  const fetchNews = useCallback(
    async ({ type, page, pageSize, index }) => {
      const setTypes = {
        featuredNews: setFeaturedNews,
        institutionalNews: setRecentNews,
        auctions: setAuctions,
        sportsNews: setSportsNews,
        resultsNews: setResultsNews,
      };

      const types = {
        featuredNews,
        institutionalNews,
        auctions,
        sportsNews,
        resultsNews,
      };

      try {
        const { data } = await api.get('contents', {
          params: {
            getCover: true,
            pagesize: pageSize,
            page,
            star: type === 'featuredNews',
            index_id: type === 'featuredNews' ? null : index,
          },
        });

        if (page > 1) {
          return setTypes[type]({ rows: [...types[type].rows, ...data.rows] });
        }

        return setTypes[type](data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [auctions, featuredNews, institutionalNews, resultsNews, sportsNews]
  );

  useEffect(() => {
    /**
     * Lazy load render twice
     * Featured news is at the top of the page, so fetch runs when featuredNewsRender = 1.
     * Others routes are fetched only when the lazy load rendered in second time.
     */
    const fetchAll = async () => {
      if (featuredNewsRender === 1) {
        await fetchNews({
          type: 'featuredNews',
          index: indexes.FEATURED_NEWS,
          page: 1,
          pageSize: 3,
        });

        setFeaturedNewsRender(null);
        setFeaturedNewsIsLoading(false);
      }

      if (institutionalNewsRender === 2) {
        await fetchNews({
          type: 'institutionalNews',
          index: indexes.INSTITUTIONAL_NEWS,
          page: 1,
          pageSize: 10,
        });

        setInstitutionalNewsRender(null);
        setInstitutionalNewsIsLoading(false);
      }

      if (auctionsRender === 2) {
        await fetchNews({
          type: 'auctions',
          index: indexes.AUCTIONS,
          page: 1,
          pageSize: 13,
        });

        setAuctionsRender(null);
        setAuctionsIsLoading(false);
      }

      if (sportsNewsRender === 2) {
        await fetchNews({
          type: 'sportsNews',
          index: indexes.SPORTS_NEWS,
          page: 1,
          pageSize: 10,
        });

        setSportsNewsRender(null);
        setSportsNewsIsLoading(false);
      }

      if (resultsNewsRender === 2) {
        await fetchNews({
          type: 'resultsNews',
          index: indexes.RESULTS_NEWS,
          page: 1,
          pageSize: 10,
        });

        setResultsNewsRender(null);
        setResultNewsLoading(false);
      }
    };

    fetchAll();
  }, [
    fetchNews,
    featuredNewsRender,
    institutionalNewsRender,
    auctionsRender,
    sportsNewsRender,
    resultsNewsRender,
  ]);

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <Page ref={containerRef}>
        {modalIsOpen && <Modal handleCloseModal={handleCloseModal} />}
        <Container>
          <Header isWithContents />

          <ChannelsWrapper />

          <LazyLoad
            offsetVertical={300}
            onContentVisible={() =>
              setFeaturedNewsRender(featuredNewsRender + 1)
            }
          >
            <FeaturedNews
              data={featuredNews}
              isMobile={isMobile}
              loading={featuredNewsIsLoading}
            />
          </LazyLoad>

          <Advertisements>
            {isMobile ? (
              <Advertisement slots={['1', '2']} />
            ) : (
              <>
                <Advertisement containerType="half" slots={['1']} />
                <Advertisement containerType="half" slots={['2']} />
              </>
            )}
          </Advertisements>

          <LazyLoad
            offsetVertical={300}
            onContentVisible={() =>
              setInstitutionalNewsRender(institutionalNewsRender + 1)
            }
          >
            <CategoryNews
              data={institutionalNews}
              loading={institutionalNewsIsLoading}
              type="institutionalNews"
              category="Institucionais"
              isMobile={isMobile}
              color={colors.grey}
              fetchMore={page =>
                fetchNews({
                  type: 'institutionalNews',
                  index: indexes.INSTITUTIONAL_NEWS,
                  page,
                  pageSize: 13,
                })
              }
            />
          </LazyLoad>
        </Container>

        {/* <Container>
          <LazyLoad
            offsetVertical={300}
            onContentVisible={() => setAuctionsRender(auctionsRender + 1)}
          >
            <AuctionsAndMarket
              loading={auctionsIsLoading}
              data={auctions}
              fetchMore={fetchNews}
              isMobile={isMobile}
            />
          </LazyLoad>
        </Container> */}

        <Container>
          <Advertisements>
            {isMobile ? (
              <Advertisement slots={['6', '7']} />
            ) : (
              <>
                <Advertisement containerType="half" slots={['6']} />
                <Advertisement containerType="half" slots={['7']} />
              </>
            )}
          </Advertisements>

          <LazyLoad
            offsetVertical={300}
            onContentVisible={() => setSportsNewsRender(sportsNewsRender + 1)}
          >
            <CategoryNews
              withIcon
              data={sportsNews}
              loading={sportsNewsIsLoading}
              category="Esportes"
              color={colors.orange}
              isMobile={isMobile}
              fetchMore={page =>
                fetchNews({
                  type: 'sportsNews',
                  index: indexes.SPORTS_NEWS,
                  page,
                  pageSize: 13,
                })
              }
            />
          </LazyLoad>
        </Container>

        <GreenContainer>
          <LazyLoad
            offsetVertical={300}
            onContentVisible={() => setResultsNewsRender(resultsNewsRender + 1)}
          >
            <CategoryNews
              withAds
              withIcon
              isResults
              isMobile={isMobile}
              color={colors.blue}
              data={resultsNews}
              loading={resultNewsLoading}
              category="Universo QM"
              style={{ paddingBottom: 0 }}
            />
          </LazyLoad>

          <BannerMagazine />
        </GreenContainer>

        <BlackContainer>
          <Play />

          <Footer />
        </BlackContainer>
        <WhatsAppFloating />
      </Page>
    </>
  );
}

Main.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1100,
});

export default withSizes(mapSizesToProps)(Main);
