import React, { useEffect, useState, useCallback } from 'react';

import LogoAbqm from '@abqm/theme/assets/images/logos/logo-abqm.svg';

// import api from '../../services/api';
import { urls, api, indexes } from '@abqm/services';
// import api from '../../../services/api';
// import { handleScrollTop, formatDate } from '../../../../functions';
import { handleScrollTop, formatDate } from '@abqm/functions';

import { Container, Achievement, AchievementImage, Sponsor, SponsorImageContainer, SponsorImageGold, SponsorImageSilver, SponsorImageBronze } from './styles';
import data from '@abqm/components/src/MenuMobile/ListItems/data';



function LastedSponsors({ hotsite, sponsors }) {
  const [lastedSponsors, setLastedMoreSponsors] = useState([]);
  const [spGold, setGold] = useState([]);
  const [spSilver, setSilver] = useState([]);
  const [spBronze, setBronze] = useState([]);

  useEffect(() => {
    const fetchSilver = async () => {
      try {
        const data1 = await api.get('contents', {
          params: {
            page: 1,
            pagesize: 99,
            index_id: sponsors.id,
            getCover: true,
            order: 2,
          },
        });

        setSilver(data1.data.rows);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSilver();
  }, [sponsors]);

  useEffect(() => {
    const fetchBronze = async () => {
      try {
        const data2 = await api.get('contents', {
          params: {
            page: 1,
            pagesize: 99,
            index_id: sponsors.id,
            getCover: true,
            order: 3,
          },
        });

        setBronze(data2.data.rows);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBronze();
  }, [sponsors]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get('contents', {
          params: {
            page: 1,
            pagesize: 99,
            index_id: sponsors.id,
            getCover: true,
            order: 1,
          },
        });

        setLastedMoreSponsors(data.rows);
        setGold(data.rows);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [sponsors]);

  return (
    <Container>
      {hotsite.map(item => (
        <>
          <Achievement>
            <h1>Realização</h1>
            <hr />

            <AchievementImage>
              <img src={`${urls.hotsitesImageURL}/${item?.logo_realizacao}`} alt="Logo da ABQM" />
            </AchievementImage>
          </Achievement>
          <Sponsor>
            <h1>Patrocínio</h1>
            <hr />
            <section>
              <SponsorImageContainer>
                {spGold.map(item => (
                  <div
                    // to={{
                    //   pathname: `/noticias/${item.url}`,
                    //   idLocation: item.id,
                    // }}
                    // onClick={handleScrollTop}
                    key={item.id}
                  >
                    <SponsorImageGold>
                      <a href={item.link_externo} target='_blank'>
                        <img
                          src={`${urls.hotsitesImageURL}/${item?.cover?.file}`}
                          alt={item?.cover?.file}
                        />
                      </a>
                    </SponsorImageGold>
                  </div>
                ))}
              </SponsorImageContainer>
              <SponsorImageContainer>
                {spSilver.map(itemS => (
                  <div
                    // to={{
                    //   pathname: `/noticias/${item.url}`,
                    //   idLocation: item.id,
                    // }}
                    // onClick={handleScrollTop}
                    key={itemS.id}
                  >
                    <SponsorImageSilver>
                      <a href={itemS.link_externo} target='_blank'>
                        <img
                          src={`${urls.hotsitesImageURL}/${itemS?.cover?.file}`}
                          alt={itemS?.cover?.file}
                        />
                      </a>
                    </SponsorImageSilver>
                  </div>
                ))}
              </SponsorImageContainer>
              <SponsorImageContainer>
                {spBronze.map(itemB => (
                  <div
                    // to={{
                    //   pathname: `/noticias/${item.url}`,
                    //   idLocation: item.id,
                    // }}
                    // onClick={handleScrollTop}
                    key={itemB.id}
                  >
                    <SponsorImageBronze>
                      <a href={itemB.link_externo} target='_blank'>
                        <img
                          src={`${urls.hotsitesImageURL}/${itemB?.cover?.file}`}
                          alt={itemB?.cover?.file}
                        />
                      </a>
                    </SponsorImageBronze>
                  </div>
                ))}
              </SponsorImageContainer>
            </section>

          </Sponsor>
        </>
      ))}
    </Container>
  );
}

export default LastedSponsors;
