import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

// import api from '../../../../services/api';
import { urls, api, indexes } from '@abqm/services';

import { Container, DescriptionVideo, LiveVideo, Nav, Icon, Tabs } from './styles';

export default function ArenaOrganact({ hotsite, conteudoVideos }) {
  const [navContent, setNavContent] = useState([]);
  const [id, setId] = useState(null);
  const [content, setContent] = useState({});
  const [styleTextos, setStyleTextos] = useState({});
  const [styleBotoes, setStyleBotoes] = useState({});
  const [styleBorda, setStyleBorda] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get(`contents/`, {
          params: {
            getCover: true,
            pagesize: 99,
            page: 1,
            index_id: conteudoVideos.id,
          },
        });

        setNavContent(data.rows);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [conteudoVideos]);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const { data } = await api.get(`contents/${id || navContent[0].id}`, {
          params: {
            getContents: true,
            language: 'pt',
          },
        });
        setContent(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchVideo();
  }, [id, navContent]);

  useEffect(() => {
    try {
      setStyleTextos({ border: 'none', color: `${hotsite[0].cor_transmissoes_textos}` });
      setStyleBotoes({ border: 'none', color: '#FFFFFF', backgroundImage: `linear-gradient(to left, ${hotsite[0].cor_transmissoes_botoes}66, ${hotsite[0].cor_transmissoes_botoes} 33%)` });
      setStyleBorda({ border: `solid 15px ${hotsite[0].cor_transmissoes_botoes}4D` });

      //border: solid 15px rgba(145, 102, 42, 0.3);

    } catch (error) {
      console.log(error);
    } finally {
    }
  }, [hotsite]);

  return (
    <Container>
      <h5 style={styleTextos}>Acompanhe as provas</h5>
      <br />
      <h1 style={styleTextos}>AO VIVO</h1>

      <div>
        <Nav>
          <ul>
            {navContent.map(item => (
              <button onClick={() => setId(item.id)} key={item.id} style={styleBotoes}>
                <Icon className="IconButton" />
                <NavLink to={item.id}>{item.title}</NavLink>
              </button>
            ))}
          </ul>
        </Nav>

        <LiveVideo>
          <div style={styleBorda}>{ReactHtmlParser(content.resume)}</div>
        </LiveVideo>

        <DescriptionVideo>
          <h2 style={styleTextos}> {content.title} </h2>
          <p style={styleTextos}>{ReactHtmlParser(content.text)}</p>
        </DescriptionVideo>
      </div>
    </Container>
  );
}
