import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { IoIosArrowForward } from 'react-icons/io';

import { pxToRem } from '@abqm/functions';
import { metrics, fonts, colors } from '@abqm/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: ${metrics.baseSpacing()};

  ${media.lessThan('large')`
    ${({ isFeatured }) =>
      !isFeatured &&
      css`
        flex-direction: column;
        align-items: flex-start;
      `}
  `}

  > h3 {
    display: flex;
    align-items: center;

    ${fonts.size3};
    line-height: 1.17;
    letter-spacing: 0.9px;
    color: ${colors.green};
    text-transform: uppercase;
    font-family: SFCompactDisplay-Thin, sans-serif;

    ${media.lessThan('huge')`
      ${fonts.size1};
    `}

    ${media.lessThan('large')`
      ${({ isFeatured }) =>
        !isFeatured &&
        css`
          margin-bottom: ${metrics.baseSpacing(0.5)};
        `}
    `}
  }

  > time,
  > span {
    margin-left: ${({ withoutCategory }) =>
      withoutCategory ? 0 : metrics.baseSpacing(2)};

    ${fonts.size3};
    line-height: 0.67;
    color: ${colors.grey};
    letter-spacing: 0.9px;
    font-family: SFCompactDisplay-Thin;

    ${media.lessThan('huge')`
      margin-left: ${({ withoutCategory }) =>
        withoutCategory ? 0 : metrics.baseSpacing()};

      ${fonts.size1};
    `}

    ${media.lessThan('large')`
    ${({ withoutCategory }) => (withoutCategory ? 0 : metrics.baseSpacing())}

      ${fonts.size1};

      ${({ isFeatured }) =>
        !isFeatured &&
        css`
          margin-left: 0;
        `}
    `}
  }
  > time {
    letter-spacing: normal;

    ${media.greaterThan('medium')`    
      margin-top: ${pxToRem(2)};
    `};
  }
`;

export const Icon = styled(IoIosArrowForward)`
  font-size: ${pxToRem(18)};

  ${media.lessThan('large')`
    margin-bottom: 1px;

    font-size: ${pxToRem(13)};
  `}
`;
