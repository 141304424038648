import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { fonts, colors, metrics } from '@abqm/theme';
import { CategoryTitle as CategoryTitleC } from '@abqm/components';

export const Container = styled(Link)`
  width: ${pxToRem(350)};

  &:hover h4{
    text-decoration: underline;
  }

  &:hover img {
    transform: scale(1.1);
  }

  ${media.lessThan('1370px')`
    width: ${pxToRem(300)};
  `}

  ${media.lessThan('large')`
    width: 100%;
    margin-bottom: ${metrics.baseSpacing(5)};
  `}

  ${media.between('large', '1900px')`
    &:last-child {
      margin-left: ${pxToRem(10)};
    }
  `}

  > .content {
    display: flex;

    > .img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${metrics.baseSpacing()};

      overflow: hidden;
      width: ${pxToRem(160)};
      height: ${pxToRem(120)};
      min-width: ${pxToRem(160)};
      border-radius: ${pxToRem(3)};

      background-color: ${colors.black};

      ${media.lessThan('1370px')`
        width: ${pxToRem(120)};
        height: ${pxToRem(100)};
        min-width: ${pxToRem(120)};
      `}

      ${media.lessThan('medium')`
        width: ${pxToRem(170)};
        height: ${pxToRem(120)};  
        min-width: ${pxToRem(170)};
      `}
    }

    img {            
      max-height: 100%;
    }

    h4 {
      height: ${pxToRem(90)};

      display: -webkit-box;

      ${fonts.size5};
      font-weight: 100;      
      line-height: 1.14;      
      color: ${colors.black};
      -webkit-box-orient: vertical;
      font-family: SFProDisplay-Semibold, sans-serif;

      ${media.lessThan('huge')`
        height: ${pxToRem(100)};

        ${fonts.size4};
      `}

      ${media.lessThan('1370px')`
        ${fonts.size3};

        height: ${pxToRem(90)};
      `}

      ${media.lessThan('large')`
        height: ${pxToRem(90)};      
      `}

      ${media.lessThan('medium')`
        height: ${pxToRem(70)};      
      `}
    }

    > .wrap {
    }
  }
`;

export const CategoryTitle = styled(CategoryTitleC)`
  ${media.lessThan('huge')`
    span {
      display: none;
    }
    
    /* time { 
      display: none;
    } */
  `}
`;
