import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
    Main,
    Login,
    News,
    Institutional,
    Esportes,
    Impressos,
    StudBook,
    QuartoDeMilha,
    OnlineServices,
    Equoterapia,
    BoletimABQM,
    SalaImprensa,
    NewsCategories,
    Hotsites,
    Event,
    Champions,
    EquitacaoWestern,
} from '../pages';

export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={Main} />
            <Route path="/login/:destination?" component={Login} />
            <Route path="/noticias/:url" component={News} />
            <Route path="/mais-noticias/:url" component={NewsCategories} />
            <Route path="/hotsite/:url" component={Hotsites} />
            <Route path="/provas/:url" component={Event} />
            <Route path="/campeoes/:url" component={Champions} />
            <Route path="/institucional/:id?" component={Institutional} />
            <Route path="/boletins-abqm/:id?" component={BoletimABQM} />
            <Route path="/sala-de-imprensa/:id?" component={SalaImprensa} />
            <Route path="/equoterapia/:id?" component={Equoterapia} />
            <Route path="/esportes/:id?" component={Esportes} />
            <Route path="/impressos/:id?" component={Impressos} />
            <Route path="/stud-book/:id?" component={StudBook} />
            <Route path="/quarto-de-milha/:id?" component={QuartoDeMilha} />
            <Route path="/servicos/:page?" component={OnlineServices} />
            <Route path="/westernequitacao/:id?" component={EquitacaoWestern} />
        </Switch>
    );
}
