import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';

import { pxToRem } from '@abqm/functions';
import { metrics, fonts, colors } from '@abqm/theme';

const containerLayouts = {
  vertical: css`
    flex-direction: column;
    max-width: ${pxToRem(220)};

    ${media.greaterThan('large')`
      height: ${pxToRem(242)};
    `}

    ${media.greaterThan('huge')`
      height: ${pxToRem(290)};
    `}
  `,
  horizontal: css`
    flex-direction: row;
    max-width: ${pxToRem(490)};
  `,
};

export const Container = styled(Link)`
  display: flex;
  justify-content: space-between;

  ${({ isvertical }) =>
    isvertical === 'true'
      ? containerLayouts.vertical
      : containerLayouts.horizontal}

  justify-content: flex-start;
  align-content: flex-start;

  &:hover h3 {
    text-decoration: underline;
  }

  &:hover img {
    transform: scale(1.1);
  }

  ${media.lessThan('large')`
    width: 100%;
    max-width: 100%;

    flex-direction: row;
  `}

  > .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;    

    ${({ isvertical }) =>
      isvertical === 'true'
        ? css`
            width: 100%;
            margin-right: 0;
            margin-bottom: ${metrics.baseSpacing()};
          `
        : css`
            max-width: ${pxToRem(250)};
            min-width: ${pxToRem(250)};
            margin-right: ${metrics.baseSpacing(1)};
            margin-bottom: 0;
          `}

    height: ${pxToRem(130)};
    max-height: ${pxToRem(130)};
    min-height: ${pxToRem(130)};
    border-radius: ${metrics.borderRadius()};

    ${media.lessThan('huge')`
        min-height: ${pxToRem(120)};
        width: ${({ isvertical }) =>
          isvertical === 'true' ? '100%' : pxToRem(180)};

        ${({ isvertical }) =>
          isvertical === 'true'
            ? css`
                width: 100%;
              `
            : css`
                max-width: ${pxToRem(180)};
                min-width: ${pxToRem(180)};
              `}
      `}

      ${media.lessThan('large')`
        height: ${pxToRem(120)};
        width: ${pxToRem(220)};
        max-width: unset;
        min-width: ${pxToRem(220)};
      `}

      ${media.lessThan('medium')`
        height: ${pxToRem(110)};
        width: ${pxToRem(170)} !important;
        min-width: ${pxToRem(170)};
      `}
      
    img {
      margin: 0;
    
      object-fit: cover;
      object-position: top;
      width: 100%; 
      height: 100%;

      /* ${media.lessThan('medium')`        
      `} */
    }
  }

  > .content {
    > h3 {
      margin-top: ${metrics.baseSpacing(1.5)};

      display: -webkit-box;

      ${fonts.size4};
      font-weight: 100;
      line-height: 1.1;
      color: ${colors.black};
      font-family: SFProDisplay-Semibold, sans-serif;
      overflow: hidden;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;      

      ${media.lessThan('huge')`
        ${fonts.size3};

        -webkit-line-clamp: 4;
      `}

      ${media.lessThan('large')`
        margin-top: ${metrics.baseSpacing(0.5)};
      `}

      ${media.lessThan('medium')`
        -webkit-line-clamp: 5;
      `}
    }
  }
`;

export const SkeletonContainer = styled.div`
  width: 100%;
`;
