import styled from 'styled-components';
import media from 'styled-media-query';

import { colors, fonts } from '@abqm/theme';
import { pxToRem } from '@abqm/functions';

export const Container = styled.div`
  height: 35px;
  width: 100%;
  padding: 0 20px;

  display: flex;
  align-items: center;

  background-color: ${colors.dark};

  ${media.lessThan('large')`
    display: none;
  `}

  > .left,
  > .center,
  > .right {
    display: flex;
    align-items: center;
  }

  > .left {
    width: ${pxToRem(405)};

    > a {
      margin-right: 5px;

      &:nth-child(1) {
        margin-right: 20px;
      }
    }
  }

  > .center {
    margin: 0 auto;
    justify-content: flex-end;

    > a + a {
      margin-left: 15px;
    }
  }

  > .right {
    width: ${pxToRem(405)};

    justify-content: flex-end;

    > a + a {
      margin-left: 20px;
    }
  }
`;

export const AuthOptions = styled.div`
  position: absolute;
  z-index: 99;
  top: 36px;

  width: 200px;
`;

export const LogoutButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 8px #0003;

  background: #5aabc9;

  &:hover {
    background: #6bbcda;
    box-shadow: 2px 2px 8px #0006;
    transition: 0.3s;
  }

  div span {
    ${fonts.size1};
    color: ${colors.white};
    font-family: SFProDisplay-Regular, sans-serif;
  }
`;
