import React from 'react';
import PropTypes from 'prop-types';
import { withSizes } from 'react-sizes';

import { Header, InternalPage } from '@abqm/components';

import { Container } from './styles';

function Main({ isMobile, match }) {
    return (
        <>
            <Container>
                <Header type="internal" />
                <InternalPage
                    mobile={isMobile}
                    title="Boletins ABQM"
                    indexId={818}
                    currentContentId={match.params.id}
                    isBoletim
                />
            </Container>

            {/* <BlackContainer>
        <Footer />
      </BlackContainer> */}
        </>
    );
}

Main.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
};

const mapSizesToProps = ({ width }) => ({
    isMobile: width <= 1100,
});

export default withSizes(mapSizesToProps)(Main);
