import { createGlobalStyle } from 'styled-components';
import media from 'styled-media-query';

import 'react-toastify/dist/ReactToastify.css';

import colors from './colors';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    transition: .3s;
  }

  *:focus {
    outline: 0;
  }

  html {
    min-height: calc(100vh);
  }

  body, #root {
    width: 100%;
    min-height: calc(100vh);
    
    ${media.lessThan('medium')`
      overflow-x: hidden;
    `}
  }

  body {
    -webkit-font-smoothing: antialiased;

    ${media.lessThan('large')`
      background-color: ${props => props.primary};
    `}
  }

  body, input, button {
    font: 16px 'GoogleSans-Regular';
  }

  a {
    color: ${colors.black};
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  /* Modal styles */
  .ReactModal__Overlay {
      opacity: 0;
      transition: opacity 300ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }

  /* Lazy Load */
  .LazyLoad {
  opacity: 0;
  transition: all 2s ease-in-out;

    &.is-visible {
      opacity: 1;
    }
  }  
`;
