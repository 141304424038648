import styled, { css } from 'styled-components';
// import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { metrics, colors, fonts } from '@abqm/theme';

import { lighten } from 'polished';

export const Page = styled.div``;

export const Container = styled.div`
  flex: 1;
  height: calc(100vh - 35px);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    height: unset;
  }
`;

export const Left = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin-bottom: 24px;
  }
`;

export const Right = styled.div`
  position: relative;
  flex: 0.47;

  padding: ${props => (props.renderRegister ? '20px 48px' : '48px')};
  height: 100%;
  min-height: 400px;

  display: flex;
  justify-content: center;
  align-items: ${props => (props.renderRegister ? 'flex-start' : 'center')};
  overflow: hidden;

  background-color: #040d14;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const RightBackground = styled.img`
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;

  transition: 0.3s;
  width: 100%;
  height: auto;

  ${({ full }) =>
    !full &&
    css`
      transition: 0.3s;
      right: -5%;
      bottom: -65%;
      width: 100%;
    `}
`;

export const FormContainer = styled.div`
  flex: 1;
  width: 100%;
  max-width: 408px;
  z-index: 99;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: fit-content;
  padding: 0 24px;

  .title {
    position: relative;

    display: block;
    margin-bottom: ${metrics.baseSpacing(4.5)};

    ${fonts.size7};
    color: ${colors.white};
    line-height: 38px;
    font-family: SFCompactRounded-Medium, sans-serif;
    letter-spacing: -0.91px;
  }
`;

export const Logo = styled.img`
  display: block;
  width: ${pxToRem(196)};
  height: auto;
  margin-bottom: ${({ info }) =>
    info ? metrics.baseSpacing(4) : metrics.baseSpacing(6)};
`;

export const Socials = styled.div`
  height: auto;
  width: ${pxToRem(112)};
  margin-bottom: ${metrics.baseSpacing(3)};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SocialItem = styled.img`
  width: ${pxToRem(48)};
  height: ${pxToRem(48)};

  display: block;

  cursor: pointer;
  transition: 0.3s;
  transform: scale(1);

  :hover {
    transition: 0.3s;
    transform: scale(1.15);
  }

  :active {
    transition: 0.3s;
    transform: scale(0.9);
  }
`;

export const Form = styled.form`
  flex: 1;
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  p {
    display: block;
    ${fonts.size3};
    color: #797978;
    font-family: SFProText-Regular, sans-serif;
    letter-spacing: -0.46px;
    margin-bottom: ${metrics.baseSpacing(1)};
  }

  > div {
    width: 100%;
    height: fit-content;
    margin-bottom: ${metrics.baseSpacing(3)};

    display: flex;
    flex-direction: column;

    > p {
      ${fonts.size2};
      letter-spacing: -0.46px;
      margin-bottom: ${metrics.baseSpacing(0.5)};
    }
  }
`;

export const Input = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  border-radius: 4px;
  height: ${pxToRem(40)};
  border: solid 1px #d9d9d9;
  background: ${({ dark }) => (dark ? 'rgba(255, 255, 255, 0.04)' : '#fff')};

  transition: 0.3s;

  input {
    width: 100%;
    height: 100%;

    padding: ${pxToRem(12)};

    border: none;
    background: transparent;
    border-radius: 4px;
    ${fonts.size3};
    color: ${({ dark }) =>
      dark ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.65)'};
    font-family: SFProText-Regular, sans-serif;

    ::placeholder {
      color: ${({ dark }) =>
        dark ? 'rgba(255, 255, 255, 0.25)' : 'rgba(0, 0, 0, 0.25)'};
      font-family: SFProText-Regular, sans-serif;
    }
  }

  &:hover {
    transition: 0.3s;
    border-color: ${colors.brightBlue};
  }

  &:focus-within {
    transition: 0.3s;
    border-color: ${colors.brightBlue};
    box-shadow: 0 0 2px ${colors.brightBlue};
  }

  ${({ errors }) =>
    errors &&
    css`
    border-color: ${colors.danger};

    &:hover {
      transition: 0.3s;
      border-color: ${colors.danger};
    }

    &:focus-within {
      transition: 0.3s;
      border-color: ${colors.danger};
      box-shadow: 0 0 2px ${colors.danger};
    }
    
      &::after {
        content: '${errors.message}';
        position: absolute;
        bottom: -20px;
        right: 0;

        ${fonts.size1};
        color: ${colors.danger};
        font-family: SFProText-Regular, sans-serif;
      }
    `}
`;

export const InputIcon = styled.div`
  width: fit-content;
  padding: ${pxToRem(12)};
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${metrics.baseSpacing(1)};

  width: ${pxToRem(16)};
  height: ${pxToRem(16)};

  cursor: pointer;
  border-radius: 2px;
  border: solid 1px #d9d9d9;
  background: ${colors.white};

  &:hover {
    border-color: ${colors.brightBlue};
  }

  .checkmark {
    opacity: 0;
    color: #fff;
  }

  ${({ checked }) =>
    checked &&
    css`
      border-color: ${colors.brightBlue};
      background: ${colors.brightBlue};

      .checkmark {
        opacity: 1;
      }
    `}
`;

export const Actions = styled.div`
  width: 100%;
  height: 24px;

  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;

  margin-top: ${metrics.baseSpacing(0.5)};

  > div {
    transition: 0.3s;
    display: flex;
    justify-content: space-between;

    &:hover {
      p {
        transition: 0.3s;
      }
    }
  }

  p {
    transition: 0.3s;
    ${fonts.size2};
    color: #656565;
    font-family: SFProText-Regular, sans-serif;
    margin-bottom: 0 !important;
  }
`;

export const Forgot = styled.p`
  display: block;

  cursor: pointer;
  transition: 0.3s;
  ${fonts.size2};
  color: #656565;
  font-family: SFProText-Regular, sans-serif;
  margin-bottom: 0 !important;

  &:hover {
    transition: 0.3s;
    color: #1890ff;
  }
`;

export const Button = styled.button`
  display: block;
  width: ${pxToRem(132)};
  height: ${pxToRem(40)};
  opacity: ${({ error }) => (error ? '0.6' : '1')};
  transition: 0.3s;
  border: none;
  border-radius: 20px;
  background-color: ${colors.lightGreen};

  ${fonts.size2};
  color: ${colors.white};
  font-family: SFProText-Regular, sans-serif;
  letter-spacing: -0.4px;

  &:hover {
    transition: 0.3s;
    background-color: ${lighten(0.02, colors.lightGreen)};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      transition: 0.3s;
      opacity: 0.6;
    `}
`;

export const InfoContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: ${pxToRem(255)};
  height: fit-content;

  p {
    margin-bottom: ${metrics.baseSpacing(3)};

    text-align: center;
    ${fonts.size3};
    color: rgba(255, 255, 255, 0.65);
    line-height: 25.6px;
    font-family: SFProText-Regular, sans-serif;
    letter-spacing: -0.46px;
  }

  .dark {
    color: rgba(102, 102, 102, 0.65);
  }

  .title {
    position: relative;

    display: block;
    margin-bottom: ${metrics.baseSpacing(4.5)};

    ${fonts.size7};
    color: ${colors.white};
    line-height: 38px;
    font-family: SFCompactRounded-Medium, sans-serif;
    letter-spacing: -0.91px;

    :after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -12px;
      transform: translate(-50%, -50%);

      width: ${pxToRem(60)};
      height: ${pxToRem(2)};
      background: ${colors.white};
    }
  }
`;

export const RegisterInfoConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-family: SFProText-Regular, sans-serif;

  .flagButtons {
    margin-top: 1.2rem;
  }

  hr {
    width: 110%;
    margin: 10px 0 15px 0;

    border-top: 1px solid #fff;
  }

  .registerInfoText {
    color: #fff;
    font-size: 0.9rem;

    p {
      margin-bottom: 15px;

      span {
        font-weight: bold;
      }
    }

    ul {
      margin-bottom: 15px;
    }
  }
`;

export const LanguageButton = styled.button`
  margin-right: 2rem;
  padding: 5px 5px 1px 5px;

  border: ${props =>
    props.active ? '1px solid rgba(255, 255, 255, 0.51)' : 'none'};
  border-radius: 5px;
  background: none;

  img {
    width: 35px;
  }

  :last-child {
    margin-right: 0;
  }
`;
