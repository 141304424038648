import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Back from '@abqm/theme/assets/images/logos/logo-abqm.svg';
import BackMobile from '@abqm/theme/assets/images/logos/logo-abqm.svg';

export const Container = styled.section`
  z-index: 6;

  position: relative;

  width: 100%;
  // min-height: 90vh;
  max-width: 1920px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1024px) {
    padding: 0 0;
    background-color: transparent;
  }

  h1 {
    margin-bottom: 50px;
    // margin-top: -100px;

    display: flex;
    align-items: center;
    justify-content: center;

    //color: #91662A;
    font-size: 60px;
    font-family: 'SFProDisplay-Semibold';

    @media (max-width: 1024px) {
      //color: #91662A;
      margin: 120px 0 0 0;

      font-size: 30px !important;
    }
  }

  section {
    height: 100%;
    padding: 0 3rem;
    margin-bottom: 100px;

    display: flex;
    justify-content: center;

    @media (max-width: 1024px) {
      margin-top: 2rem;

      align-items: center;
      flex-direction: column;
    }
  }
`;

export const Blur = styled.div`
  z-index: -1;
  position: absolute;

  width: 100%;
  height: 100%;

  background: rgba(21, 14, 8, 0.5);

  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  filter: brightness(150%);
`;

export const News = styled(Link)`
  width: 30%;
  margin: 10px;
  height: 100%;

  max-width: 475px;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  text-decoration: none;
  //background: #91662A;

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 775px;
    margin-bottom: 80px;

    //background: #91662A;
  }

  img {
    width: 100%;
    height: 220px;

    object-fit: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    @media (max-width: 1024px) {
      height: auto;
    }
  }

  div {
    z-index: 1;

    p {
      padding: 0px 10px 10px 10px;

      //color: #fff;
      font-size: 20px;
      text-transform: uppercase;
      font-family: 'SFProDisplay-Semibold';

      @media (max-width: 1024px) {
        text-align: start;

        padding: 15px;

        //color: #fff;
        font-weight: 600;
        font-size: 20px !important;
      }
    }

    span {
      padding: 20px 10px 20px 10px;

      //color: rgba(255, 255, 255, 0.5);
      font-size: 12px;
      font-family: 'SFProDisplay-Semibold';

      @media (max-width: 1024px) {
        //color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
      }
    }
  }

  :hover {
    cursor: pointer;
  }
`;
