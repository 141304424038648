import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { metrics, fonts, colors } from '@abqm/theme';
import { lighten, darken } from 'polished';
import IframeComponent from 'react-iframe';

import download from '@abqm/theme/assets/images/icones/download.png';

export const Container = styled.div`
  width: ${props => (props.mobile ? '100%' : '55%')};

  display: ${props => (props.mobile && !props.show ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: flex-start;

  margin-left: ${props => (props.mobile ? 0 : metrics.baseSpacing(14))};
  ${props =>
    props.mobile &&
    css`
      margin: 0;
      padding: ${metrics.baseSpacing(1)};
    `}

  ${media.greaterThan('huge')`
      margin-right: ${metrics.baseSpacing(14)};
    `}
`;

export const ContentTitle = styled.p`
  display: block;
  width: 100%;

  ${fonts.size7};
  color: ${colors.grey};
  line-height: ${pxToRem(38)};
  font-weight: 600;
  font-family: SFRounded-Semibold, sans-serif;

  ${props =>
    props.mobile &&
    css`
      ${fonts.size6};
      line-height: ${pxToRem(30)};
    `}
`;

export const ContentResume = styled.p`
  margin-top: ${metrics.baseSpacing(1)};

  display: block;
  width: 100%;

  ${fonts.size4};
  color: ${colors.grey};
  line-height: ${pxToRem(24)};
  font-family: SFRounded-Medium, sans-serif;
`;

export const InnerContent = styled.div`
  margin-top: ${metrics.baseSpacing(3)};

  overflow-x: auto;

  img {
    max-width: 100%;
    height: auto;
  }

  p,
  td,
  h3,
  li {
    ${fonts.size3};
    color: ${colors.grey};
    line-height: 1.75;
    letter-spacing: normal;
    font-weight: normal;
    font-family: SFProDisplay-Regular, sans-serif;
  }

  h3 {
    font-size: ${pxToRem(22)};
    font-family: SFProDisplay-Semibold, sans-serif;
  }

  p {
    margin-bottom: ${metrics.baseSpacing(2)};
  }

  ol,
  ul {
    padding-left: ${metrics.baseSpacing(6)} !important;
    margin-bottom: ${metrics.baseSpacing(2)};
  }

  a {
    text-decoration: underline;

    :hover {
      color: ${darken(0.5, colors.grey)};
    }
  }

  a[href$='.pdf'] {
    width: fit-content;

    display: block;

    position: relative;

    padding: ${metrics.baseSpacing(1)};
    padding-left: ${metrics.baseSpacing(6)};
    padding-right: ${metrics.baseSpacing(3)};

    text-decoration: none;
    transition: 0.3s;
    border-radius: 30px;
    background: ${colors.lightGrey};
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.06);

    &:hover {
      transition: 0.3s;
      background: ${lighten(0.05, colors.lightGrey)};
    }

    &::before {
      position: absolute;
      left: ${metrics.baseSpacing(3)};
      content: url(${download});
    }
  }

  table {
    overflow-x: scroll;
    margin-bottom: ${metrics.baseSpacing(3)};

    border-collapse: collapse;
    border-spacing: 0;

    td {
      margin: 0;
      padding-left: ${metrics.baseSpacing(2)};
      padding-right: ${metrics.baseSpacing(2)};

      border: solid 1px ${colors.lightGrey};
    }
  }
`;

export const Loader = styled.div`
  width: 100%;
  height: ${pxToRem(750)};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Iframe = styled(IframeComponent)`
  width: 100%;
  height: 100%;

  border: 0;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
`;
