import React, { useState, useEffect } from 'react';
import { urls } from '@abqm/services';
import PropTypes from 'prop-types';

import { Container, Icon } from './styles';

export default function SearchInput({
  placeholder,
  onIconClick,
  onTextChange,
  type,
  ...rest
}) {
  const [text, setText] = useState('');
  function handleKeyPress(event) {
    if (event.charCode === 13) {
      window.location.href = `${urls.searchURL}?term=${text}`;
    }
  }

  function updateText(event) {
    if (event.charCode === 13) {
      onTextChange(event.target.value);
    }
  }

  function renderDefault() {
    return (
      <Container {...rest}>
        <input
          type="text"
          name="search"
          value={text}
          placeholder={placeholder}
          onChange={event => setText(event.target.value)}
          onKeyPress={event => handleKeyPress(event)}
        />

        <a href={`${urls.searchURL}?term=${text}`}>
          <Icon />
        </a>
      </Container>
    );
  }

  function renderPlay() {
    return (
      <Container {...rest}>
        <input
          type="text"
          name="search"
          value={text}
          placeholder="Buscar na ABQM Play"
          onChange={event => setText(event.target.value)}
          onKeyPress={event => updateText(event)}
        />

        <button type="button" onClick={() => onIconClick(text)}>
          <Icon />
        </button>
      </Container>
    );
  }

  const renders = {
    default: renderDefault(),
    play: renderPlay(),
  };

  useEffect(() => {
    setText(text);
  }, [text]);

  return <>{renders[type]}</>;
}

SearchInput.defaultProps = {
  placeholder: 'Buscar na ABQM',
  onIconClick: () => {},
  onTextChange: () => {},
  type: 'default',
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onIconClick: PropTypes.func,
  onTextChange: PropTypes.func,
  type: PropTypes.string,
};
