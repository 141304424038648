import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ContentVideo from './components/ContentVideo';

import { Container } from './styles';

function LiveHome({ hotsite, conteudoVideos }) {
  return (
    <Container>
      <Switch>
        <ContentVideo hotsite={hotsite} conteudoVideos={conteudoVideos} />
      </Switch>
    </Container>
  );
}

export default LiveHome;
