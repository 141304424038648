import styled from 'styled-components';
import { lighten } from 'polished';
import media from 'styled-media-query';
import { IoMdTrophy } from 'react-icons/io';

import { pxToRem } from '@abqm/functions';
import { metrics, fonts, colors } from '@abqm/theme';
import {
  SimpleNew as SimpleNewComponent,
  MoreButton as MoreButtonComponent,
  CategoryTitle as CategoryTitleC,
  ContentWrap,
} from '@abqm/components';

import AdvertisementComponent from '../Advertisement';

export const Container = styled.section`
  padding: ${metrics.baseSpacing(3)} ${pxToRem(80)};
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  ${media.lessThan('huge')`
    padding: ${metrics.baseSpacing(3)} ${metrics.baseSpacing(4)};    
  `}

  ${media.lessThan('large')`
    padding: ${metrics.baseSpacing(3)} 0;  
  `}

  ${media.lessThan('medium')`
    padding: 0;
    margin-bottom: 0;
  `}

  .top {
    height: ${pxToRem(466)};
    
    display: flex;

    ${media.lessThan('huge')`
      height: ${pxToRem(380)};
    `}

    ${media.lessThan('large')`
      height: unset;
      
      flex-direction: column;
    `}
  }

  .bottom {
    margin-top: ${metrics.baseSpacing(7)};

    display: flex;
    align-items: center;
    justify-content: space-between;

    ${media.lessThan('large')`
      display: none;
    `}
  }
`;

export const CategoryTitle = styled(CategoryTitleC)`
  margin-top: ${metrics.baseSpacing()};
`;

export const Content = styled(ContentWrap)`  
  ${media.between('large', 'huge')`
    padding: 0 ${metrics.baseSpacing(4)};
  `}

  ${media.between('1280px', '1280px')`
    padding: 0;
  `}

  ${media.lessThan('medium')`
    margin-bottom: 0;
  `}
`;

export const Featured = styled.div`
  /* width: ${({ hasads }) => (hasads === 'true' ? 100 : 65)}%; */
  width: 93%;
  margin-right: ${metrics.baseSpacing(2)};

  display: flex;
  align-items: center;

  ${media.lessThan('1600px')`
    width: 80%;
  `}
 
  ${media.lessThan('huge')`
    /* width: ${({ hasads }) => (hasads === 'true' ? 100 : 75)}%; */
     width: calc(100% - 27%); /* calc used because setting percentage lazy loading breaks. */
  `}

  ${media.lessThan('large')`
    width: 100%;

    flex-direction: column;
  `}

  .featured-img-main {
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    width: 100%;
    height: 100%;

    margin-right: ${metrics.baseSpacing(3)};
    border-radius: ${metrics.borderRadius()};    

    > img {
      height: 100%;
      object-fit: cover;

       &:hover {
        transform: scale(1.1);
      }
 
      ${media.lessThan('large')`
        width: 100%;
        height: auto;
      `}

      ${media.lessThan('small')`        
        height: 100%;
      `}
    }

    ${media.lessThan('large')`
      width: 100%;
      margin-right: 0;
      height: ${pxToRem(240)};      

      flex-direction: column;
    `}
  }

  > .content {
    position: relative;

    height: 100%;    

    ${media.greaterThan('large')`
      max-width: ${pxToRem(328)};    
    `}

    ${media.greaterThan('1536px')`
      max-width: ${pxToRem(430)};
    `}

    ${media.lessThan('large')`
      margin-bottom: ${metrics.baseSpacing(4)};
    `}

    > h3 {
      margin-top: ${metrics.baseSpacing(2)};

      display: -webkit-box;

      ${fonts.size7};
      font-weight: 100;
      line-height: 1.2;
      color: ${colors.black};
      font-family: SFProDisplay-Semibold, sans-serif;
      overflow: hidden;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;

      &:hover {
        text-decoration: underline;
      }

      ${media.lessThan('large')`
        margin-top: ${metrics.baseSpacing(0.5)};

        ${fonts.size6};
        line-height: 1.2;
        color: ${colors.black};
        letter-spacing: -0.52px;
      `}
    }

    > span,
    > p {
      margin-top: ${metrics.baseSpacing(2)};

      display: -webkit-box;

      ${fonts.size5};
      line-height: 1.43;
      color: ${colors.grey};
      font-family: SFProDisplay-Regular, sans-serif;
      overflow: hidden;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;

      ${media.lessThan('large')`
        margin-top: ${metrics.baseSpacing(0.5)};

        ${fonts.size3};
        line-height: 1.56;
        color: ${colors.grey};
      `}
    }

    > span  {
      font-weight: 700;
      align-self: flex-end;
      ${media.greaterThan('1240px')`
        position: absolute;
        bottom: 0;
      `}
    }
  }
`;

export const SimpleNew = styled(SimpleNewComponent)`
  width: 100%;

  .img-wrapper {
    ${media.between('large', 'huge')`
      width: ${pxToRem(180)};
      max-width: ${pxToRem(180)};
      min-width: ${pxToRem(180)};

      height: ${pxToRem(110)};
      max-height: ${pxToRem(110)};
      min-height: ${pxToRem(110)};
    `}
  }

  & + a {
    margin-left: ${({ isVertical }) =>
    isVertical ? metrics.baseSpacing(2) : 0};

    ${media.lessThan('huge')`
      margin-left: ${({ isVertical }) => (isVertical ? '1.8rem' : 0)};
  `}
  }

  ${media.lessThan('large')`
    & + a {
      margin-top: ${metrics.baseSpacing()};

      ${media.lessThan('large')`
      margin-top: ${metrics.baseSpacing(4)};
      `}
    }
  `}
`;

export const Others = styled.div`
  width: 35%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  ${media.lessThan('huge')`
    width: 30%;
  `}

  ${media.lessThan('large')`
    width: 100%;
  `}
`;

export const Item = styled.div`
  & + div {
    margin-top: ${metrics.baseSpacing(4)};
  }
`;

export const Advertisement = styled(AdvertisementComponent)`
  margin-top: ${metrics.baseSpacing(2)};
  height: 100%;

  img {
    width: 100%;
    margin-left: auto;
    height: ${pxToRem(450)};
    /* width: ${pxToRem(390)}; */

    display: block;

    object-fit: fill;

    ${media.lessThan('huge')`
      /* width: ${pxToRem(310)}; */
    `}

    ${media.lessThan('large')`
      width: 100%;
    `}

    ${media.lessThan('small')`
      position: relative;
      left: 60%;

      width: 80%;
      height: auto;
      margin-left: -50%;
    `}
  }

  ${media.lessThan('large')`
    height: calc((${window.innerWidth}px -  24px) / 0.8);
    margin-top: ${metrics.baseSpacing(7)};
    margin-bottom: ${metrics.baseSpacing()};
  `}

  ${media.lessThan('small')`
    height: calc((${window.innerWidth}px -  24px));

    :before {
      left: 10%;
    }
  `}
`;

export const MoreButton = styled(MoreButtonComponent)`
  margin: ${metrics.baseSpacing(3)} auto ${metrics.baseSpacing(2)};

  svg {
    height: ${pxToRem(36)};
    width: ${pxToRem(36)};
  }

  span {
    position: absolute;
    left: 50%;
    transform: translateX(-38%);
  }
`;

export const Results = styled.a`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;

  height: ${pxToRem(66)};
  padding-right: ${pxToRem(10)};
  border-radius: ${pxToRem(4)};

  display: flex;
  align-items: flex-end;

  ${media.lessThan('large')`
    position: unset;

    width: ${pxToRem(400)};
    margin-top: ${metrics.baseSpacing()};
    border: 1px solid ${lighten(0.2, colors.blue)};

    overflow: hidden;
    background: ${colors.white};
  `}

  ${media.lessThan('1000')`
    width: ${pxToRem(330)};
    height: ${pxToRem(56)};
  `}

  ${media.lessThan('small')`
    width: ${pxToRem(250)};
    height: ${pxToRem(44)};
  `}

  > img {
    height: ${pxToRem(50)};
    margin-right: ${metrics.baseSpacing(2)};

    ${media.lessThan('1600px')`
      height: ${pxToRem(32)};
    `}

    ${media.lessThan('large')`
      display: none;
    `}
  }

  > .check {
    display: flex;
    align-items: center;

    > .info {      
      margin-left: ${pxToRem(2)};

      > .secondary,
      > .primary {
        ${fonts.size3};
        line-height: 1.1;

        ${media.lessThan('1600px')`
          ${fonts.size2};
        `}

        ${media.lessThan('large')`
          margin-left: ${metrics.baseSpacing()};
        `}
      }

      > .secondary {
        color: ${colors.lightBlue};
        font-family: SFProDisplay-SemiBold, sans-serif;
      }

      > .primary {
        color: ${colors.blue};
        font-family: SFProDisplay-Bold, sans-serif;
      }
    }
  }
`;

export const Icon = styled(IoMdTrophy)`
  color: ${colors.blue};
  font-size: ${pxToRem(32)};

  ${media.lessThan('huge')`
    font-size: ${pxToRem(24)};
  `}

  ${media.lessThan('large')`
    width: ${pxToRem(66)};
    height: ${pxToRem(66)};
    padding: ${pxToRem(12)};

    color: ${colors.white};
    font-size: ${pxToRem(32)};
    background: ${colors.blue};
  `}

  ${media.lessThan('medium')`
    width: ${pxToRem(64)};
    height: ${pxToRem(64)};
  `}

  ${media.lessThan('small')`
    width: ${pxToRem(44)};
    height: ${pxToRem(44)};
    padding: ${pxToRem(8)};

    font-size: ${pxToRem(24)};
  `}
`;

export const SkeletonContainer = styled.div`
  /* Mobile first */

  margin-bottom: ${pxToRem(32)};

  .image {
    margin-top: ${pxToRem(18)};
  }

  .date {
    margin-top: ${pxToRem(8)};
  }

  .new-title-wrapper {
    margin-top: ${pxToRem(4)};

    .new-title + .new-title {
      margin-top: ${pxToRem(4)};
    }

    + .new-title-wrapper {
      margin: ${pxToRem(8)} 0 ${pxToRem(18)};
    }
  }

  .secondary-news-wrapper {
    display: grid;
    grid-column-gap: ${pxToRem(6)};
    grid-template-columns: 170px 1fr;

    margin: ${pxToRem(16)} 0;

    width: 100%;
    height: 100%;

    span {
      height: 100%;
    }
  }

  ${media.greaterThan('large')`
    max-width: 1540px;
    margin: ${pxToRem(24)} auto ${pxToRem(32)};
    padding: ${pxToRem(12)};

    .category-skeleton-wrapper {
      display: grid;
      grid-gap: ${pxToRem(48)};
      grid-template-rows: 470px 290px;
      grid-template-columns: 3fr 1.2fr;
      grid-template-areas:
        'featured secondary-side'
        'secondary-bottom secondary-bottom';     

      margin-top: ${pxToRem(24)};

      div span {
        width: 100%;               
      }

      .featured-new-wrapper {
        grid-area: featured;

        display: flex;

        width: 100%;
        

        .featured-image-wrapper {
          flex: 1;

          span { 
            height: 100%;
          }
        }

        .featured-title-wrapper {
          flex: 1.5;
          
          width: 100%;
          margin-left: ${pxToRem(20)};

          .featured-title {
            margin-top: ${pxToRem(8)};            
          }

          .featured-description:first-child {
            margin-top: ${pxToRem(16)};            
          }
        }
      }

      .secondary-news-side {
        grid-area: secondary-side;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 100%;

        .secondary-side {
          display: flex;

          .secondary-image {
            margin-right: ${pxToRem(8)};
          }

          .secondary-title {
            margin-bottom: ${pxToRem(4)};
          }
        }
      }

      .secondary-news-bottom {
        grid-area: secondary-bottom;

        display: flex;
        justify-content: space-between;

        width: 100%;        

        .secondary-bottom-image {
          margin-bottom: ${pxToRem(12)};
        }

        .secondary-bottom-title {
          margin-bottom: ${pxToRem(4)};
        }
      }
    }
  `}
`;
