import styled from 'styled-components';
import media from 'styled-media-query';
import { push } from 'react-burger-menu';

import { pxToRem } from '@abqm/functions';
import { colors, fonts, metrics } from '@abqm/theme';
import { Button as ButtonComponent } from '@abqm/components';

export const Container = styled.div`
  position: absolute;

  width: ${pxToRem(38)};

  display: flex;
  flex-direction: row;
`;

export const Menu = styled(push)`
  width: 85% !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.17);

  opacity: 0.98;
  background-color: #1e2122;

  a {
    color: white;
  }
`;

export const Icon = styled.img`
  margin-left: ${pxToRem(12)};

  ${media.lessThan('medium')`
    margin-top: ${metrics.baseSpacing(2)};
  `}

  ${media.lessThan('1024px')`
    margin-top: ${metrics.baseSpacing(2)};
  `}

  ${media.lessThan('768px')`
    margin-top: ${metrics.baseSpacing(2)};
    margin-left: ${metrics.baseSpacing(2)};
  `}

  ${media.lessThan('small')`
    margin-top: ${metrics.baseSpacing(3)};
    margin-left: ${metrics.baseSpacing(2)};
  `}
`;

export const WrapperLine = styled.div`
  width: 100%;
  padding-left: ${pxToRem(16)};
  margin-bottom: ${pxToRem(16)};
  padding-right: ${pxToRem(16)};

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;

export const Line = styled.div`
  height: ${pxToRem(2)};

  background-color: #363d41;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  height: 10vh;
  padding-top: ${pxToRem(16)};
  padding-left: ${pxToRem(16)};
  padding-right: ${pxToRem(16)};
  padding-bottom: ${pxToRem(16)};

  flex-flow: row wrap;
  display: flex !important;
  justify-content: space-between;
`;

export const MenuFooter = styled.div`
  width: 100%;
  height: 20vh;

  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex !important;
`;

export const Socials = styled.div`
  width: 100%;
  margin-top: ${pxToRem(12)};

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  > .socials {
    display: flex;
    align-items: flex-end;

    a {
      height: ${pxToRem(23)};

      & + a {
        margin-left: ${metrics.baseSpacing(2)};
      }

      > img {
        height: ${pxToRem(32)};
      }
    }

    > .twitter {
      ${fonts.size7};
      line-height: 1.17;
      line-height: 1.22;
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
      color: ${colors.grey};
      letter-spacing: -0.96px;
      font-stretch: condensed;
      text-transform: uppercase;
      font-family: 'BebasNeue-Regular';
    }
  }
`;

export const Button = styled(ButtonComponent)`
  width: ${pxToRem(86)};
  height: ${pxToRem(36)};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Contact = styled(ButtonComponent)`
  width: ${pxToRem(140)};
  height: ${pxToRem(30)};

  line-height: 1.19;
  text-align: 'center';
  font-size: ${pxToRem(16)};
  font-family: 'SFRounded-Regular';
`;

export const WrapperList = styled.div`
  width: 100%;

  padding: ${metrics.baseSpacing(2)};
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pxToRem(300)};

  background-color: white;
  line-height: 1.19;
  text-align: center;
  font-size: ${pxToRem(20)};
  font-family: SFProDisplay-Regular, sans-serif;
  color: #566067;

  h2 {
    font-family: SFProDisplay-Bold !important;
  }

  p {
    margin-top: 20px;

    span {
      font-family: SFProDisplay-Bold !important;
    }
  }

  > #install-links {
    display: flex;
    margin-top: 30px !important;

    a {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
`;

export const CloseButton = styled.div`
  margin: -25px 0 25px 0;

  align-items: center;
  justify-content: flex-end;
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
`;
