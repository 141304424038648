import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { metrics } from '@abqm/theme';

export const Container = styled.div`
  width: 100%;
  padding: 0 ${pxToRem(80)};
  max-width: ${pxToRem(1700)};
  margin: ${metrics.baseSpacing(3)} auto;

  display: flex;
  justify-content: space-between;
  flex-direction: ${({ direction }) => direction};

  ${media.lessThan('1600px')`
    padding: ${pxToRem(12)};
    max-width: ${metrics.maxWidth};
  `}

  ${media.lessThan('large')`
    padding: ${pxToRem(12)} ${metrics.baseSpacing(2)};

    flex-direction: column;
  `}
`;
