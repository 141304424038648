import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { metrics, colors, fonts } from '@abqm/theme';

import ButtonGradient from '../ButtonGradient';
import ContentComponent from '../ContentWrap';

export const Container = styled.section`
  position: relative;

  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  /* background-image: url(${require('@abqm/theme/assets/images/background-magazine.jpg')}); */
  background-image: url(${props => props.bgImage});

  height: ${pxToRem(600)};
  padding: ${metrics.baseSpacing(4)};
  padding-top: ${pxToRem(118)};

  ${media.greaterThan('huge')`
    height: ${pxToRem(640)};
  `}

  ${media.lessThan('large')`
    height: auto;
  `}

  ${media.lessThan('medium')`
    padding-top: ${pxToRem(25)};
    background-size: auto 100%;
  `}

  > .ribbon {
    position: absolute;
    right: 0;
    top: ${metrics.baseSpacing(10)};

    width: ${pxToRem(310)};
    height: ${pxToRem(160)};

    display: flex;
    align-items: center;
    justify-content: flex-end;

    z-index: 6;
    background-repeat: no-repeat;
    background-size: ${pxToRem(314)};
    background-image: url(${require('@abqm/theme/assets/images/badges/ribbon.png')});

    ${media.lessThan('huge')`
      width: ${pxToRem(240)};
      height: ${pxToRem(120)};

      background-size: ${pxToRem(240)};
    `}

    ${media.lessThan('large')`
      top: ${metrics.baseSpacing(20)};
    `}

    ${media.lessThan('medium')`
      top: ${metrics.baseSpacing(12)};
    `}

    ${media.lessThan('small')`
      width: ${pxToRem(140)};
      height: ${pxToRem(70)};

      background-size: ${pxToRem(140)};
    `}

    > .text {
      margin-right: ${pxToRem(30)};

      ${fonts.size7};
      font-weight: 100;
      color: ${colors.white};
      font-family: SFRounded-Thin, sans-serif;

      ${media.lessThan('huge')`
        margin-right: ${metrics.baseSpacing(3)};

        font-size: 1.8rem;
      `}

      ${media.lessThan('large')`
        margin-right: ${pxToRem(24)};

        ${fonts.size6};
      `}

      ${media.lessThan('medium')`
        margin-right: ${pxToRem(40)};
      `}

      ${media.lessThan('small')`
        ${fonts.size4};
        margin-right: ${pxToRem(15)};
      `}
    }
  }
`;

export const Content = styled(ContentComponent).attrs({
  direction: 'row',
})`
  margin: 0;

  ${media.between('large', 'huge')`
    padding: 0 ${metrics.baseSpacing(4)};
  `}

  ${media.between('1280px', '1280px')`
    padding: 0;
  `}

  ${media.lessThan('large')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

export const Left = styled.div`
  width: 50%;

  ${media.lessThan('large')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}

  > .logo {
    height: ${pxToRem(100)};
    margin-bottom: ${metrics.baseSpacing(3)};

    ${media.lessThan('small')`
      height: ${pxToRem(50)};
    `}
  }

  > p {
    margin-bottom: ${metrics.baseSpacing(2)};

    ${fonts.size6};
    line-height: 1.38;
    color: ${colors.black};
    font-family: SFProDisplay-Regular, sans-serif;

    ${media.lessThan('large')`
      display: none;
    `}
  }
`;

export const Button = styled(ButtonGradient)`
  height: ${pxToRem(43)};
  width: ${pxToRem(152)};

  display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};

  ${fonts.size3};
  opacity: 1 !important;

  ${media.lessThan('large')`
    font-size: 1.1rem;
    font-family: SFProDisplay-Regular;
    margin: ${metrics.baseSpacing(3)} auto 0;
    display: ${({ isMobile }) => (!isMobile ? 'none' : 'flex')};
  `}
`;

const readMoreSize = 120;

export const Right = styled.div`
  position: relative;

  width: 50%;
  display: flex;
  justify-content: center;

  ${media.lessThan('large')`
    align-items: center;
    flex-direction: column;
  `}

  > .mockup {
    position: relative;
    z-index: 6;

    height: ${pxToRem(570)};

    ${media.lessThan('huge')`

      height: ${pxToRem(510)};
    `}

    ${media.lessThan('large')`

      height: ${pxToRem(688)};
    `}

    ${media.lessThan('small')`
      height: ${pxToRem(370)};
    `}
  }

  > .mockup > img {
    height: 100%;
  }

  > .shade {
    position: absolute;
    right: 0;
    bottom: ${pxToRem(70)};
    z-index: 7;
    /* Ultimo commit */

    border-radius: 50%;
    width: ${pxToRem(readMoreSize + 8)};
    height: ${pxToRem(readMoreSize + 8)};
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.06);

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(123, 103, 88, 0.2);

    ${media.lessThan('large')`
      display: none;
    `}

    > a {
      border-radius: 50%;
      width: ${pxToRem(readMoreSize)};
      height: ${pxToRem(readMoreSize)};

      display: flex;
      align-items: center;
      justify-content: center;

      ${fonts.size6};
      cursor: pointer;
      font-weight: 100;
      color: ${colors.white};
      font-family: SFRounded-Thin, sans-serif;
      background-color: rgba(123, 103, 89, 0.87);
    }
  }
`;
