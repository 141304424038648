import styled from 'styled-components';

import { pxToRem } from '@abqm/functions';
import { fonts, colors } from '@abqm/theme';

export const Container = styled.div`
  height: 54vh;

  flex-direction: column;
  display: flex !important;
  overflow-y: scroll !important;

  ::-webkit-scrollbar {
    width: ${pxToRem(7)};

    -webkit-appearance: none;
    background-color: ${colors.dark};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: ${pxToRem(7)};

    background-color: #363d41;
  }

  .invisible-scrollbar::-webkit-scrollbar {
    display: none;
  }

  a {
    margin: 0px 0px 24px 0px;

    ${fonts.size4};
    font-weight: 500;
    text-align: left;
    line-height: 1.17;
    color: #a7b8c4 !important;
    font-family: SFRounded-Regular, sans-serif;
  }
`;
