import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '@abqm/theme';

import { Container } from './styles';

export default function SectionTitle({ title, color, withIcon }) {
  return (
    <Container color={color}>
      <h1>{title}</h1>

      {/* {withIcon && <MoreButton color={color} onClick={() => {}} term={title} />} */}
    </Container>
  );
}

SectionTitle.defaultProps = {
  color: colors.grey,
  withIcon: false,
};

SectionTitle.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  withIcon: PropTypes.bool,
};
