import styled from 'styled-components';
import media from 'styled-media-query';

import { metrics, fonts, colors } from '@abqm/theme';
import { pxToRem } from '@abqm/functions';

export const Container = styled.div`
  position: relative;
  margin-top: ${metrics.baseSpacing(3)};

  width: 80%;
  height: fit-content;

  margin-left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: space-around;

  &:before {
    position: absolute;
    left: -${pxToRem(30)};

    display: block;

    height: 50%;
    width: ${pxToRem(1)};

    content: '';
    opacity: 0.2;
    background: ${colors.grey};

    ${media.lessThan('760px')`
      display: none;
    `}
  }

  &:after {
    position: absolute;
    right: -${pxToRem(30)};

    display: block;

    height: 50%;
    width: ${pxToRem(1)};

    content: '';
    opacity: 0.2;
    background: ${colors.grey};

    ${media.lessThan('760px')`
      display: none;
    `}
  }

  ${media.lessThan('large')`
    margin-top: 0;
    width: 100%;
  `}

  ${media.lessThan('760px')`
    width: 110%;
    overflow-x: scroll;
    /* scroll-behavior: smooth;
    scroll-snap-type: x mandatory; */

    -webkit-overflow-scrolling: touch !important;

    ::-webkit-scrollbar {
      display: none !important;
    }

    justify-content: flex-start;

    margin-left: -${pxToRem(20)};
    padding-left: ${pxToRem(10)};
    padding-right: ${pxToRem(20)};

    transform: translateX(0);
  /*
    & :first-child,
    & :last-child {
      scroll-snap-align: start;
    } */
  `}
`;

export const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: ${pxToRem(155)};
  height: ${pxToRem(120)};

  ${media.lessThan('huge')`
    width: ${pxToRem(140)};
    height: ${pxToRem(105)};
  `}

  ${media.lessThan('medium')`
    width: auto;
    height: ${pxToRem(80)};
  `}
`;

export const ButtonBackground = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.3s;

  width: ${pxToRem(94)};
  height: ${pxToRem(94)};

  :hover {
    transform: scale(0.9);

    background-color: rgba(59, 145, 177, 0.3);

    .Icon {
      transform: scale(0.8);
    }
  }

  ${media.lessThan('huge')`
    width: ${pxToRem(80)};
    height: ${pxToRem(80)};
  `}

  ${media.lessThan('medium')`
    width: ${pxToRem(60)};
    height: ${pxToRem(60)};
  `}

  ${media.lessThan('small')`
    :hover {
      transform: none;

      .Icon {
        transform: none;
      }
    }
  `}

  border-radius: 50%;
  background-image: linear-gradient(
    to bottom,
    rgba(59, 145, 177, 0.17),
    rgba(135, 197, 0, 0.17) 111%
  );
`;

export const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 90%;
  height: 90%;

  border-radius: ${pxToRem(300)};
  background-image: linear-gradient(to bottom, #3b91b1, #87c500 111%);
`;

export const Icon = styled.img`
  max-height: 50%;
  max-width: 60%;
`;

export const ButtonTitle = styled.p`
  display: block;

  width: 100%;
  height: ${pxToRem(22)};
  ${fonts.size3};
  font-family: SFProDisplay-Regular;
  letter-spacing: -0.48px;
  text-align: center;
  color: #a7b8c4;

  ${media.lessThan('1536px')`
    ${fonts.size2};
  `}

  ${media.lessThan('huge')`
    ${fonts.size1};
  `}

  ${media.lessThan('large')`
    font-size: 14px;
  `}

  ${media.lessThan('medium')`
    font-size: 11px;
  `}

  ${media.lessThan('760px')`
    white-space: nowrap;
    width: 85px;
    letter-spacing: -0.33px;
    height: ${pxToRem(14)};
  `}

  ${media.lessThan('360px')`
    width: 80px;
  `}

  ${media.lessThan('340px')`
    width: 75px;
  `}
`;
