import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { lighten } from 'polished';

import { pxToRem } from '@abqm/functions';
import { colors, metrics, fonts } from '@abqm/theme';

import ButtonComponent from '../Button';

export const Container = styled(ButtonComponent)`
  opacity: 1;
  border: none;
  height: ${pxToRem(47)};
  border-radius: ${pxToRem(50)};
  padding: 0 ${metrics.baseSpacing(4)};

  display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};

  ${fonts.size5};
  background-image: linear-gradient(
    to bottom,
    ${colors.green},
    ${colors.lightGreen} 111%
  );
  &:hover {
    box-shadow: 0px 3px 5px #3333 !important;
    background-image: linear-gradient(
      to bottom,
      ${lighten(0.01, colors.green)},
      ${lighten(0.01, colors.lightGreen)} 111% !important
    );
  }


  ${({ isLoading }) =>
    isLoading &&
    css`
      background-image: linear-gradient(
        to bottom,
        ${lighten(0.03, colors.green)},
        ${lighten(0.03, colors.lightGreen)} 111%
      );
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-image: linear-gradient(
        to bottom,
        ${lighten(0.03, colors.fadedGrey)},
        ${lighten(0.03, colors.lightGrey)} 111%
      );
      &:hover {
        background-image: linear-gradient(
          to bottom,
          ${lighten(0.01, colors.fadedGrey)},
          ${lighten(0.01, colors.lightGrey)} 111% !important
        );
      }
    `}

  ${media.lessThan('large')`
    height: ${pxToRem(51)};
    padding: 0 ${metrics.baseSpacing(2)};

    display: ${({ isMobile }) => (isMobile ? 'flex' : 'none')};

    ${fonts.size4};
  `}

  ${media.lessThan('small')`
    height: ${pxToRem(41)};
  `}
`;
