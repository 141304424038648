export default {
  dark: '#1e2122',
  black: '#333',
  darkBlue: '#363d41',

  blue: '#3b91b1',
  brightBlue: '#1890ff',
  lightBlue: '#a7b8c4',

  grey: '#566067',
  darkgrey: '#171414',
  mediumGrey: '#707070',
  eightGrey: '#8a8888',
  fadedGrey: '#b4b0b1',
  lightGrey: '#d8d8d8',
  observatoryGrey: '#f9f9f9',
  menuGrey: '#f0f0f0',

  darkGreen: '#719104',
  green: '#329a58',
  lightGreen: '#87c500',

  white: '#fff',
  darkWhite: '#f5f7f8',
  greyWhite: '#cecdcd',

  yellow: '#efe187',

  orange: '#dd8800',
  mediumOrange: '#eda01c',

  danger: '#f07d7d',
  warning: '#f2d000',
  success: 'hsl(141, 71%, 48%)',

  transparent: 'transparent',
};
