import React, { useState, useEffect } from 'react';
import { urls, api } from '@abqm/services';

import { Container, Content, Left, Button, Right } from './styles';

const IMAGE_ENDPOINT = urls.imageURL;

export default function MagazineQM() {
  const defaultQuery = {
    pagesize: 1,
    index_id: 28,
    getCover: true,
  };
  const [magazine, setMagazine] = useState([]);
  const [magazineCover, setMagazineCover] = useState([]);

  const getImage = (imageId) =>
    magazineCover.length
      ? `${IMAGE_ENDPOINT}${magazineCover[0]["content_files"].find(item => item["config_file_id"] === imageId).file}`
      : ''

  const bgImage = getImage(16)
  const coverImage = getImage(17)
  const editionNumber = magazineCover.length && magazineCover[0].text

  useEffect(() => {
    async function searchMagazine() {
      try {
        const magazineResponse = await api.get('/contents', {
          params: {
            page: 1,
            ...defaultQuery,
          },
        });

        const magazineCoverResponse = await api.get('/lastmagazine');
        setMagazine([...magazineResponse.data.rows]);
        setMagazineCover([...magazineCoverResponse.data]);
      } catch (err) {
        console.log(err);
      }
    }
    searchMagazine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container bgImage={bgImage}>
      <Content>
        <Left>
          <img
            className="logo"
            alt="Logo da Revista Quarto de Milha"
            src={require('@abqm/theme/assets/images/logos/logo-revista.svg')}
          />

          <p>
            A Revista Quarto de Milha traz informações atuais de todas as
            atividades nas quais a raça é notícia, tanto no Brasil como no
            exterior. Nela, você poderá acompanhar os principais campeonatos de
            Trabalho, incluindo as Vaquejadas nordestinas, as provas de Laço
            Comprido nas regiões Centro-Oeste ou no Sul do País.
          </p>

          <Button title="Confira" href={urls.magazineURL} />
        </Left>

        <Right>
          <a
            className="mockup"
            href={`${magazine[0]?.resume}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Revista Quarto de Milha"
              src={coverImage}
            />
          </a>

          <div className="shade">
            <a
              href={`${magazine[0]?.resume}`}
              className="red"
              target="_blank"
              rel="noopener noreferrer"
            >
              Leia Agora
            </a>
          </div>

          <Button title="Confira" isMobile href={urls.magazineURL} />
        </Right>
      </Content>

      <a
        className="ribbon"
        href={`${magazine[0]?.resume}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p className="text">{`Edição ${editionNumber}`}</p>
      </a>
    </Container>
  );
}
