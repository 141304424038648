import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { urls, api, indexes } from '@abqm/services';

import NavMobile from '../NavMobile';

// import logoInterno from '../../themes/assets/logo-abqm.png';
// import LogoQuartoMilhaPreto from '../../themes/assets/logo-abqm.png';

import { Container, NavDesktop } from './styles';

export default function NavMenu({ hotsite }) {
  const [newsUrl, setNewsUrl] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get(`hotsite/`, {
          params: {
            parent_id: hotsite[0].index_id,
          },
        });

        const conteudoNews = data.find(obj => {
          return obj.title === 'Notícias';
        });

        const dNot = await api.get('contents', {
          params: {
            page: 1,
            pagesize: 3,
            index_id: conteudoNews.id,
            getCover: true,
          },
        });

        setNewsUrl(dNot.data.rows[0].url);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [hotsite]);





  return (
    <Container>
      <NavMobile hotsite={hotsite} />
      {hotsite.map(item => (
        <NavDesktop>
          <Link to="/">
            <img src={`${urls.hotsitesImageURL}/${item.logo}`} alt="LogoAbqm" />
          </Link>

          <div className="nav">
            <Link to={`/hotsite/${item.url}`}>Home</Link>

            <Link to={`/provas/${item.url}`}>Provas</Link>

            <Link to="/sala-de-imprensa">Sala de Imprensa</Link>

            <Link to={`/noticias/${newsUrl}`}>Notícias</Link>

            <Link to={`/campeoes/${item.url}`}>Campeões</Link>

            <a
              href="https://seqm.com.br/"
              rel="noopener noreferrer"
              target="_blank"
            >
              SEQM
            </a>
          </div>

          <Link to="/provas/">
            <div className="logoCampeonato">
              <img
                src={`${urls.hotsitesImageURL}/${item.imagem_topo}`}
                className="LogoCampeonato"
                alt="LogoCampeonato"
              />
            </div>
          </Link>
        </NavDesktop>
      ))}
    </Container>
  );
}
