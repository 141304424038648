import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import { SectionTitle } from '@abqm/components';
import { colors } from '@abqm/theme';
import { urls } from '@abqm/services';

import { isModOf } from '@abqm/functions';


import {
  Icon,
  Others,
  Content,
  Results,
  Featured,
  Container,
  Item,
  SimpleNew,
  MoreButton,
  CategoryTitle,
  Advertisement,
  SkeletonContainer,
} from './styles';

export default function CategoryNews({
  data,
  type,
  color,
  withAds,
  isMobile,
  category,
  withIcon,
  isResults,
  fetchMore,
  loading,
  ...rest
}) {
  const [numberOfItems, setNumberOfItems] = useState(3);
  const [currentPage, setCurrentPage] = useState(2);
  const [hasAds, setHasAds] = useState(true); //eslint-disable-line

  const featuredNew = data.rows && data.rows[0];

  // function hasAdsShow(currentItem) {
  //   return numberOfItems !== currentItem + 1;
  // }

  function handleFetchMore() {
    setNumberOfItems(numberOfItems + 4);

    fetchMore(currentPage);

    setCurrentPage(currentPage + 1);
  }

  function renderSection() {
    const featuredNewUrl = `noticias/${featuredNew?.url}` || '';
    const newsCategoryUrl = `mais-noticias/${category.replace(/\s/g, '-').toLowerCase()}` || '';

    return (
      <>
        <SectionTitle title={category} color={color} withIcon={withIcon} />

        <div className="top">
          <Featured
            hasads={String(hasAds)}
            withads={String(withAds)}
            to={`noticias/${featuredNew?.url}` || ''}
            isresults={String(isResults)}
          >
            <Link to={featuredNewUrl} className="featured-img-main">
              <img
                alt="Notícia destaque"
                src={`${urls.imageURL}${featuredNew?.cover?.file}`}
              />
            </Link>

            <div className="content" style={{ display: 'flex', flexDirection: 'column' }}>
              <CategoryTitle
                isFeatured
                type={type}
                date={featuredNew?.date}
                title={featuredNew?.index.title}
                withoutCategory={category !== 'Notícias Recentes'}
              />

              <h3>
                <Link to={featuredNewUrl}>{featuredNew?.title}</Link>
              </h3>

              <p>
                <Link to={featuredNewUrl}>{featuredNew?.resume}</Link>
              </p>

              <span>
                <Link to={newsCategoryUrl}>Mais notícias</Link>
              </span>
              {isResults && featuredNew?.link_externo && (
                <Results
                  target="_blank"
                  rel="noopener"
                  href={featuredNew?.link_externo}
                >
                  <img
                    src={require('@abqm/theme/assets/images/logos/logo-seqm.svg')}
                    alt="Sem Logo"
                  />

                  <div className="check">
                    <Icon />

                    <div className="info">
                      <p className="secondary">Confira aqui os</p>
                      <p className="primary">Resultados deste evento</p>
                    </div>
                  </div>
                </Results>
              )}
            </div>
          </Featured>

          {withAds && !isMobile ? (
            <Others>
              <Advertisement slots="8" watermarkType="horizontalRight" />
            </Others>
          ) : (
            <Others>
              {data.rows &&
                data.rows
                  .filter((item, index) => index > 0 && index <= numberOfItems)
                  .map((item, itemIndex) => (
                    <Item key={item.id}>
                      <SimpleNew
                        key={item.id}
                        {...item}
                        banner={`${urls.imageURL}${item?.cover?.file}`}
                      />

                      {isModOf(3, itemIndex + 1) && isMobile && (
                        <Advertisement ads={[]} slots={['8']} />
                      )}
                    </Item>
                  ))}
            </Others>
          )}
        </div>

        <div className="bottom">
          {data.rows &&
            data.rows
              .filter((item, index) => index > 3)
              .map(item => (
                <SimpleNew
                  key={item.id}
                  isVertical
                  {...item}
                  banner={`${urls.imageURL}${item?.cover?.file}`}
                />
              ))}
        </div>

        <MoreButton
          isMobileLayout
          color={color}
          onClick={handleFetchMore}
          term={category}
        />
      </>
    );
  }

  if (loading)
    return (
      <SkeletonContainer>
        {isMobile ? (
          <>
            <Skeleton width={200} height={30} className="category-title" />
            <Skeleton height={240} className="image" />

            <div className="new-title-wrapper">
              <Skeleton height={24} count={3} className="new-title" />
            </div>

            <div className="new-title-wrapper">
              <Skeleton height={14} count={4} className="new-description" />
            </div>

            {[1, 2, 3].map(item => (
              <div key={item} className="secondary-news-wrapper">
                <Skeleton height={120} className="secondary-new-image" />
                <Skeleton
                  height={14}
                  count={5}
                  className="secondary-new-title"
                />
              </div>
            ))}
          </>
        ) : (
          <>
            <Skeleton width={200} height={30} className="category-title" />

            <div className="category-skeleton-wrapper">
              <div className="featured-new-wrapper">
                <div className="featured-image-wrapper">
                  <Skeleton width={466} />
                </div>

                <div className="featured-title-wrapper">
                  <Skeleton height={10} width={200} />
                  <Skeleton height={24} count={3} className="featured-title" />
                  <Skeleton
                    height={14}
                    count={5}
                    className="featured-description"
                  />
                </div>
              </div>

              <div className="secondary-news-side">
                {[1, 2, 3].map(item => (
                  <div className="secondary-side" key={item}>
                    <Skeleton
                      width={250}
                      height={130}
                      className="secondary-image"
                    />
                    <Skeleton
                      height={16}
                      count={5}
                      className="secondary-title"
                    />
                  </div>
                ))}
              </div>

              <div className="secondary-news-bottom">
                {[1, 2, 3, 4, 5, 6].map(item => (
                  <div key={item} className="secondary-new">
                    <Skeleton
                      width={220}
                      height={130}
                      className="secondary-bottom-image"
                    />
                    <Skeleton
                      height={16}
                      count={5}
                      className="secondary-bottom-title"
                    />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </SkeletonContainer>
    );

  return (
    <Container isResults={isResults} {...rest}>
      {isResults ? <Content>{renderSection()}</Content> : renderSection()}
    </Container>
  );
}

CategoryNews.defaultProps = {
  type: 'default',
  category: 'Notícias Recentes',
  color: colors.darkGreen,
  withIcon: false,
  withAds: false,
  isMobile: false,
  isResults: false,
  fetchMore: () => { },
  data: {
    rows: [],
  },
};

CategoryNews.propTypes = {
  category: PropTypes.string,
  color: PropTypes.string,
  withIcon: PropTypes.bool,
  withAds: PropTypes.bool,
  isMobile: PropTypes.bool,
  isResults: PropTypes.bool,
  fetchMore: PropTypes.func,
  type: PropTypes.string,
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape()),
  }),
  loading: PropTypes.bool,
};
