import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { darken } from 'polished';

import { pxToRem } from '@abqm/functions';
import { metrics, colors, fonts } from '@abqm/theme';

import {
  SearchInput as SearchInputComponent,
  Socials as SocialsComponent,
} from '../../index';

export const Container = styled.header`
  width: 100%;

  ${media.lessThan('large')`
    display: none;
  `}

  ${media.lessThan('1280px')`
    padding: 0 ${metrics.baseSpacing(2)};
  `}

  ${props =>
    props.type === 'services' &&
    css`
      overflow-x: hidden;
    `}

  > .content {
    position: relative;

    width: 100%;
    height: ${props => (props.internal ? pxToRem(106) : pxToRem(130))};
    padding-bottom: ${props =>
    props.observatory ? 0 : metrics.baseSpacing(3)};
    margin-bottom: ${({ isWithContents }) =>
    isWithContents ? 0 : metrics.baseSpacing(3)};

    ${props =>
    props.type === 'services' &&
    css`
        margin-bottom: 0;
        margin-left: 3%;
        width: 94%;
      `}

    display: flex;
    align-items: ${props => (props.observatory ? 'center' : 'flex-end')};
    justify-content: space-between;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${pxToRem(2)};

      display: block;

      content: '';
      opacity: 0.5;
      background: ${colors.lightGrey};

      ${props =>
    props.type === 'services' &&
    css`
          width: 150%;
          margin-left: -20%;
        `}
    }

    ${media.lessThan('huge')`
      height: ${props => (props.internal ? pxToRem(90) : pxToRem(130))};
    `}

    ${props =>
    props.type === 'external' &&
    css`
        margin: 0;
        padding: 0;
        height: ${props.magazine ? pxToRem(90) : pxToRem(60)} !important;

        > .browse {
          height: 100%;
          position: relative;
          margin-left: ${metrics.baseSpacing(16)};

          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;

          > a {
            &.link {
              display: flex;
              align-items: center;

              & + .link {
                margin-top: ${pxToRem(7)};
              }

              ${fonts.size1};
              color: ${colors.lightBlue};
              font-family: SFProDisplay-Regular, sans-serif;

              > .icon {
                margin-right: ${metrics.baseSpacing(1)};
                height: ${pxToRem(18)};
              }
            }
          }

          &:before {
            position: absolute;
            left: -${metrics.baseSpacing(2)};
            content: '';
            background: ${colors.lightGrey};
            height: 75%;
            width: ${pxToRem(1.5)};
          }
        }
      `}

    > .left,
    > .right {
      width: ${props => (props.internal ? '100%' : '100%')};

      padding-left: 10px;

      ${media.lessThan('huge')`
        transform: scale(0.8);
      `}
    }

    > .left {
      position: relative;

      display: flex;
      align-items: center;

      ${media.lessThan('huge')`
        transform: scale(0.8) translateX(-14%);
      `}

      ${({ internal, observatory }) =>
    (internal || observatory) &&
    css`
        &:after {
          position: absolute;
          top: 50%;
          left:  ${props => (props.observatory ? '66%' : '48%')};
          transform: translateY(-50%);

          width: ${pxToRem(2)};
          height: ${pxToRem(60)};

          display: ${props => (props.internal ? 'none' : 'block')};

          content: '';
          opacity: 0.5;
          background: ${colors.lightGrey};

          ${media.lessThan('1536px')`
            left: ${props => (props.observatory ? '80%' : '58%')};
          `}

          ${media.lessThan('huge')`
            left:  ${props => (props.observatory ? '85%' : '66%')};
          `}

          ${media.lessThan('1300px')`
            left:  ${props => (props.observatory ? '86%' : '69%')};
          `}
        }
      `}

      .logo-abqm {
        position: relative;
        top: ${props => (props.internal ? '0' : '0')};

        height: ${props => (props.internal ? '60px' : '70px')};

        ${media.lessThan('huge')`
          height: ${props => (props.internal ? '45px' : '70px')};
        `}
      }

      > .observatory-title {
        display: block;
        margin-left: ${metrics.baseSpacing(8)};
        margin-right: ${metrics.baseSpacing(2)};
        width: ${pxToRem(226)};

        p {
          ${fonts.size8};
          color: ${colors.black};
          line-height: 1;
          font-family: SFProDisplay-Regular, sans-serif;
          margin-left: ${pxToRem(32)};
        }

        &:after {
          position: absolute;
          top: 50%;
          left: 33%;
          transform: translateY(-50%);

          width: ${pxToRem(2)};
          height: ${pxToRem(60)};

          display: ${props => (props.internal ? 'none' : 'block')};

          content: '';
          opacity: 0.5;
          background: ${colors.lightGrey};

          ${media.lessThan('1536px')`
            left: 40%;
          `}

          ${media.lessThan('huge')`
            left: 43%;
          `}

          ${media.lessThan('1300px')`
            left: 44%;
          `}
        }
      }

      > .menu {
        margin-top: ${props => (props.internal ? '0' : metrics.baseSpacing())};
        margin-left: ${metrics.baseSpacing(5)};

        display: flex;
        align-items: center;
        justify-content: center;

        > li {
          /* padding: 0 ${metrics.baseSpacing(1)}; */

          & + li {
            margin-left: ${metrics.baseSpacing(3)};
          }

          > a {
            ${fonts.size3};

            ${media.between('1500px', '1540px')`
              ${fonts.size1};
            `}

            transition: 0.3s;
            font-weight: 500;
            text-align: center;
            color: ${colors.black};
            white-space: nowrap;
            font-family: SFProDisplay-Regular, sans-serif;

            &:hover {
              color: ${darken(0.5, colors.black)};
            }
          }
        }
      }

      > .browse {
        position: relative;
        margin-left: ${metrics.baseSpacing(6)};

        display: flex;
        align-items: flex-start;
        flex-direction: column;

        ${media.lessThan('huge')`
          margin-left: ${metrics.baseSpacing(5)};
        `}

        > span {
          margin-bottom: ${metrics.baseSpacing(0.5)};

          ${fonts.size2};
          font-weight: bold;
          line-height: 1.23;
          text-align: center;
          color: ${colors.grey};
          text-transform: uppercase;
          font-family: Humanist777BT-BoldCondensedB, sans-serif;
        }

        > a {
          &.link {
            display: flex;
            align-items: center;

            & + .link {
              margin-top: ${pxToRem(10)};
            }

            ${fonts.size1};
            color: ${colors.lightBlue};
            font-family: SFRounded-Regular, sans-serif;

            > .icon {
              margin-right: ${metrics.baseSpacing(0.5)};
              height: ${pxToRem(15)};
            }
          }

          .logo-event {
            height: ${pxToRem(80)};
          }

          ${props =>
    props.internal &&
    css`
              &:before {
                position: absolute;
                left: -${metrics.baseSpacing(2)};
                content: '';
                background: ${colors.lightGrey};
                height: 75%;
                width: ${pxToRem(1.5)};
              }
            `}
        }
      }
    }

    > .right {
      height: ${props => (props.internal ? pxToRem(60) : 'auto')};
      display: flex;
      align-items: ${props => (props.internal ? 'center' : 'flex-end')};
      justify-content: flex-end;

      ${media.lessThan('huge')`
        transform: scale(0.8) translateX(14%);
      `}

      > .animal-consult {
        position: relative;

        height: ${pxToRem(92)};
        width: ${pxToRem(100)};
        padding: 0 ${pxToRem(10)};
        margin-right: ${metrics.baseSpacing(6)};
        border: ${pxToRem(2)} solid ${colors.lightGrey};

        ${media.lessThan('huge')`
          margin-right: ${metrics.baseSpacing(3)};
        `}

        > .content {
          position: absolute;
          left: 50%;
          top: -5px;
          transform: translateX(-50%);

          height: 120%;
          padding: ${metrics.baseSpacing()};

          background: ${colors.white};

          > h4,
          > span {
            width: 100%;

            display: block;

            ${fonts.size2};
            line-height: 0.5;
            text-align: center;
            color: ${colors.grey};
            text-transform: uppercase;
            font-family: Humanist777BT-BoldCondensedB, sans-serif;
          }

          > h4 {
            margin-bottom: ${metrics.baseSpacing()};

            font-weight: 700;
          }

          > span {
            ${fonts.size1};
          }
        }
      }
    }

    > .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      height: ${props => (props.magazine ? pxToRem(48) : pxToRem(50))};

      img {
        height: 100%;
        padding: ${pxToRem(4)};
      }
    }

    > .menu-home {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        align-items: center;
        justify-content: center;

        > li {
          /* padding: 0 ${metrics.baseSpacing(1)}; */

          & + li {
            margin-left: ${metrics.baseSpacing(3)};
          }

          > a {
            ${fonts.size3};

            ${media.lessThan('1536px')`
              ${fonts.size2};
            `}

            ${media.lessThan('1280px')`
              ${fonts.size1};
            `}

            transition: 0.3s;
            font-weight: 500;
            text-align: center;
            color: ${colors.black};
            white-space: nowrap;
            font-family: SFRounded-Medium, sans-serif;

            &:hover {
              color: ${darken(0.5, colors.black)};
            }
          }
        }
      }
  }

  > .menu {
    position: relative;
    z-index: 999999999;
    margin-top: ${metrics.baseSpacing()};

    display: flex;
    align-items: center;
    justify-content: center;

    ${media.lessThan('huge')`
      transform: scale(0.8);
    `}

    > li {
      position: relative;
      padding: 0 ${metrics.baseSpacing(2)};

      ${props =>
    props.observatory &&
    css`
          padding-right: ${metrics.baseSpacing(4)};
        `}

      a {
        ${fonts.size4};
        white-space: nowrap;
        transition: 0.3s;
        font-weight: 500;
        text-align: center;
        color: ${colors.black};
        font-family: SFRounded-Medium, sans-serif;
      }

      &:hover {
        > a {
          color: ${darken(0.5, colors.black)};
        }

        > ul {
          visibility: visible;
          pointer-events: auto;
          opacity: 1;
          transition: 0.3s;
        }
      }

      > ul {
        display: block;
        pointer-events: none;

        z-index: 9999;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 100%;
        left: ${metrics.baseSpacing(2.5)};

        cursor: pointer;
        transition: 0.3s;

        > li {
          margin-top: ${pxToRem(1.5)};

          :first-child {
            margin-top: ${pxToRem(8)};

            border-top: solid ${pxToRem(3)} ${colors.black};
          }

          > a {

            display: block;
            text-align: left;
            padding: ${metrics.baseSpacing(1.5)};

            background: ${colors.menuGrey};
            ${fonts.size3};
            color: ${colors.black};
          }

        }
      }
    }
  }
`;

export const MenuArrow = styled.img`
  display: block;

  position: absolute;
  right: ${metrics.baseSpacing(1.5)};
  top: 50%;

  transform: translateY(-50%);
`;

export const SearchInput = styled(SearchInputComponent)`
${props =>
    !props.isWithContents &&
    css`
    height: ${pxToRem(40)};
    max-width: ${pxToRem(400)};

    > input {
      ${fonts.size4};
    }

    svg {
      width: ${pxToRem(20)};
    }
  `}

  ${props =>
    props.internal &&
    css`
      height: ${pxToRem(40)};
      max-width: ${pxToRem(400)};

      ${media.lessThan('1536px')`
        max-width: ${pxToRem(250)};
      `}

      ${media.lessThan('huge')`
        margin-top: ${pxToRem(15)};
        height: ${pxToRem(30)};
        max-width: ${pxToRem(250)};
      `}

      > input {
        ${fonts.size3};
      }

      svg {
        width: ${pxToRem(20)};
      }
    `}

  ${media.lessThan('medium')`
    width: 100%;

    ${props =>
      props.internal &&
      css`
        > input {
          ${fonts.size3};
        }
      `}
  `}
`;

export const Center = styled.div`
  width: 100%;

  align-self: center;
  margin-top: ${({ isWithContents }) => (isWithContents ? '0' : pxToRem(26))};
  display: flex;
  justify-content: ${({ isWithContents }) =>
    isWithContents ? 'center' : 'flex-start'};

  ${media.lessThan('huge')`
      width: ${({ isWithContents }) => (isWithContents ? 120 : 100)}%;
  `}

  > div {
    ${({ isWithContents }) =>
    isWithContents &&
    css`
        max-width: unset;
      `};
  }
`;

export const Socials = styled(SocialsComponent)`
  transform: scale(0.9);

  ${media.lessThan('1536px')`
    transform: scale(0.8);
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;
