import React, { useState, useEffect, useCallback } from 'react';

import { Link } from 'react-router-dom';

//import api from '../../../../services/api';
import { urls, api, indexes } from '@abqm/services';
import { handleScrollTop } from '@abqm/functions';

import { Container, ItemsMenu } from './styles';
import { RiContactsBookLine } from 'react-icons/ri';

export default function HomeNavContent({ hotsite, conteudos }) {
  const [navContent, setNavContent] = useState([]);
  const [style, setStyle] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {

        const { data } = await api.get('contents', {
          params: {
            page: 1,
            pagesize: 99,
            index_id: conteudos.id,
            getCover: false,
          },
        });

        setNavContent(data.rows);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    fetchData();
  }, [conteudos]);

  useEffect(() => {
    try {
      setStyle({ border: 'none', color: `${hotsite[0].cor_menu_textos}`, backgroundColor: `${hotsite[0].cor_documentos_botoes}` });

    } catch (error) {
      console.log(error);
    } finally {
    }
  }, [hotsite]);

  return (
    <Container>
      {hotsite.map(hs => (
        <ItemsMenu>
          {navContent.map(item => (
            <li key={item.id}>
              <div style={style}>
                {item.link_externo ? (
                  <a href={item.link_externo} target={item.link_em_nova_aba}>
                    {item.title}
                  </a>
                ) : (
                  <Link
                    to={{
                      pathname: `/provas/${hs.url}`,
                      idLocation: item.id,
                    }}
                    onClick={handleScrollTop}
                  >
                    {item.title}
                  </Link>
                )}
              </div>
            </li>
          ))}
        </ItemsMenu>
      ))}
    </Container>
  );
}
