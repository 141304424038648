import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { withSizes } from 'react-sizes';
import ReactHtmlParser from 'react-html-parser';
import { useParams, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import {
  Header,
  Footer,
  SectionTitle,
  CategoryTitle,
  BlackContainer,
} from '@abqm/components';
import { urls, api } from '@abqm/services';

import {
  Cover,
  Share,
  Content,
  Container,
  ContentBody,
  ContentHeader,
  ContentFooter,
  AssociatedNew,
  AssociatedNews,
  ButtonGradient,
  SkeletonContainer,
} from './styles';

function News({ isMobile, width }) {
  const { url } = useParams();
  const history = useHistory();

  const [cover, setCover] = useState(null);
  const [content, setContent] = useState({});
  const [associatedNews, setAssociatedNews] = useState([]);
  const [associatedNewsPage, setAssociatedNewsPage] = useState(1);
  const [isLoadingAssociatedNews, setIsLoadingAssociatedNews] = useState(false);
  const [isDisabledBtnMoreNews, setIsDisabledBtnMoreNews] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);

  function handleAssociatedNewsSlots() {
    const windowWidth = window.innerWidth;

    if (windowWidth <= 1440) {
      return 10;
    }

    if (windowWidth <= 1100) {
      return 6;
    }

    return 12;
  }

  async function fetchNews({ page, pageSize, index }) {
    try {
      const { data } = await api.get('contents', {
        params: {
          getCover: true,
          pagesize: pageSize,
          page,
          index_id: index,
          featured: false,
        },
      });

      if (
        !(data.rows.length >= pageSize) ||
        !(Number(data.count) > (width <= 1500 ? 10 : 12))
      ) {
        setIsDisabledBtnMoreNews(true);
      }

      return setAssociatedNews([...associatedNews, ...data.rows]);
    } catch (error) {
      // eslint-disable-next-line no-console
      return console.log(error);
    }
  }

  function handleMoreNews() {
    setIsLoadingAssociatedNews(true);
    fetchNews({
      index: content.index.id,
      page: associatedNewsPage + 1,
      pageSize: width <= 1500 ? 10 : 12,
    });

    setAssociatedNewsPage(associatedNewsPage + 1);
  }

  useEffect(() => {
    if (!(associatedNews.length > 0)) {
      return;
    }
    setIsLoadingAssociatedNews(false);
  }, [associatedNews]); //eslint-disable-line

  useEffect(() => {
    async function loadContent() {
      try {
        const request = await api.get(`/contents/${url}`);
        const { content_files, ...contentData } = request.data;

        setContent(contentData);

        if (content_files.length > 0) {
          const fileInText = contentData.text.includes(
            content_files[0] ? content_files[0].file : null
          );

          if (!fileInText) {
            if (content_files[0]) {
              if (content_files[0].cover) {
                setCover(content_files[0].file);
              }
            }
          }
        }

        const news = await api.get(`/contents`, {
          params: {
            index_id: contentData.index.id,
            ignore_id: contentData.id,
            getCover: true,
            pagesize: handleAssociatedNewsSlots(),
          },
        });

        setAssociatedNews(news.data.rows);
      } catch (error) {
        toast.error('Conteúdo não encontrado, tente novamente!');
        history.push('/');
      } finally {
        setLoadingContent(false);
      }
    }

    loadContent();
    window.scrollTo(0, 0);
  }, [history, url]); //eslint-disable-line

  return (
    <>
      <Container>
        <Header type="internal" />

        {loadingContent ? (
          <SkeletonContainer>
            {isMobile ? (
              <>
                <Skeleton width={220} height={14} className="category-title" />

                <div className="new-title-wrapper">
                  <Skeleton height={26} count={3} className="new-title" />
                  <Skeleton height={14} count={3} className="new-description" />
                </div>

                <Skeleton height={215} className="new-cover" />

                <Skeleton height={14} count={10} className="new-content" />
              </>
            ) : (
              <>
                <Skeleton width={220} height={20} className="category-title" />

                <div className="new-title-wrapper">
                  <Skeleton height={40} count={2} className="new-title" />
                  <Skeleton height={16} count={2} className="new-description" />
                </div>

                <Skeleton height={670} className="new-cover" />

                <div className="new-content-wrapper">
                  <Skeleton height={16} count={8} className="new-content" />
                </div>

                <div className="new-content-wrapper">
                  <Skeleton height={16} count={16} className="new-content" />
                </div>
              </>
            )}
          </SkeletonContainer>
        ) : (
          <Content>
            <ContentHeader>
              <CategoryTitle
                isFeatured
                title={content?.index?.title}
                date={content.date}
              />

              <h1>{content.title}</h1>

              <h2> {content.resume}</h2>

              <Share isVertical={!isMobile} />
            </ContentHeader>

            {cover && (
              <Cover src={`${urls.imageURL}${cover}`} alt={content.title} />
            )}
            <ContentBody>{ReactHtmlParser(content.text)}</ContentBody>
          </Content>
        )}

        <ContentFooter>
          <SectionTitle title="Notícias relacionadas" />

          <AssociatedNews>
            {associatedNews &&
              associatedNews.map(associated => (
                <AssociatedNew
                  key={associated.id}
                  url={associated.url}
                  date={associated.date}
                  isVertical={!isMobile}
                  title={associated.title}
                  banner={`${urls.imageURL}${associated.cover?.file}`}
                  onClick={() => window.location.replace(associated.url)}
                />
              ))}
          </AssociatedNews>

          <ButtonGradient
            title="Carregar mais"
            onClick={handleMoreNews}
            isLoading={isLoadingAssociatedNews}
            isMobile={isMobile}
            isDisabled={isDisabledBtnMoreNews}
          />
        </ContentFooter>
      </Container>

      <BlackContainer>
        <Footer />
      </BlackContainer>
    </>
  );
}

News.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1100,
  width,
});

export default withSizes(mapSizesToProps)(News);
