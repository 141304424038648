import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { colors, metrics, fonts } from '@abqm/theme';

export const Container = styled.a`
  border: none;
  height: ${pxToRem(47)};
  border-radius: ${pxToRem(50)};
  padding: 0 ${metrics.baseSpacing(4)};

  display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  ${fonts.size5};
  color: ${colors.darkWhite};
  font-family: SFProDisplay-Semibold, sans-serif;
  cursor: pointer;
  background-image: linear-gradient(
    to bottom,
    ${colors.green},
    ${colors.lightGreen} 111%
  );

  ${media.lessThan('large')`
    height: ${pxToRem(51)};
    padding: 0 ${metrics.baseSpacing(2)};

    display: ${({ isMobile }) => (isMobile ? 'flex' : 'none')};

    ${fonts.size4};
  `}

  ${media.lessThan('small')`
    height: ${pxToRem(41)};
  `}
`;
