import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { colors, fonts } from '@abqm/theme';
import { pxToRem } from '@abqm/functions';

export const Container = styled.a`
  border: none;
  min-width: ${pxToRem(90)};
  border-radius: ${pxToRem(16)};
  padding: ${pxToRem(3)} ${pxToRem(13)};

  display: flex;
  align-items: center;
  justify-content: center;

  ${fonts.size1};
  cursor: pointer;
  transition: box-shadow background-image 0.3s;
  color: ${colors.white};
  font-family: SFProDisplay-Regular, sans-serif;
  ${({ isBigger }) =>
    isBigger &&
    css`
      transform: scale(1.1);
    `}

  ${({ isPrimary }) =>
    isPrimary
      ? css`
          border: 1px solid ${colors.transparent};

          background-color: ${colors.blue};

          &:hover {
            background-color: ${lighten('0.1', colors.blue)};
          }
        `
      : css`
          opacity: 0.7;
          border: 1px solid ${colors.grey};

          background-color: ${colors.darkBlue};

          &:hover {
            background-color: ${lighten('0.05', colors.darkBlue)};
          }
        `}

  > svg {
    margin-right: 5px;
  }
`;
