import styled from 'styled-components';
import { metrics, colors, fonts } from '@abqm/theme';

export const Container = styled.main`
  //width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  max-width: 1920px;
`;
