import styled from 'styled-components';

import { colors } from '@abqm/theme';
import { pxToRem } from '@abqm/functions';

export const Container = styled.a`
  position: fixed;
  bottom: ${pxToRem(100)};
  right: ${pxToRem(100)};
  z-index: 2000;

  width: ${pxToRem(32)};
  height: ${pxToRem(32)};
  border-radius: ${pxToRem(4)};

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  background: ${colors.dark};
`;
