import styled from 'styled-components';
import { Link } from 'react-router-dom';

// import colors from '../../themes/colors';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px;
  margin: 0px auto;

  text-align: start;
  button {
    width: 180px;
    height: 50px;
    border: none;
    margin: 30px auto;
    border-radius: 30px;
    font-family: 'SFProDisplay-Semibold';

    display: flex;
    align-items: center;
    justify-content: center;

    // background-image: linear-gradient(to left,#FBBB2F,#91662A 33%);
    // linear-gradient(rgb(50, 154, 88), rgb(135, 197, 0) 111%);

    @media (max-width: 1024px) {
      width: 300px;
      height: 80px;
      font-size: 30px;
    }

    // display: none;
  }
  :hover button {
    box-shadow: rgba(51, 51, 51, 0.2) 0px 3px 5px;
  }

  @media (max-width: 1366px) {
    max-width: 1200px;
  }
`;

export const CardNews = styled.div`
  width: 100%;
  height: auto;
  // display: flex;
  // flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;

  span {
    font-weight: bold;
  }

  @media (max-width: 1024px) {
    // flex-direction: row;
    grid-template-columns: repeat(1, 1fr);
    overflow-x: auto !important;
    overflow-y: hidden;
    justify-content: flex-start;
  }
`;

export const CardContent = styled(Link)`
  // width: 22%;
  // max-width: 220px;
  height: auto;
  margin: 10px;
  border-radius: 6px;

  display: flex;
  flex: 1 0 21%;
  object-fit: cover;
  flex-direction: column;
  text-decoration: none;

  @media (max-width: 1024px) {
    // width: 380px;
    // max-width: 380px;
    height: auto;
  }

  img {
    width: 100%;
    height: 50%;
    border-radius: 4px;

    object-fit: cover;
    filter: grayscale(30%);

    @media (max-width: 1024px) {
      width: 350px;
      height: auto;
    }
  }
  :hover img {
    filter: grayscale(0%);
  }

  span {
    margin: 5px 0 5px 0;
    font-size: 12px;
    font-family: 'SFProDisplay-Regular';
    padding-left: 10px;
    padding-right: 10px;

    @media (max-width: 1024px) {
      font-size: 10px;
    }
  }

  p {
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;

    font-size: 16px;
    font-weight: 600;
    font-family: 'SFProDisplay-Regular';

    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }

  :hover {
    cursor: pointer;
    transition: all 0.2s ease 0s;
  }
`;
