import styled, { css } from 'styled-components';

import { GoSearch } from 'react-icons/go';

import { pxToRem } from '@abqm/functions';
import { metrics, fonts, colors } from '@abqm/theme';
import media from 'styled-media-query';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  padding: ${props => (props.mobile ? 0 : metrics.baseSpacing(10))};
  padding-top: ${metrics.baseSpacing(4)};
  padding-bottom: ${metrics.baseSpacing(8)};

  ${media.lessThan('huge')`
    padding-top: 0;
  `}

  ${props =>
    props.mobile &&
    css`
      padding-top: 0;
    `}
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @media (min-width: 1366px) {
    background: #fff;
  }
`;

export const MainTitle = styled.p`
  top: ${metrics.baseSpacing(3)};

  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  padding-left: ${props =>
    props.mobile ? metrics.baseSpacing(1) : metrics.baseSpacing(4)};
  margin-bottom: ${metrics.baseSpacing(2)};

  ${fonts.size9};
  color: ${colors.grey};
  line-height: ${pxToRem(60)};
  font-weight: 600;
  font-family: SFRounded-Semibold, sans-serif;

  ${props =>
    props.mobile &&
    css`
      top: ${metrics.baseSpacing(8)};
      padding: 0;
      padding-left: ${metrics.baseSpacing(1)};
      padding-top: 0;
      padding-bottom: ${metrics.baseSpacing(2)};
      margin: 0;

      ${fonts.size7};
      line-height: initial;
      background: ${colors.white};
      z-index: 9999;
    `}
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${pxToRem(30)};
  height: ${pxToRem(30)};

  cursor: pointer;
  margin-right: ${metrics.baseSpacing(9)};

  background: ${colors.blue};
  ${fonts.size3};
  color: ${colors.white};
  padding: ${metrics.baseSpacing(1)};
  border-radius: ${pxToRem(4)};
`;

export const SectionNews = styled.div`
  width: 70%;
  margin-left: 32%;
  margin-top: 3%;
`;

export const FilterSearch = styled.div`
  width: 100%;
  padding-left: 30px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SearchInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

> input {
  border-radius: 0.25rem;
  border: 1px solid rgb(167, 184, 196);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease 0s;
  background: rgb(255, 255, 255);
  height: 2.5rem;
  padding-left: 10px;
  font-family: SFRounded-Regular,sans-serif;
  font-size: 1rem;
  width: 240px;
  margin-left: 15px;
}

> select {
  border-radius: 0.25rem;
  border: 1px solid rgb(167, 184, 196);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease 0s;
  background: rgb(255, 255, 255);
  height: 2.5rem;
  padding-left: 10px;
  font-family: SFRounded-Regular,sans-serif;
  font-size: 1rem;
  width: 170px;
  margin-left: 15px;
}

> button {
    border: none;
    background: transparent;
    position: absolute;
    right: 3%;

    > svg {
      height: 20px;
    }
  }

  &:focus-within {
    transition: 0.3s;
    border-width: 2px;
  }
`;

export const Icon = styled(GoSearch).attrs({
  size: window.innerWidth <= 720 ? 20 : 32,
})`
  margin-top: 2px;
  color: ${colors.lightBlue};
`;
