export default {
    environment: 'production',
    urls: {
        playURL: 'https://play.abqm.com.br',
        portalURL: 'https://abqm.com.br',
        searchURL: 'https://busca.abqm.com.br',
        channelURL: 'https://portal.abqm.com.br',
        magazineURL: 'https://revista.abqm.com.br',
        observatoryURL: '/boletins-abqm/',
        apiURL: 'https://api.abqm.com.br:3333',
        cookieURL: '.abqm.com.br',
        imprensaUrl: '/sala-de-imprensa/',
        whatsAppShareURL: 'https://intranet.abqm.com.br/whatssapp_site/Noticias.aspx?Noticia=',
        hotsitesImageURL: 'https://adm.abqm.com.br/img/content_files',
    },
};
