import React, { useState, useEffect, useCallback } from 'react';

import MenuItems from '../MenuItems';
import HomeNavContent from '../HomeNavContent';

import { urls, api, indexes } from '@abqm/services';

//import LogoCampeonato from '../../../../themes/assets/LogoCampeonato.png';
// import LogoQuartoMilha from '../../../../themes/assets/ABQM_logo.png';
//LogoQuartoMilhaPreto.svg';

// import YoutubeHome from '../../../../themes/assets/youtube-dourado-rounded.png';
// import FacebookHome from '../../../../themes/assets/face-dourado-rounded.png';
// import InstagramHome from '../../../../themes/assets/insta-dourado-rounded.png';

// import LogoCampeonato from '@abqm/theme/assets/images/logos/logo-abqm.svg';
// import LogoQuartoMilha from '@abqm/theme/assets/images/logos/logo-abqm.svg';

// import YoutubeHome from '@abqm/theme/assets/images/icones/youtube-mobile.svg';
// import FacebookHome from '@abqm/theme/assets/images/logos/logo-abqm.svg';
// import InstagramHome from '@abqm/theme/assets/images/logos/logo-abqm.svg';

import * as S from './styles';

export default function Header({ hotsite, conteudos, conteudoNews }) {
  const [style, setStyle] = useState({});

  useEffect(() => {
    try {
      setStyle({ color: `${hotsite[0].cor_redessociais_icones}` });

    } catch (error) {
      console.log(error);
    } finally {
    }

    // const fetchData = async () => {

    // };
    // fetchData();
  }, [hotsite]);

  return (
    <S.Container>
      {hotsite.map(item => (
        <>
          <S.ContentTop>

            <img src={`${urls.hotsitesImageURL}/${item?.logo}`} alt="LogoQuartoMilha" />

            <aside>
              <a
                href={`https://${item?.link_youtube}`}
                target="blank"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={`${item.cor_redessociais_icones}`} viewBox="0 0 32 32"><path d="M13.83 19.259L19.262 16l-5.432-3.259z" /><path d="M16 0C7.164 0 0 7.163 0 16s7.164 16 16 16 16-7.164 16-16S24.836 0 16 0zm8.688 16.701c0 1.5-.174 3-.174 3s-.17 1.278-.69 1.839c-.661.738-1.401.742-1.741.786-2.432.186-6.083.192-6.083.192s-4.518-.044-5.908-.186c-.387-.077-1.254-.055-1.916-.792-.521-.562-.69-1.839-.69-1.839s-.174-1.499-.174-3v-1.406c0-1.5.174-2.999.174-2.999s.17-1.278.69-1.841c.661-.739 1.401-.743 1.741-.785 2.431-.188 6.079-.188 6.079-.188h.008s3.648 0 6.079.188c.339.042 1.08.046 1.741.784.521.563.69 1.841.69 1.841s.174 1.5.174 3v1.406z" /></svg>
              </a>

              <a href={`https://${item?.link_instagram}`} target="blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={`${item.cor_redessociais_icones}`} data-name="Instagram w/circle" viewBox="0 0 19.2 19.2"><path d="M13.498 6.651a1.656 1.656 0 0 0-.95-.949 2.766 2.766 0 0 0-.928-.172c-.527-.024-.685-.03-2.02-.03s-1.493.006-2.02.03a2.766 2.766 0 0 0-.929.172 1.656 1.656 0 0 0-.949.95 2.766 2.766 0 0 0-.172.928c-.024.527-.03.685-.03 2.02s.006 1.493.03 2.02a2.766 2.766 0 0 0 .172.929 1.656 1.656 0 0 0 .95.949 2.766 2.766 0 0 0 .928.172c.527.024.685.03 2.02.03s1.493-.006 2.02-.03a2.766 2.766 0 0 0 .929-.172 1.656 1.656 0 0 0 .949-.95 2.766 2.766 0 0 0 .172-.928c.024-.527.03-.685.03-2.02s-.006-1.493-.03-2.02a2.766 2.766 0 0 0-.172-.929zM9.6 12.168A2.568 2.568 0 1 1 12.168 9.6 2.568 2.568 0 0 1 9.6 12.168zm2.669-4.637a.6.6 0 1 1 .6-.6.6.6 0 0 1-.6.6z" /><circle cx="9.6" cy="9.6" r="1.667" /><path d="M9.6 0a9.6 9.6 0 1 0 9.6 9.6A9.6 9.6 0 0 0 9.6 0zm4.97 11.662a3.67 3.67 0 0 1-.233 1.213 2.556 2.556 0 0 1-1.462 1.462 3.67 3.67 0 0 1-1.213.233c-.534.024-.704.03-2.062.03s-1.528-.006-2.062-.03a3.67 3.67 0 0 1-1.213-.233 2.556 2.556 0 0 1-1.462-1.462 3.67 3.67 0 0 1-.233-1.213c-.024-.534-.03-.704-.03-2.062s.006-1.528.03-2.062a3.67 3.67 0 0 1 .233-1.213 2.556 2.556 0 0 1 1.462-1.462 3.67 3.67 0 0 1 1.213-.233c.534-.024.704-.03 2.062-.03s1.528.006 2.062.03a3.67 3.67 0 0 1 1.213.233 2.556 2.556 0 0 1 1.462 1.462 3.67 3.67 0 0 1 .233 1.213c.024.534.03.704.03 2.062s-.006 1.528-.03 2.062z" /></svg>
              </a>

              <a href={`https://${item?.link_facebook}`} target="blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={`${item.cor_redessociais_icones}`} class="bi bi-facebook" viewBox="0 0 16 16"> <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" /> </svg>
              </a>

              <p style={style}>@abqmoficial</p>
            </aside>

          </S.ContentTop>

          <S.ContentCenter>
            <aside>
              <MenuItems hotsite={hotsite} conteudoNews={conteudoNews} />
            </aside>

            <div>
              <img src={`${urls.hotsitesImageURL}/${item?.imagem_topo}`} alt="LogoCampeonato" />
            </div>
          </S.ContentCenter>

          <S.ContentFooter>
            <HomeNavContent hotsite={hotsite} conteudos={conteudos} />
          </S.ContentFooter>
        </>
      ))}
    </S.Container >
  );
}
