import styled from 'styled-components';

// import colors from '../../themes/colors';

export const Container = styled.section`
  width: 100%;
  min-height: auto;
  background-color: #edeef0;
  padding: 50px 0 50px 0;

  display: flex;
  flex-direction: row;
  justify-content: center;

  h1 {
    font-size: 24px;
    color: #000;
    font-family: 'SFProDisplay-Semibold';

    @media (max-width: 1024px) {
      font-size: 8vw;
      text-align: center;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  span {
    margin: 40px;
    height: 3px;
    width: 150px;
    border-radius: 4px;
    background-color: #02315a;

    @media (max-width: 1024px) {
      height: 6px;
    }
  }

  p {
    width: 90%;
    font-size: 1.8vw;
    text-align: center;
    color: #02315a;

    @media (max-width: 1024px) {
      font-size: 4vw;
    }
  }
`;

export const Achievement = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 10px 0 10px;
  text-align: center;
  width: 40%;

  h1 {
    width: 40%;
    align-self: flex-end;
  }

  hr {
    align-self: flex-end;
    border-color: #000;
    width: 40%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    text-align: left;

    h1 {
      margin-top: 100px;
      width: 100%;
    }

    hr {
      align-self: center;
      border-color: #000;
      width: 90%;
    }
  }
`;

export const AchievementImage = styled.div`
  align-self: flex-end;
  height: auto;
  margin-top: 35px;
  margin-bottom: 10px;
  width: 40%;

  img {
    // max-height: 80%;
    max-width: 80%;
  }

  @media (max-width: 1024px) {
    align-self: center;
    width: 100%;
    text-align: center;

    img {
      height: auto;
      // width: 400px;
      // max-width: 400px;
      margin-top: 80px;
    }
  }
`;

export const Sponsor = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 10px 0 10px;
  text-align: center;
  width: 60%;

  h1 {
    width: 60%;
  }

  hr {
    border-color: #000;
    margin-bottom: 30px;
    width: 60%;
  }

  @media (max-width: 1024px) {
    margin-top:100px;
    width: 100%;

    // padding: 2rem 7rem;

    gap: 2rem;
    grid-template-rows: repeat(11, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'first first'
      'first first'
      'second second'
      'second second'
      'third third'
      'third third'
      'four four'
      'four four'
      'sponsor sponsor'
      'sponsor sponsor'
      'sponsor sponsor'
      'sponsor sponsor';

      h1 {
        width: 100%;
        text-align: center;
        margin-top: 50px;
      }

      hr {
        border-color: #000;
        margin-bottom: 80px;
        width: 90%;
        align-self: center;
      }
  }

  a {
    padding: 1rem;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: 0.2s;
    backdrop-filter: blur(2.8px);
    -webkit-backdrop-filter: blur(2.8px);
    background-color: rgba(255, 255, 255, 0.2);

    :hover {
      transform: translateY(-5%);
    }

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
    }

    :first-child {
      grid-area: first;
    }

    :nth-child(2) {
      grid-area: second;
    }

    :nth-child(3) {
      grid-area: third;
    }

    :nth-child(4) {
      grid-area: four;
    }

    + a {
      /* background: blue; */
    }
  }
`;

export const SponsorImageContainer = styled.div`
  align-items: center;
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  height: auto;
  width: 65%;

  @media (max-width: 1024px) {
    //flex-direction: row;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    width: 100%;

    div {
      width: 90%;
    }
  }
`;

export const SponsorImageGold = styled.div`
  height: auto;
  margin: 0 15px 30px 0;
  //width: 135px;

  a {
    border: 0px;
    padding: 0px;
    display: block;
  }

  // img {
  //   max-height: 50%;
  //   max-width: 50%;
  // }

  img {
    height: auto !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  @media (max-width: 1024px) {
    width: 100% !important;
    align-self: center;

    // img {
    //   max-height: auto;
    //   min-width: 100%;
    // }

    img {
      height: auto !important;
      width: 100% !important;
      max-width: 100% !important;
    }
  }
`;

export const SponsorImageSilver = styled.div`
  height: auto;
  margin: 0 15px 30px 0;
  //width: 135px;

  a {
    border: 0px;
    padding: 0px;
    display: block;
  }

  // img {
  //   max-height: 50%;
  //   max-width: 50%;
  // }

  img {
    height: auto !important;
    width: 80% !important;
    max-width: 100% !important;
  }

  @media (max-width: 1024px) {
    width: 100% !important;
    align-self: center;

    // img {
    //   max-height: auto;
    //   min-width: 100%;
    // }

    img {
      height: auto !important;
      width: 80% !important;
      max-width: 100% !important;
    }
  }
`;

export const SponsorImageBronze = styled.div`
  height: auto;
  margin: 0 15px 30px 0;
  //width: 135px;

  a {
    border: 0px;
    padding: 0px;
    display: block;
  }

  // img {
  //   max-height: 50%;
  //   max-width: 50%;
  // }

  img {
    height: auto !important;
    width: 60% !important;
    max-width: 100% !important;
  }

  @media (max-width: 1024px) {
    width: 100% !important;
    align-self: center;

    // img {
    //   max-height: auto;
    //   min-width: 100%;
    // }

    img {
      height: auto !important;
      width: 60% !important;
      max-width: 100% !important;
    }
  }
`;