import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { withSizes } from 'react-sizes';
import { useParams, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import {
    Header,
    Footer,
    SectionTitle,
    BlackContainer,
} from '@abqm/components';
import { pxToRem } from '@abqm/functions';
import { urls, api, indexes } from '@abqm/services';

import {
    Container,
    ContentFooter,
    AssociatedNew,
    AssociatedNews,
    ButtonGradient,
    SkeletonContainer
} from './styles';

function NewsCategories({ isMobile, width }) {
    const { url } = useParams();
    const history = useHistory();

    const [associatedNews, setAssociatedNews] = useState([]);
    const [associatedNewsPage, setAssociatedNewsPage] = useState(1);
    const [isLoadingAssociatedNews, setIsLoadingAssociatedNews] = useState(false);
    const [isDisabledBtnMoreNews, setIsDisabledBtnMoreNews] = useState(false);
    const [loading, setLoading] = useState(false)

    if (!url) {
        toast.error('Parâmetro não informado')
        history.push('/')
    }

    const indexUrl = {
        institucionais: indexes.FEATURED_NEWS,
        ['universo-qm']: indexes.FEATURED_NEWS,
        esportes: indexes.SPORTS_NEWS,
        ['leiloes-e-mercado']: indexes.AUCTIONS
    }

    const indexName = {
        institucionais: 'Institucionais',
        ['universo-qm']: 'Universo QM',
        esportes: 'Esportes',
        ['leiloes-e-mercado']: 'Leilões e Mercado'
    }

    function handleAssociatedNewsSlots() {
        const windowWidth = window.innerWidth;

        if (windowWidth <= 1440) {
            return 10;
        }

        if (windowWidth <= 1100) {
            return 6;
        }

        return 12;
    }

    async function fetchNews({ page, pageSize, index }) {
        try {
            const { data } = await api.get('contents', {
                params: {
                    getCover: true,
                    pagesize: pageSize,
                    page,
                    index_id: index,
                    featured: false,
                },
            });

            if (
                !(data.rows.length >= pageSize) ||
                !(Number(data.count) > (width <= 1500 ? 10 : 12))
            ) {
                setIsDisabledBtnMoreNews(true);
            }

            setAssociatedNews([...associatedNews, ...data.rows]);
            return data
        } catch (error) {
            return console.log(error);
        }
    }

    function handleMoreNews() {
        setIsLoadingAssociatedNews(true);
        fetchNews({
            index: indexUrl[url] || indexes.FEATURED_NEWS,
            page: associatedNewsPage + 1,
            pageSize: width <= 1500 ? 10 : 12,
        });

        setAssociatedNewsPage(associatedNewsPage + 1);
    }

    useEffect(() => {
        if (!(associatedNews.length > 0)) {
            return;
        }
        setIsLoadingAssociatedNews(false);
    }, [associatedNews]);

    const loadContent = useCallback(async () => {
        setLoading(true)
        try {
            const resp = await fetchNews({
                index: indexUrl[url] || indexes.FEATURED_NEWS,
                page: 1,
                pageSize: width <= 1500 ? 10 : 12,
            });
            const request = await api.get(`/contents/${resp?.data[0]?.index?.url}`);
            const { content_files, ...contentData } = request.data;

            const news = await api.get(`/contents`, {
                params: {
                    index_id: contentData.index.id,
                    ignore_id: contentData.id,
                    getCover: true,
                    pagesize: handleAssociatedNewsSlots(),
                },
            });
            setAssociatedNews(news.data.rows);
        } catch (error) {
            console.log('%cindex.js line:143 error', 'color: #007acc;', error);
        }
        setLoading(false)
    }, [associatedNewsPage])

    function LoadingComponent() {
        return (
            <SkeletonContainer>
                <>
                    {[1, 2, 3, 4, 5, 6].map(item => (
                        <div className="new-title-wrapper">
                            <Skeleton width={pxToRem(220)} height={pxToRem(130)} className="category-title" />
                            <Skeleton height={16} count={4} className="new-content" />
                        </div>
                    ))}
                </>
            </SkeletonContainer>
        )
    }

    useEffect(() => {
        loadContent();
        window.scrollTo(0, 0);
    }, []); //eslint-disable-line

    return (
        <>
            <Container>
                <Header type="internal" />
                <ContentFooter>
                    <SectionTitle title={indexName[url] || "Notícias relacionadas"} />

                    <AssociatedNews>
                        {loading && <LoadingComponent />}
                        {!loading && associatedNews.length < 0 &&
                            <SectionTitle title={'Erro ao carregar notícias'} />
                        }
                        {!loading && associatedNews.length > 0 &&
                            associatedNews.map(associated => (
                                <AssociatedNew
                                    key={associated.id}
                                    url={associated.url}
                                    date={associated.date}
                                    isVertical={!isMobile}
                                    title={associated.title}
                                    banner={`${urls.imageURL}${associated.cover?.file}`}
                                    onClick={() => window.location.replace(`/noticias/${associated.url}`)}
                                />
                            ))
                        }
                    </AssociatedNews>

                    <ButtonGradient
                        title="Carregar mais"
                        onClick={handleMoreNews}
                        isLoading={isLoadingAssociatedNews}
                        isMobile={isMobile}
                        isDisabled={isDisabledBtnMoreNews}
                    />
                </ContentFooter>
            </Container>

            <BlackContainer>
                <Footer />
            </BlackContainer>
        </>
    );
}

NewsCategories.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
};

const mapSizesToProps = ({ width }) => ({
    isMobile: width <= 1100,
    width,
});

export default withSizes(mapSizesToProps)(NewsCategories);
