import React from 'react';
import PropTypes from 'prop-types';
import { MdPhone } from 'react-icons/md';
import { MdComment } from 'react-icons/md';
import { FaUserAlt, FaChevronDown } from 'react-icons/fa';
import { Ball } from 'react-loading-io';

import { colors } from '@abqm/theme';

import { Container } from './styles';

export default function Button({
  title,
  icon,
  href,
  onClick,
  isLoading,
  isDisabled,
  rightIcon,
  ...rest
}) {
  function renderIcon() {
    const props = {
      size: 12,
      color: colors.white,
    };

    return {
      phone: <MdPhone {...props} />,
      contato: <MdComment {...props} />,
      user: <FaUserAlt {...props} />,
      'chevron-down': <FaChevronDown {...props} />,
    };
  }

  function renderEnabledButton() {
    return (
      <Container {...rest} href={href} onClick={onClick} isLoading={isLoading}>
        {!isLoading && icon ? renderIcon()[icon] : ''}
        {isLoading ? `Carregando` : title}
        {isLoading && (
          <>
            <Ball size={9} color={colors.white} speed={1} />
            <Ball size={9} color={colors.white} speed={1.4} />
            <Ball size={9} color={colors.white} speed={1.6} />
          </>
        )}
        {!isLoading && rightIcon ? (
          <div style={{ marginLeft: 4 }}>{renderIcon()[rightIcon]}</div>
        ) : (
          ''
        )}
      </Container>
    );
  }

  function renderDisabledButton() {
    return <Container {...rest}>{title}</Container>;
  }

  return isDisabled ? renderDisabledButton() : renderEnabledButton();
}

Button.defaultProps = {
  icon: '',
  href: '#!',
  isPrimary: false,
  isBigger: false,
  isLoading: false,
  isDisabled: false,
  onClick: () => { },
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  href: PropTypes.string,
  isPrimary: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isBigger: PropTypes.bool,
  onClick: PropTypes.func,
};
