import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 1rem;

  /* @media (max-width: 1024px) {
      overflow-x: auto;
      overflow-y: hidden;

      height: auto;
  } */
`;

export const ItemsMenu = styled.ul`
  width: 100%;
  padding: 0 1rem;
  transform: skew(-15deg);

  display: flex;
  flex-wrap: wrap; // retirar caso queira overflow.
  align-items: center;
  justify-content: center;

  /* @media (max-width: 1024px) {
    align-items: flex-start;
    justify-content: flex-start;
  } */

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @media (max-width: 1024px) {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 1024px) {
    transform: skew(0deg);
  }

  li {
    width: auto;
    padding: 10px;
    border-bottom: 2px solid transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    list-style: none;
    transition: 0.3s;

    @media (max-width: 1024px) {
      width: 100%;
      transform: skew(-15deg);
    }

    /* @media (max-width: 1024px) {
      + li {
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) and (stroke-color: transparent) {
            margin-left: 40vw;
          }
        }
      }
    } */

    /* @media (max-width: 1024px) {
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) and (stroke-color: transparent) {
          :first-child {
            margin-left: 20vw;
          }
        }
      }
    } */

    div {
      padding: 10px;
      min-width: 237px;
      max-width: 250px;
      min-height: 68px;
      max-height: 68px;
      border-radius: 10px;
      box-shadow: 0px 3px 13px #00000041;
      // border: solid 2px rgba(251, 187, 47, 0.27);

      display: flex;
      align-items: center;
      justify-content: center;

      //background-color: #91662A;

      @media (max-width: 1024px) {
        min-width: 100%;
        min-height: 60px;

        font-size: 43px;
        border-radius: 5px;
      }
      a {
        color: #fff;
        font-size: 1.3rem;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-family: 'Bebas Neue';
        transform: skew(15deg);
        font-family: 'SFProDisplay-Semibold';

        @media (max-width: 1024px) {
          font-size: 1rem;
          // transform: skew(0deg);
        }
      }
    }
  }
`;
