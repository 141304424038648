import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { metrics, colors, fonts } from '@abqm/theme';
import {
  SimpleNew,
  Share as ShareC,
  ButtonGradient as ButtonGradientLib,
} from '@abqm/components';

export const Container = styled.div`
  width: 100%;
  max-width: ${pxToRem(1700)};
  padding: ${metrics.baseSpacing(2)} 0;
  margin: 0 auto;

  ${media.lessThan('huge')`
    max-width: ${metrics.maxWidth};
  `}

  ${media.lessThan('large')`
    padding: 0 ${metrics.baseSpacing(2)};
  `}

  ${media.between('medium', 'large')`
    padding: 0 ${metrics.baseSpacing(1)};
  `}

  ${media.lessThan('medium')`
    padding: ${metrics.baseSpacing(1)} 0;
  `}
  
`;

export const Cover = styled.img`
  width: 88%;
  margin: ${metrics.baseSpacing(4)} auto;
  border-radius: ${metrics.borderRadius(0.5)};

  display: block;
  object-fit: cover;
`;

export const Content = styled.div`
  width: 100%;
  max-width: ${pxToRem(1700)};
  padding-top: ${metrics.baseSpacing(2)};
  margin: ${metrics.baseSpacing(3)} auto;

  ${media.lessThan('huge')`
    max-width: ${metrics.maxWidth};
  `}

  ${media.lessThan('large')`
    padding: 0 ${metrics.baseSpacing(1)};
  `}

  ${media.between('medium', 'large')`
    padding: 0 ${metrics.baseSpacing(1)};
  `}

  ${media.lessThan('medium')`
    padding: 0 ${metrics.baseSpacing(1)};
    margin-top: 0;
  `}
`;

export const ContentHeader = styled.div`
  padding: 0 ${metrics.baseSpacing(40)};

  ${media.lessThan('huge')`
    padding: 0 ${metrics.baseSpacing(30)};
  `}

  ${media.lessThan('large')`
    padding: 0 ${metrics.baseSpacing(4)};
  `}

  ${media.between('medium', 'large')`
    padding: 0 ${metrics.baseSpacing(1)};
  `}

  ${media.lessThan('medium')`
    padding: 0 ${metrics.baseSpacing(1)};
  `}

  h1{
    margin-top: ${metrics.baseSpacing(1)};
    
    color: #333;
    ${fonts.size8}
    line-height: ${metrics.baseSpacing(5)};
    font-family: SFProDisplay-Regular, sans-serif;
    
    ${media.lessThan('huge')`
       margin-top: ${metrics.baseSpacing(1)};

       line-height: 1.3;
    `}

    ${media.lessThan('large')`
      padding: 0 ${metrics.baseSpacing(4)};
    `}

    ${media.between('medium', 'large')`
      padding: 0 ${metrics.baseSpacing(1)};
    `}

    ${media.lessThan('medium')`
      padding: 0 ${metrics.baseSpacing(1)};

      ${fonts.size6};
    `}
  }

  h2{
    font-family: Georgia;
    margin: ${metrics.baseSpacing(4)} 0;
    
    ${fonts.size5};
    font-weight: 300;
    font-style: italic;
    color: ${colors.grey};

    ${media.lessThan('huge')`
      margin-top: ${metrics.baseSpacing(2)};
    `}

    ${media.lessThan('large')`
      padding: 0 ${metrics.baseSpacing(4)};
      `}
      
      ${media.between('medium', 'large')`
      margin: ${metrics.baseSpacing(2)} 0;
      padding: 0 ${metrics.baseSpacing(1)};
      /* margin-bottom: ${metrics.baseSpacing(2)}; */
    `}

    ${media.lessThan('medium')`
      margin: ${metrics.baseSpacing(2)} 0;
      padding: 0 ${metrics.baseSpacing(1)};

      ${fonts.size3};
    `}

    ${media.lessThan('small')`
      margin-top: ${pxToRem(8)};
    `}
  }
`;

export const Share = styled(ShareC)`
  left: 0;
  z-index: 1;
  top: ${pxToRem(256)};

  ${media.lessThan('large')`
    top: 0;
    margin: 0 ${metrics.baseSpacing(1)};

    a {
      margin: 0 ${metrics.baseSpacing(3)} 0 0;
    }
    
    > div {
        img {
        margin: 0 ${metrics.baseSpacing(3)} 0 0;
      }
    }
  `}

  ${media.lessThan('medium')`
    a{
      margin-left: 0 !important;
    }
  `}
`;

export const ContentBody = styled.div`
  padding: 0 ${metrics.baseSpacing(40)};

  ${media.lessThan('huge')`
    padding: 0 ${metrics.baseSpacing(30)};
  `}

  ${media.lessThan('large')`
    padding: 0 ${metrics.baseSpacing(20)};
  `}

  ${media.between('medium', 'large')`
    padding: 0 ${metrics.baseSpacing(2)};
  `}

  ${media.lessThan('medium')`
    padding: 0 ${metrics.baseSpacing(1)};
  `}

  p {
    font-family: SFProDisplay-Regular;
    ${fonts.size5};
    text-align: justify;
    color: ${colors.grey};
    line-height: ${pxToRem(33)};

    ${media.lessThan('medium')`
      ${fonts.size3};
      line-height: ${pxToRem(27)};
    `}

    > img{
      max-width: 100%;

      ${media.lessThan('medium')`
        margin: ${pxToRem(24)} 0;
      `}
    }

    em {
      sup {
        margin: ${metrics.baseSpacing(-8)} 0 ${metrics.baseSpacing(2)};

        display: block;

        ${media.lessThan('small')`
        margin: ${metrics.baseSpacing(-6)} 0 ${metrics.baseSpacing(2)};
        
        line-height: 1.4;
        `}
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    margin: ${metrics.baseSpacing(4)} auto;
    border-radius: ${metrics.borderRadius(0.5)};
  
    display: block;
  }
`;

export const ContentFooter = styled.div`
  margin: ${metrics.baseSpacing(7)} ${metrics.baseSpacing(20)};

  ${media.lessThan('huge')`
    margin: ${metrics.baseSpacing(5)} ${metrics.baseSpacing(15)};
  `}

  ${media.lessThan('large')`
    margin: ${metrics.baseSpacing(5)} ${metrics.baseSpacing(7)};
  `}

  ${media.between('medium', 'large')`
    margin: ${metrics.baseSpacing(5)} ${metrics.baseSpacing(2)};
  `}

  ${media.lessThan('medium')`
    margin: ${metrics.baseSpacing(5)} ${metrics.baseSpacing(2)};
  `}

`;

export const AssociatedNews = styled.div`
  display: grid;
  grid-gap: ${pxToRem(8)};
  grid-template-columns: repeat(6, 1fr);

  ${media.lessThan('huge')`
    grid-template-columns: repeat(5, 1fr);
  `};

  ${media.lessThan('large')`
    grid-template-columns: repeat(1, 1fr);
  `};

  a {
    margin-top: ${metrics.baseSpacing(2)};
  }

  a + a {
    ${media.lessThan('medium')`
      margin-top: ${metrics.baseSpacing(4)};
    `}
  }
`;

export const AssociatedNew = styled(SimpleNew)``;

export const BlackContainer = styled.div`
  background-image: linear-gradient(to bottom, #200e0e, #151515);

  > section,
  > footer {
    margin: 0 auto;
    padding: ${pxToRem(120)} ${metrics.baseSpacing(10)};

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    text-align: center;
    color: ${colors.white};

    ${media.lessThan('large')`
    display: flex;
    flex-direction: column;

    text-align: left;
  `}

    ${media.lessThan('medium')`
    padding: ${pxToRem(60)} ${metrics.baseSpacing(2)};
  `}
  }
`;

export const ButtonGradient = styled(ButtonGradientLib)`
  margin: auto;

  width: ${pxToRem(198)};

  margin-top: ${pxToRem(32)};
`;

export const SkeletonContainer = styled.div`
  /* Mobile first */
  ${media.greaterThan('large')`
    display: flex;
  `}
  padding: 0 ${pxToRem(24)};

  .new-title-wrapper {
    margin-top: ${pxToRem(12)};
    margin-right: ${pxToRem(20)};

    .new-title + .new-title {
      margin-top: ${pxToRem(4)};
    }

    .new-description {
      margin-top: ${pxToRem(4)};

      &:first-child {
        margin-top: ${pxToRem(12)};
      }
    }
  }

  .new-cover {
    margin: ${pxToRem(24)} 0;
  }

  .new-content + .new-content {
    margin-top: ${pxToRem(6)};
  }

  ${media.greaterThan('large')`
    max-width: 1060px;
    margin: ${pxToRem(40)} auto;

    .new-title-wrapper {
      .new-title + .new-title {
        margin-top: ${pxToRem(10)};
      }

      .new-description {
        margin-top: ${pxToRem(6)};

        &:first-child {
          margin-top: ${pxToRem(38)};
        }

        &:last-child {
          margin-bottom: ${pxToRem(34)};
        }
      }
    }

    .new-content-wrapper {
      margin-top: ${pxToRem(20)};

      & + .new-content-wrapper {
        margin-top: ${pxToRem(28)};
      }

      .new-content + .new-content {
        margin-top: ${pxToRem(8)};
      }
    }

  `}
`;
