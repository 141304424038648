import React from 'react';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { ScrollToHOC } from 'react-scroll-to';

import { colors } from '@abqm/theme';

import { Container } from './styles';

function AnchorTop({ scroll, y }) {
  return (
    <Container onClick={() => scroll({ x: 0, y: 500 })}>
      <MdKeyboardArrowUp size="22" color={colors.white} />
    </Container>
  );
}

export default ScrollToHOC(AnchorTop);
