import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { metrics, colors } from '@abqm/theme';

export default styled.div`
  position: relative;
  z-index: 5;

  padding: ${metrics.baseSpacing(13)} 0;
  background-image: linear-gradient(to bottom, #200e0e, #151515);

  > section,
  > footer {
    margin: 0 auto;
    padding: ${pxToRem(120)} ${metrics.baseSpacing(10)};

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    text-align: center;
    color: ${colors.white};

    ${media.lessThan('large')`
    display: flex;
    flex-direction: column;

    text-align: left;
  `}

    ${media.lessThan('medium')`
    padding: ${pxToRem(60)} ${metrics.baseSpacing(2)};
  `}
  }

  ${media.lessThan('large')`
    padding: 0;
  `}
`;
