/* eslint-disable react/prop-types */
import { urls } from '@abqm/services';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withSizes } from 'react-sizes';
// import { Link } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useCookies } from 'react-cookie';

import axios from 'axios';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import decode from 'jwt-decode';

import { ReactComponent as Checkmark } from '@abqm/theme/assets/images/icones/check.svg';
import { apiUrl, centralUrl } from '../../services/api';

import {
  Container,
  Left,
  Right,
  RightBackground,
  FormContainer,
  Logo,
  Socials,
  SocialItem,
  Form,
  Input,
  InputIcon,
  Checkbox,
  Actions,
  Forgot,
  Button,
  InfoContainer,
  RegisterInfoConatiner,
  LanguageButton,
} from './styles';

const api = axios.create({
  baseURL: 'https://intranet.abqm.com.br/',
});

const GOOGLE_CLIENT_ID =
  '88224900888-ranl1n8ttmgfaeu0r6qpk1ka4fjuhvt1.apps.googleusercontent.com';
const FACEBOOK_APP_ID = '1145794985496332';

// eslint-disable-next-line no-unused-vars
function Login({ isMobile, match }) {
  const {
    register,
    errors,
    handleSubmit,
    setError,
    clearError,
    getValues,
  } = useForm();

  const [, setCookie, removeCookie] = useCookies([]);

  const [signup, setSignup] = useState(false);
  const [remember, setRemember] = useState(false);
  const [passwordVisible, setPasswordVisibile] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpf, setCpf] = useState('');
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('pt-br');

  const COOKIE_URL = urls.cookieURL;

  const onSetCookies = useCallback(
    ({ name, token, expireDate }) => {
      setCookie(name, token, {
        path: '/',
        domain: COOKIE_URL,
        ...(expireDate ? { expires: expireDate } : {}),
      });
    },
    [setCookie]
  );

  const onRemoveCookies = useCallback(() => {
    removeCookie('ServicosOnline[tokenABQM]', {
      path: '',
      domain: COOKIE_URL,
    });
  }, [removeCookie]);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const returnUrl = params.get('returnUrl');

  async function redirectLogin(token) {
    onRemoveCookies();

    if (remember) {
      const date = new Date();
      const expireDate = new Date(date.setMonth(date.getMonth() + 1));
      onSetCookies({ name: 'ServicosOnline[tokenABQM]', token, expireDate });
    } else {
      onSetCookies({ name: 'ServicosOnline[tokenABQM]', token });
    }

    switch (match.params.destination) {
      case 'servicos':
        window.open(
          `https://intranet.abqm.com.br/Comercial/ServicosOnLine/UI/LoginWeb/Servicos.aspx?token=${token}&r=${remember}`,
          '_self'
        );
        break;
      case 'consulta':
        // Consulta de animais
        if (returnUrl != '' && returnUrl != null) {
          window.open(returnUrl, '_self');
        } else {
          window.open(`http://consulta.abqm.com.br/`, '_self');
        }

        break;
      case 'central':
        // Central
        window.open(`${centralUrl}/auth?token=${token}`, '_self');
        break;
      case 'seqm':
        // SEQM
        window.open(`https://seqm.com.br?token=${token}`, '_self');
        break;
      case 'pagamentos':
        // PAGAMENTOS
        window.open(
          `https://centraldoquartista.abqm.com.br/pagamentos?token=${token}`,
          '_self'
        );
        break;
      default:
        // Portal
        window.open(`${urls.portalURL}?token=${token}`, '_self');
    }

    return true;
  }

  async function onSubmit(data, _e) {
    try {
      setLoading(true);

      const response = await apiUrl.post(
        '/api/ServicosOnline/seguranca/login',
        {
          usuario: data.email,
          senha: data.password,
        }
      );

      if (response.data.acessoPermitido) {
        const { token } = response.data.mensagem;

        await redirectLogin(token);
      } else {
        onRemoveCookies();
        setError('email', 'invalid', '');
        setError('password', 'invalid', '');
        toast.error('Email ou senha inválidos, tente novamente!', {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: true,
        });
      }
    } catch (e) {
      onRemoveCookies();
      setError('email', 'invalid', '');
      setError('password', 'invalid', '');
      toast.error('Email ou senha inválidos, tente novamente!', {
        position: 'top-right',
        autoClose: 10000,
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  }

  async function signupSubmit(data, _e) {
    setLoading(true);
    const rawInput = data.cpf;
    const parsedCpf = rawInput.replace(/[^0-9]/g, '');

    if (parsedCpf.length === 11) {
      window.open(
        `https://intranet.abqm.com.br/Comercial/ServicosOnline/UI/CadastroPessoa/CadWebTodos.aspx?c=${parsedCpf}&tipo=`,
        '_self'
      );
    } else {
      setError('cpf', 'cpfInvalid', 'Insira um CPF válido');
    }

    setLoading(false);
  }

  async function updateSocialMedia(idpessoa, uiredesocial, tiporede) {
    const response = api.post(
      'ABQM.API/api/PesquisaPessoa/AtualizarUIDRedeSocial',
      {
        idpessoa,
        uiredesocial,
        tiporede,
      }
    );

    return response;
  }

  async function checkSocialLogin(useremail, uid, type) {
    try {
      const social = await api.get(
        `ABQM.API/api/PesquisaPessoa/PessoaSelectPorEmailSocial/?email=${useremail}`
      );

      if (!social.data.listDto) {
        toast.error(
          `O endereço de e-mail ${useremail} não foi encontrado em nosso sistema!`,
          {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: true,
          }
        );
      } else {
        const data = {
          uidrede: uid,
          secret: '@Abqm$2019$$', // Put on env later
        };

        await updateSocialMedia(social.data.listDto.id_pessoa, uid, type);
        const token = await api.post('ABQM.API/api/site/Geral/GeraTokenUID/', {
          ...data,
        });

        await redirectLogin(token.data.result.Data[0].token);
      }
    } catch (e) {
      toast.error(
        `Não foi possível realizar login via ${type === 1 ? 'Facebook' : 'Google'
        }, tente novamente!`,
        {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: true,
        }
      );
    }
  }

  async function facebookSignIn(response) {
    setLoading(true);
    if (response.email) {
      await checkSocialLogin(response.email, response.userId, 1);
    }
    setLoading(false);
  }

  async function googleSignIn(response) {
    setLoading(true);
    if (response.tokenObj) {
      const decodedToken = decode(response.tokenObj.id_token);
      await checkSocialLogin(decodedToken.email, decodedToken.sub, 2);
    }
    setLoading(false);
  }

  async function forgotPassword() {
    const formData = getValues();

    if (!formData.email) {
      setError('email', 'invalid', 'Digite seu email');
    } else {
      try {
        const response = await apiUrl.patch(
          'api/ServicosOnline/Seguranca/EsqueciSenha',
          {
            usuario: formData.email,
          }
        );

        if (!response.data.sucesso) {
          toast.error(response.data.mensagem, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: true,
          });
        }

        if (response.data.sucesso) {
          toast.success(response.data.mensagem, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        toast.error(`Não foi possível recuperar sua senha, tente novamente!`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: true,
        });
      }
    }
  }

  function renderFormContainer() {
    return (
      <FormContainer>
        <Logo
          src={require('@abqm/theme/assets/images/icones/logo-quartodemilha.svg')}
        />

        <Socials>
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            callback={facebookSignIn}
            fields="name,email,picture"
            scope="public_profile,email"
            render={renderProps => (
              <SocialItem
                onClick={renderProps.onClick}
                src={require('@abqm/theme/assets/images/icones/login-facebook.svg')}
              />
            )}
          />

          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            render={renderProps => (
              <SocialItem
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                src={require('@abqm/theme/assets/images/icones/login-google.svg')}
              />
            )}
            onSuccess={googleSignIn}
            onFailure={googleSignIn}
          />
        </Socials>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <p>ou utilize sua conta de e-mail</p>

          <div>
            <p>E-mail</p>
            <Input errors={errors.email} onClick={() => clearError()}>
              <input
                value={email}
                onChange={e => setEmail(e.value)}
                type="text"
                placeholder="Digite seu e-mail"
                name="email"
                ref={register({
                  required: 'Digite seu email',
                })}
              />
              {/* {errors.email && (
                <InputIcon>
                  <FiXCircle size={16} color="#f07d7d" />
                </InputIcon>
              )} */}
            </Input>
          </div>

          <div>
            <p>Senha</p>
            <Input errors={errors.password} onClick={() => clearError()}>
              <input
                value={password}
                onChange={e => setPassword(e.value)}
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Digite sua senha"
                name="password"
                ref={register({
                  required: 'Digite sua senha',
                })}
              />
              {/* {!errors.password ? ( */}
              <InputIcon onClick={() => setPasswordVisibile(!passwordVisible)}>
                {!passwordVisible ? (
                  <FiEye size={16} color="rgba(0, 0, 0, 0.25)" />
                ) : (
                  <FiEyeOff size={16} color="rgba(0, 0, 0, 0.25)" />
                )}
              </InputIcon>
              {/* ) : (
                <InputIcon>
                  <FiXCircle size={16} color="#f07d7d" />
                </InputIcon>
              )} */}
            </Input>
          </div>

          <Actions>
            <div>
              <Checkbox
                onClick={() => setRemember(!remember)}
                checked={remember}
              >
                <Checkmark className="checkmark" height={10} width={10} />
              </Checkbox>

              <p>Mantenha-me logado</p>
            </div>

            <Forgot onClick={() => forgotPassword()}>
              Esqueci minha senha
            </Forgot>
          </Actions>

          <Button disabled={loading} type="submit">
            Entrar
          </Button>
        </Form>
      </FormContainer>
    );
  }

  function renderLoginInfo() {
    return (
      <InfoContainer>
        <Logo
          info
          src={require('@abqm/theme/assets/images/icones/logo-quartodemilha.svg')}
        />
        <p className="dark">
          Conecte-se para ter acesso
          <br />
          a todos os recursos disponíveis
          <br />
          em seu cadastro!
        </p>
        <Button onClick={() => setSignup(false)} type="button">
          Login
        </Button>
      </InfoContainer>
    );
  }

  function renderSignupContainer() {
    return (
      <>
        <RightBackground
          src={require('@abqm/theme/assets/images/background-login.svg')}
        />
        <FormContainer>
          <p className="title">Olá, Quartista!</p>
          <Form onSubmit={handleSubmit(signupSubmit)}>
            <div>
              <p>Inicie o seu cadastro informando o seu CPF</p>
              <Input dark errors={errors.cpf} onClick={() => clearError()}>
                <InputMask
                  mask="999.999.999-99"
                  maskChar="_"
                  value={cpf}
                  onChange={e => setCpf(e.value)}
                  type="text"
                  placeholder="Digite seu CPF"
                  name="cpf"
                  inputRef={register({
                    required: 'Digite seu CPF',
                  })}
                />
              </Input>
            </div>

            <Button disabled={loading} type="submit">
              Cadastrar
            </Button>
          </Form>
          <RegisterInfoConatiner>
            <div className="flagButtons">
              <LanguageButton
                type="button"
                onClick={() => setLanguage('pt-br')}
                active={language === 'pt-br'}
              >
                <img
                  src={require('@abqm/theme/assets/images/flag-br.svg')}
                  alt="Brasil"
                />
              </LanguageButton>
              <LanguageButton
                type="button"
                onClick={() => setLanguage('en-us')}
                active={language === 'en-us'}
              >
                <img
                  src={require('@abqm/theme/assets/images/flag-us.svg')}
                  alt="Estados Unidos"
                />
              </LanguageButton>
              <LanguageButton
                type="button"
                onClick={() => setLanguage('es-us')}
                active={language === 'es-us'}
              >
                <img
                  src={require('@abqm/theme/assets/images/flag-es.svg')}
                  alt="Espanha"
                />
              </LanguageButton>
            </div>
            <hr />
            {language === 'pt-br' && (
              <div className="registerInfoText">
                <p>
                  Para dar andamento ao processo de cadastro de estrangeiro, é
                  necessário enviar os seguintes documentos:
                </p>
                <ul>
                  <li> - Foto do número do passaporte;</li>
                  <li> - Endereço brasileiro, se houver, ou do exterior;</li>
                  <li> - Número de telefone com DDI;</li>
                  <li> - E-mail.</li>
                </ul>
                <p>
                  Todas as informações devem ser encaminhadas para:{' '}
                  <span>cadastroestrangeiro@abqm.com.br</span>.
                </p>
                <p>
                  Após a análise do departamento responsável, a Associação
                  retornará o contato pelo e-mail de cadastro, no prazo de até
                  48 horas úteis.
                </p>
                <p>
                  Atenciosamente, <br />
                  <span>ABQM</span>
                </p>
              </div>
            )}
            {language === 'en-us' && (
              <div className="registerInfoText">
                <p>
                  To proceed with the alien registration process, it is
                  necessary to send the following documents:
                </p>
                <ul>
                  <li> - Photo of the passport number;</li>
                  <li> - Brazilian address, if any, or abroad;</li>
                  <li> - Phone number with international code;</li>
                  <li> - Email.</li>
                </ul>
                <p>
                  All information should be sent to:{' '}
                  <span>cadastroestrangeiro@abqm.com.br</span>.
                </p>
                <p>
                  After analysis by the responsible department, the Association
                  will return by registered email, within 48 working hours.
                </p>
                <p>
                  Yours sincerely, <br />
                  <span>ABQM</span>
                </p>
              </div>
            )}
            {language === 'es-us' && (
              <div className="registerInfoText">
                <p>
                  Para continuar con el proceso de registro de extranjero, es
                  necesario enviar los siguientes documentos:
                </p>
                <ul>
                  <li> - Foto del número de pasaporte;</li>
                  <li>
                    - Dirección brasileña, si lo hubiera, o en el extranjero;
                  </li>
                  <li> - Número de telefono con código internacional;</li>
                  <li> - Correo electrónico.</li>
                </ul>
                <p>
                  Toda la información debe enviarse a:{' '}
                  <span>cadastroestrangeiro@abqm.com.br</span>.
                </p>
                <p>
                  Después del análisis por parte del departamento responsable,
                  la Asociación regresará el contacto a través del correo
                  electrónico registrado, dentro de las 48 horas hábiles.
                </p>
                <p>
                  Graciosamente,
                  <br />
                  <span>ABQM</span>
                </p>
              </div>
            )}
          </RegisterInfoConatiner>
        </FormContainer>
      </>
    );
  }

  function renderSignupInfo() {
    return (
      <>
        <RightBackground
          full
          src={require('@abqm/theme/assets/images/background-login.svg')}
        />
        <InfoContainer>
          <p className="title">Olá, Mundo!</p>
          <p>
            Faça parte da maior Associação da
            <br />
            América Latina de Criadores do
            <br />
            Cavalo Quarto de Milha
          </p>
          <Button onClick={() => setSignup(true)} type="button">
            Cadastre-se
          </Button>
        </InfoContainer>
      </>
    );
  }

  return (
    <>
      <Container>
        <Left large={!signup}>
          {signup ? renderLoginInfo() : renderFormContainer()}
        </Left>

        <Right large={signup} renderRegister={signup}>
          {signup ? renderSignupContainer() : renderSignupInfo()}
        </Right>
      </Container>
    </>
  );
}

Login.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1100,
});

export default withSizes(mapSizesToProps)(Login);
