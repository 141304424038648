import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { colors, fonts, metrics } from '@abqm/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${media.lessThan('large')`
    margin-bottom: ${metrics.baseSpacing(2)};
  `}

  > .icon {
    height: ${pxToRem(40)};

    margin-bottom: ${metrics.baseSpacing(5)};

    ${media.lessThan('large')`
      display: none;
    `}
  }

  > .title {
    margin-bottom: ${metrics.baseSpacing(2)};

    ${fonts.size3};
    font-weight: 600;
    line-height: 2.06;
    text-align: center;
    color: ${colors.white};
    text-transform: uppercase;
    font-family: SFRounded-Semibold, sans-serif;

    ${media.lessThan('large')`
      /* display: none; */
      margin-bottom: 0;
    `}
  }

  > p,
  > a {
    width: 'auto';
    margin-bottom: ${metrics.baseSpacing(0.5)};

    ${fonts.size2};
    color: ${colors.greyWhite};
    font-family: SFRounded-Thin, sans-serif;
    text-align: ${({ title }) =>
      title === 'Transparência' ? 'left' : 'center'};
  }

  > p {
    font-weight: 300;

    ${media.lessThan('large')`
      font-family: SFRounded-Thin, sans-serif;
    `}
  }

  > .link {
    font-weight: 600;
    font-family: SFRounded-Semibold, sans-serif;
  }
`;
