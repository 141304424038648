import styled from 'styled-components';
import media from 'styled-media-query';
import { AiFillPlusCircle } from 'react-icons/ai';

import { metrics, fonts, colors } from '@abqm/theme';
import { pxToRem } from '@abqm/functions';

export const Container = styled.a`
  border: transparent;
  border-radius: ${pxToRem(30)};
  margin-bottom: ${metrics.baseSpacing(0.4)};

  display: ${({ isMobileLayout }) => (isMobileLayout ? 'none' : 'flex')};
  align-items: center;

  cursor: pointer;
  background: transparent;

  ${media.lessThan('large')`
    width: ${pxToRem(196)};
    border: 1px solid ${({ color }) => color};

    display: flex;
    justify-content: space-between;

    background: ${({ color }) => color};
  `}

  ${media.lessThan('medium')`
    width: ${pxToRem(170)};
  `}

  ${media.lessThan('small')`
    width: ${pxToRem(126)};
  `}

  > span {
    margin-left: ${metrics.baseSpacing()};

    ${fonts.size4};
    line-height: 1;
    font-weight: 600;
    color: ${({ color }) => color};
    font-family: SFRounded-Semibold, sans-serif;

    ${media.lessThan('large')`
      /* align-items: center; */
      padding-right: ${metrics.baseSpacing(2)};

      ${fonts.size3};
      color: ${colors.white};
    `}
  }
`;

export const Icon = styled(AiFillPlusCircle)`
  font-size: ${pxToRem(26)};

  ${media.lessThan('large')`
    font-size: ${pxToRem(48)};
  `}

  ${media.lessThan('small')`
    font-size: ${pxToRem(32)};
  `}
`;
