import React from 'react';
import InputMask from 'react-input-mask';
import { api } from '@abqm/services';
import './styles.css';

export default function FormControlled() {
  const [formValues, setFormValues] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)
    sendMail(data)
  }

  async function sendMail(formData) {
    const {
      name,
      cargo,
      veiculo,
      email,
      telefone,
      cidade,
      estado,
      interesses,
    } = formData
    try {
      await api.post('/sendmail', {
        mensagem: `
          <p>Nome: ${name}</p>
          <p>Cargo: ${cargo}</p>
          <p>Veiculo: ${veiculo}</p>
          <p>E-mail: ${email}</p>
          <p>Telefone: ${telefone}</p>
          <p>Cidade: ${cidade}</p>
          <p>Estado: ${estado}</p>
          <p>Assunto de interesse: ${interesses}</p>
        `
      }).then((response) => {
        setLoading(false)
        alert("Mensagem enviada com sucesso!!")
      })
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="form-sala-imprensa">
      <h2>Cadastre-se, Jornalista!</h2>
      <form onSubmit={handleSubmit}>
        <div className="w-100">
          <label for="name">Nome</label>
          <input required type="text" name="name" onChange={handleInputChange} value={formValues.name || ''} />
        </div>

        <div className="flex-rows">
          <div className="w-50">
            <label for="cargo">Cargo / Função</label>
            <input required type="text" name="cargo" onChange={handleInputChange} value={formValues.cargo || ''} />
          </div>

          <div className="w-50">
            <label for="veiculo">Veículo</label>
            <input required type="text" name="veiculo" onChange={handleInputChange} value={formValues.veiculo || ''} />
          </div>

          <div className="w-50">
            <label for="email">E-mail</label>
            <input required type="email" name="email" onChange={handleInputChange} value={formValues.email || ''} />
          </div>

          <div className="w-50">
            <label for="telefone">Telefone / Whatsapp</label>
            <InputMask mask="(99) 99999-9999">
              {(inputProps) => <input {...inputProps} type="tel" name="telefone" />}
              {/* <input type="text" name="telefone" onChange={handleInputChange} value={formValues.telefone || ''} /> */}
            </InputMask>
          </div>

          <div className="w-50">
            <label for="cidade">Cidade</label>
            <input required type="text" name="cidade" onChange={handleInputChange} value={formValues.cidade || ''} />
          </div>

          <div className="w-50">
            <label for="uf">UF</label>
            <select id="estado" name="estado" onChange={handleInputChange} value={formValues.estado || ''}>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
              <option value="EX">Estrangeiro</option>
            </select>
          </div>

          <div className="w-100">
            <label for="assunto">Assunto de interesse</label>
            <textarea required name="interesses" onChange={handleInputChange} value={formValues.interesses || ''}></textarea>
          </div>
        </div>

        <div className="btn-and-loading">
          <button type="submit" className="btn-enviar">Enviar</button>
          {loading
            ? <div className="loading-btn"><img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" /></div>
            : null}
        </div>
      </form>
    </div>
  );
};
