import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function ContactCard({ title, iconName, children }) {
  return (
    <Container title={title}>
      <img
        alt="Icone"
        className="icon"
        src={require(`@abqm/theme/assets/images/icones/${iconName}.svg`)}
      />

      {title && <h5 className="title">{title}</h5>}

      {children}
    </Container>
  );
}

ContactCard.defaultProps = {
  iconName: 'search',
  title: '',
};

ContactCard.propTypes = {
  iconName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  title: PropTypes.string,
};
