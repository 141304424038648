import styled from 'styled-components';
import media from 'styled-media-query';

import { RiPlayCircleLine } from 'react-icons/ri';

// import colors from '../../../../themes/colors';

export const Container = styled.section`
  width: 100%;
  // height: 90vh;
  padding: 0 1rem;

  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin-top: 0vh;
    height: auto;
  }

  h1 {
    font-size: 7vw;
    // color: #91662A;
    line-height: 6vw;
    font-family: 'SFProDisplay-Black';
    text-shadow: 0 10px 12px rgba(0, 113, 46, 0.2);

    @media (max-width: 1024px) {
      font-size: 17vw;
      line-height: 17vw;
    }
  }

  h5 {
    // color: #91662A; // #1a6138;
    font-size: 1.5vw;
    font-weight: 900;
    text-align: center;
    letter-spacing: 1.3rem;
    text-transform: uppercase;
    font-family: 'SFProDisplay-Thin';

    @media (max-width: 1024px) {
      font-size: 4vw;
    }
  }

  > div {
    width: 100%;
    margin-left: -1%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 1024px) {
      margin-top: 0px;

      flex-direction: column;
    }
  }
`;

export const Nav = styled.nav`
  @media (max-width: 1024px) {
    width: 100%;
    min-height: 100px;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  height: auto;
  margin-right: 30px;

  ul {
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    + ul {
      margin-top: 10px;
    }

    @media (max-width: 1024px) {
      width: 100%;
      min-width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }

    button {
      width: 100%;
      min-width: 100%;
      max-width: 400px;
      text-align: center;
      padding-right: 15px;
      border-radius: 6px;
      box-shadow: 0px 0px 30px #95afe229;
      font-family: 'SFProDisplay-Semibold';

      display: flex;
      align-items: center;
      justify-content: flex-start;

      border: none;
      list-style: none;
      // background-image: linear-gradient(to left, #FBBB2F, #91662A 33%);

      + button {
        margin-top: 10px;
      }
      @media (max-width: 1024px) {
        // padding: 3vw 0;
        padding-right: 0;
        border-radius: 12px;
        width: 50%;
        min-width: 50%;
        max-width: 50%;
        margin-top: 10px;

        + button {
          // margin-top: 10px;
          // margin-left: 10px;

          // :nth-child(2) {
          //   margin-top: 0;
          // }
          // :nth-child(3) {
          //   margin-left: 0;
          // }
        }
      }

      svg {
        width: 35px;
        height: 40px;
        color: #fff;
        margin: 10px;

        @media (max-width: 1024px) {
          width: 30px;
          height: 30px;
        }
      }

      a {
        color: #fff;
        font-size: 1.0vw;
        text-decoration: none;
        text-transform: uppercase;
        font-family: 'SFProDisplay-Semibold';

        @media (max-width: 1024px) {
          width: auto;
          font-size: 5vw;
          margin-left: -5%;
          padding: 20px 30px 18px 30px;
        }
      }

      // .IconButton {
      //   @media (max-width: 1024px) {
      //     margin-top: 0.4vh;
      //   }
      // }
    }
  }

  ${media.lessThan('huge')`
    max-width: 240px;
    margin-right: 22.5px;

    ul {
      margin-top: 10px;

      li {
        padding: 18px 15px;

        svg {
          margin-right: 12px;
        }

        a {
          font-size: 18px;
        }
      }
    }
  `}

  ${media.lessThan('large')`
    max-width: 100%;
    margin-right: 0;

    ul {
      margin-top: 8px;

      li {
        padding: 14px 12px;

        svg {
          margin-right: 10px;
        }

        a {
          font-size: 30px;
        }
      }
    }
  `}
`;

export const Icon = styled(RiPlayCircleLine)`
  width: 30px;
  height: auto;

  @media (max-width: 1024px) {
    width: 60px;
  }
`;

export const LiveVideo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  aspect-ratio: 16/9;

  > div {
    width: 50vw;
    height: auto;
    aspect-ratio: 16/9;
    margin: 10px 0px 10px 0px;
    border-radius: 6px;
    //border: solid 15px rgba(145, 102, 42, 0.3);

    background-color: transparent;

    /** SAFARI ONLY */
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        min-height: 44vh;

        iframe {
          min-height: 44vh;

          @media (max-width: 1024px) {
            min-height: auto;
          }
        }

        @media (max-width: 1024px) {
          min-height: auto;
        }
      }
    }
    /** SAFARI ONLY */

    @media (max-width: 1024px) {
      width: 93vw;
      height: 51vw;
      margin-top: 2rem;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const DescriptionVideo = styled.div`
  width: auto;
  height: auto;
  padding: 0 2rem;
  max-width: 250px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1024px) {
    min-width: 90%;
    padding: 0 0;
  }

  h2 {
    // color: #91662A;
    font-size: 2.8rem;
    font-weight: bold;
    white-space: nowrap;
    text-transform: uppercase;
    font-family: 'SFProDisplay-Semibold';

    @media (max-width: 1024px) {
      // color: #91662A;
      margin-top: 0px;
      font-size: 20px;
    }
  }

  p {
    width: 100%;
    // color: #91662A;
    font-weight: bold;
    margin-top: 10px;
    font-size: 1.33rem;
    font-family: 'SFProDisplay-Thin';

    @media (max-width: 1024px) {
      // color: #91662A;
      font-weight: 600;
      margin-top: 20px;
      font-size: 33px;
    }
  }
`;
