import styled from 'styled-components';

export const Container = styled.div`
  z-index: -2;
  position: fixed;
  margin-top: -80px;

  //width: 100vw;
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    //margin-top: -50px;

    @media (max-width: 1024px) {
      object-position: left;
    }
  }

  .desktop {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .mobile {
    @media (min-width: 1024px) {
      display: none;
    }
  }
`;
