import styled from 'styled-components';
import { slide as MenuSlide } from 'react-burger-menu';

// import colors from '../../themes/colors';

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1024px) {
    display: none;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 40px;
    height: 30px;
    left: 20px;
    top: 90px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    height: 5px !important;

    background: #000000;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    margin-right: 20px;
    width: 80px !important;
    height: 80px !important;
  }
  /* .bm-cross-button span{
  left: 0;
} */

  .bm-cross-button span.bm-cross {
    width: 3px !important;
    height: 30px !important;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background-color: #000000;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    top: 0;
    position: absolute;
    position: absolute;

    width: 80% !important;
    height: 100vh !important;
  }

  /* General sidebar styles */
  .bm-menu {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100vh !important;

    background: rgba(280, 255, 255, 0.5);
    backdrop-filter: blur(30px);
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    @media (max-width: 1024px) {
      display: none;
      background: rgba(0, 0, 0, 0.5);
    }
  }
`;

export const MenuMobile = styled(MenuSlide)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  img {
    width: 90%;
    height: auto;
    margin-left: 5%;
    margin-top: 30%;
  }
`;

export const SocialsHome = styled.div`
  width: 100%;
  height: auto;
  margin-top: 55%;

  align-items: center;
  justify-content: center;
  display: flex !important;

  a {
    text-decoration: none;
    background: transparent;
    img {
      margin: 10px;
      width: 8vw;
      height: auto;
    }
  }

  p {
    color: #1a6138;
    font-size: 8vw;
    font-family: 'Bebas Neue';
  }
`;
