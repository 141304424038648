import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';

export const Container = styled.div`
  position: relative;
  z-index: 1;

  height: 100vh;

  ${({ isChannels }) =>
    isChannels &&
    css`
      min-height: 100vh;
      height: auto;
      overflow-x: hidden;
      background-size: auto auto;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-image: url(${require(`@abqm/theme/assets/images/background-canais.png`)});
    `}

  .bm-menu-wrap {
    position: relative;
  }

  .bm-overlay {
    background: rgba(255, 255, 255, 1) !important;
  }

  .bm-burger-button {
    > img {
      position: fixed !important;

      width: ${pxToRem(25)} !important;
      height: ${pxToRem(25)} !important;

      z-index: 1010;
    }
  }

  ${media.lessThan('large')`
    height: auto;
    min-height: 100vh;

    ${({ isChannels }) =>
      isChannels &&
      css`
        background-size: 100% auto;
        background-position: center;
        background-attachment: fixed;
        background-image: url(${require(`@abqm/theme/assets/images/background-canais-ipad.png`)});
      `})
  `}

  ${media.lessThan('medium')`
    height: auto;
    min-height: 100vh;

    ${({ isChannels }) =>
      isChannels &&
      css`
        background-size: 100% auto;
        background-position: center;
        background-attachment: fixed;
        background-image: url(${require(`@abqm/theme/assets/images/background-canais-mobile.png`)});
      `})
  `}

  ${media.between('768px', '1024px')`
    height: auto;
    min-height: 100vh;
  `}
`;

export const CloseButton = styled.div`
  position: fixed;
  top: ${pxToRem(10)};
  right: ${pxToRem(10)};

  width: ${pxToRem(50)};
  height: ${pxToRem(50)};

  z-index: 1001;

  align-items: center;
  justify-content: center;
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
`;

export const Icon = styled.img`
  margin-left: ${pxToRem(10)};
  width: ${pxToRem(20)} !important;
  height: ${pxToRem(20)} !important;
`;

export const ChildrenWrap = styled.div`
  transition: 0.3s;

  ${media.lessThan('large')`
    padding-top: ${pxToRem(80)};
  `}

  ${({ searchVisible }) =>
    searchVisible &&
    css`
      ${media.lessThan('large')`
        padding-top: ${pxToRem(140)};
      `}
    `}
`;
