import styled from 'styled-components';

import { metrics, colors, fonts } from '@abqm/theme';

export const Container = styled.nav`
  margin: 0;
  //font-family: 'Bebas Neue';
  font-family: 'SFProDisplay-Regular'
`;

export const NavDesktop = styled.nav`
  position: relative;
  font-family: 'SFProDisplay-Regular'

  // height: 90px;
  padding: 0 80px 15px;
  max-width: 100%;
  border-bottom: 2px solid ${colors.lightGrey};

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  margin: 20px 30px 30px;

  @media (max-width: 1024px) {
    display: none;
  }

  img {
    width: 150px;
    height: auto;
  }

  .nav {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);

    @media (max-width: 1920px) {
      left: 28%;
    }

    @media (max-width: 1700px) {
      left: 31%;
    }

    @media (max-width: 1700px) {
      left: 36%;
    }

    @media (max-width: 1366px) {
      left: 41%;
    }

    > a {
      text-decoration: none;
      color: ${colors.black};

      & + a {
        margin-left: 20px;
      }
    }
  }

  .logoCampeonato {
    z-index: 1;
    img {
      // margin-top: 21%;
      width: auto;
      height: 100px;
    }
  }
`;
