import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './config/Reactotron';

import { Global } from '@abqm/theme';
import { history } from '@abqm/services';
import { MenuRoot } from '@abqm/components';
import TagManager from 'react-gtm-module';

import Routes from './routes';

ReactGA.initialize('UA-88283231-1', {
  debug: false,
  titleCase: false,
});
ReactGA.pageview(window.location.pathname + window.location.search);

export default function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-NG7V89LP' });
  }, []);
  return (
    <MenuRoot>
      <Router history={history}>
        <Global />
        <ToastContainer autoClose={3000} />

        <Routes />
      </Router>
    </MenuRoot>
  );
}
