import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withSizes } from 'react-sizes';
import { api, urls } from '@abqm/services';
import { colors } from '@abqm/theme';
import { Container, Carousel, Item, ProgressBar } from './styles';

const handleAdsClick = id =>
  api.get(`/advertisements/${id}`);
function Advertisement({
  isMobile,
  containerType,
  watermarkType,
  slots,
  setHasAds,
  ...rest
}) {
  const [progress, setProgress] = useState(0);
  const [advertisements, setAdvertisements] = useState([]);

  useEffect(() => {
    async function fetchAdvertisements() {
      try {
        const { data } = await api.get('advertisements', {
          params: {
            pagesize: 999,
            advertisement_place: 1,
          },
        });

        setAdvertisements(data);
      } catch (error) {
        console.log('error', error);
      }
    }

    fetchAdvertisements();
  }, []);

  const handleAdSlots = useCallback(() => {
    const advertisementInfo = advertisements.filter(ad => {
      return slots.includes(String(ad.position)) && ad.status;
    });

    setHasAds(advertisementInfo.length > 0);

    return advertisementInfo || [];
  }, [advertisements, slots, setHasAds]);

  const handleProgress = useCallback(
    item => {
      const itemID = Number(item.current?.childNodes[0].id);

      const totalPercent = 100 / (handleAdSlots().length - 1);

      return itemID * totalPercent;
    },
    [handleAdSlots]
  );

  const carouselConfig = {
    interval: 3000,
    onTransitionEnd: item => setProgress(handleProgress(item)),
  };

  const progressBarConfig = {
    strokeWidth: 0.7,
    strokeColor: colors.green,
    trailColor: colors.transparent,
  };

  if (handleAdSlots().length === 0) {
    return false;
  }

  return (
    <Container
      containerType={containerType}
      watermarkType={watermarkType}
      {...rest}
    >
      <Carousel
        {...carouselConfig}
        auto={handleAdSlots().length > 1}
        loop={handleAdSlots().length > 1}
      >
        {handleAdSlots().map(({ id, url, file }, index) => (
          <Item onClick={() => handleAdsClick(id)} key={id} href={url} index={index} id={index} target="_blank">
            <img src={`${urls.adsURL}${file}`} alt="Publicidade" />
          </Item>
        ))}
      </Carousel>

      <ProgressBar {...progressBarConfig} percent={progress} />
    </Container>
  );
}

Advertisement.defaultProps = {
  watermarkType: 'default',
  containerType: 'default',
  slots: [],
  advertisements: [],
  setHasAds: () => { },
};

Advertisement.propTypes = {
  advertisements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      banner: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  isMobile: PropTypes.bool.isRequired,
  watermarkType: PropTypes.string,
  containerType: PropTypes.string,
  slots: PropTypes.arrayOf(PropTypes.string),
  setHasAds: PropTypes.func,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1024,
});

export default withSizes(mapSizesToProps)(Advertisement);
