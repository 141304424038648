import styled from 'styled-components';

export const Container = styled.div`
  right: 4%;
  bottom: 5%;
  z-index: 9999;
  position: fixed;

  width: 6.5%;
  height: auto;

  @media (max-width: 1024px) {
    width: 20%;

    bottom: 2%;
  }

  a {
    text-decoration: none;

    img {
      width: 100%;
      height: 100%;

      object-fit: contai;
    }
  }
`;
