import React from 'react';
import PropTypes from 'prop-types';

import data from './data';
import dataObs from './dataObs';
import { Container } from './styles';

export default function ListItems({ emptyList, observatory }) {
  const itemList = observatory ? dataObs : data;

  return (
    <Container>
      {!emptyList &&
        itemList.map((item, index) => (
          <a key={index} className="menu-item" href={item.url}>
            {item.title}
          </a>
        ))}
    </Container>
  );
}

ListItems.defaultProps = {
  emptyList: false,
  observatory: false,
};

ListItems.propTypes = {
  emptyList: PropTypes.bool,
  observatory: PropTypes.bool,
};
