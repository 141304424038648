import { useCookies } from 'react-cookie';

// Prodution
const intranetLink = 'https://intranet.abqm.com.br/';
const servicosLink = 'https://servicosonline.abqm.com.br/';

// const [cookies] = useCookies([]);
// const token = cookies['ServicosOnline[tokenABQM]'];

// Homolog
// const intranetLink = 'https://intranet.abqm.com.br/';
// const servicosLink = 'http://homolog.abqm.com.br/abqm_servicosonline/';

export default {
  'atendimento-inteligente': {
    name: 'Atendimento Inteligente',
    link: `${intranetLink}Comercial/ServicosOnline/UI/AtendimentoInteligente/AtendInteligente.aspx?faq=1`,
    //link: `https://homolog.abqm.com.br/Comercial/UI/AtendimentoInteligente/AtendInteligente.aspx?faq=1`,
  },
  'consulta-de-sufixo': {
    name: 'Consulta de Sufixo',
    link: `${intranetLink}Comercial/servicosonline/UI/StudBook/sufixo.aspx`,
    // link: `http://homolog.abqm.com.br/ELTON/COMERCIAL/UI/StudBook/sufixo.aspx`,
  },
  'associe-se': {
    name: 'Associe-se',
    link: `${intranetLink}Comercial/ServicosOnline/UI/CadastroPessoa/associe-se.aspx`,
    // link: `http://homolog.abqm.com.br/ELTON/COMERCIAL/UI/CadastroPessoa/associe-se.aspx`,
  },
  'consulta-socios': {
    name: 'Consulta de Sócios para Núcleos',
    link: `${intranetLink}ServicosOnlineMVC/verificasocio/index`,
    // link: `https://homolog.abqm.com.br/ELTon/ServicosOnlineMVC/verificasocio`,
  },
  estatisticas: {
    name: 'Estatísticas',
    link: `${servicosLink}estatistica`,
  },
  'relacao-de-socios': {
    name: 'Relação de Sócios',
    link: `${servicosLink}socios`,
  },
  'consulta-de-inspetores': {
    name: 'Consulta de Inspetores',
    link: `${servicosLink}inspetores`,
  },
  'canal-de-etica': {
    name: 'Canal de Ética',
    link: `${servicosLink}canal-etica-e-ouvidoria`,
  },
  'consulta-de-animais': {
    name: 'Consulta de Animais',
    link: `${servicosLink}animais?token=`,
  },
  'consulta-nucleos': {
    name: 'Consulta Núcleo/Associação',
    link: `${intranetLink}ServicosOnlineMVC/Nucleo/consultavigencia`,
  },
};
