import React from 'react';

import WppFloating from '@abqm/theme/assets/images/icones/btn-whatsapp.png';

import { Container } from './styles';

function WhatsAppFloating() {
  return (
    <Container>
      <a
        href="https://api.whatsapp.com/send/?phone=551138797150"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={WppFloating} alt="Whats app flutuante" />
      </a>
    </Container>
  );
}

export default WhatsAppFloating;
