import styled from 'styled-components';

export const Container = styled.div`
  padding: 5rem 0;
  // margin-bottom: -10%;

  @media (max-width: 1024px) {
    margin-top: 100px;
    padding: 0 0;
  }
`;
