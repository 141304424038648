import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { urls } from '@abqm/services';
import { CategoryTitle } from '@abqm/components';

import SecondaryNew from './SecondaryNew';
import {
  Container,
  FeaturedNew,
  SecondaryNews,
  SkeletonContainer,
} from './styles';

export default function FeaturedNews({ data, loading, isMobile }) {
  const featuredNew = data.rows && data.rows[0];

  if (loading)
    return isMobile ? (
      <SkeletonContainer>
        <Skeleton />
        <Skeleton width={250} height={18} />
        <Skeleton height={24} count={3} className="featured-title" />
        <Skeleton height={14} count={5} />
      </SkeletonContainer>
    ) : (
      <SkeletonContainer>
        <Skeleton width={250} />

        <div className="featured-new-title">
          <Skeleton height={24} count={3} className="featured-title" />

          <div className="description">
            <Skeleton height={14} count={5} />
          </div>
        </div>

        <div className="secondary-news">
          <div>
            <Skeleton />
            <Skeleton height={18} count={4} className="secondary-title" />
          </div>

          <div>
            <Skeleton />
            <Skeleton height={18} count={4} className="secondary-title" />
          </div>
        </div>

        <div className="image">
          <Skeleton />
        </div>
      </SkeletonContainer>
    );

  return (
    <Container>
      <div className="left">
        <FeaturedNew>
          <CategoryTitle
            isFeatured
            title={featuredNew?.index.title}
            date={featuredNew?.date}
          />

          <h2>
            <Link to={`noticias/${featuredNew?.url}`}>
              {featuredNew?.title}
            </Link>
          </h2>

          <p>
            <Link to={`noticias/${featuredNew?.url}`}>
              {featuredNew?.resume}
            </Link>
          </p>
        </FeaturedNew>

        <SecondaryNews>
          {data.rows &&
            data.rows
              .filter((item, index) => index !== 0)
              .map(item => (
                <SecondaryNew
                  key={item.id}
                  {...item}
                  category={item.index.title}
                  link={`noticias/${item?.url}`}
                  cover={`${urls.imageURL}${item.cover.file}`}
                />
              ))}
        </SecondaryNews>
      </div>

      <Link className="right" to={`noticias/${featuredNew?.url}`}>
        <img
          alt="Banner da noticia em destaque"
          src={`${urls.imageURL}${featuredNew?.cover?.file}`}
        />
      </Link>
    </Container>
  );
}

FeaturedNews.defaultProps = {
  data: {
    rows: [],
  },
};

FeaturedNews.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape()),
  }),
  loading: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
};
