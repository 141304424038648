import React from 'react';
import { FiX } from 'react-icons/fi';

import logoDerby from '@abqm/theme/assets/images/modalEvent/logoDerby.png';
import logoNacional from '@abqm/theme/assets/images/modalEvent/logoNacional.png';
import logoFullWhite from '@abqm/theme/assets/images/modalEvent/logoFullWhite.svg';
import logoPotroFuturo from '@abqm/theme/assets/images/modalEvent/logoPotroFuturo.png';

import { Container, Header, Content, Footer } from './styles';

function Modal({ handleCloseModal }) {
  return (
    <Container>
      <Header>
        <button type="button">
          <FiX size={30} onClick={handleCloseModal} />
        </button>
      </Header>

      <Content>
        <img src={logoFullWhite} alt="" className="logoQuartodeMilha" />
        <h1>Provas Oficiais 2020</h1>

        <div>
          <div>
            <img src={logoNacional} alt="Logo Nacional" />
          </div>

          <div>
            <img src={logoPotroFuturo} alt="Logo Potro do futuro" />
          </div>

          <div>
            <img src={logoDerby} alt="Logo Derby" />
          </div>
        </div>
      </Content>

      <Footer>
        <a
          href="https://abqm.com.br/provasoficiais/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ACESSE AQUI O HOTSITE
        </a>
      </Footer>
    </Container>
  );
}

export default Modal;
