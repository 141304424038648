import React from 'react';
import withSizes from 'react-sizes';
import PropTypes from 'prop-types';

import { colors } from '@abqm/theme';
import { urls } from '@abqm/services';

import { Container, Icon } from './styles';

function MoreButton({ color, onClick, isMobile, term, ...rest }) {
  return (
    <Container
      color={color}
      onClick={onClick}
      href={!isMobile ? `${urls.searchURL}/?term=${term}` : '#!;'}
      {...rest}
    >
      <Icon color={isMobile ? colors.white : color} />

      <span>ver mais</span>
    </Container>
  );
}

MoreButton.defaultProps = {
  isMobileLayout: false,
};

MoreButton.propTypes = {
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isMobileLayout: PropTypes.bool,
  term: PropTypes.string.isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1100,
});

export default withSizes(mapSizesToProps)(MoreButton);
