/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import PropTypes from 'prop-types';

import { Container, Group, Title, Item, SubGroup, SubItem } from './styles';

export default function Menu({
  mobile,
  show,
  data,
  onClickMenu,
  isObservatory,
}) {

  function checkExternalLink(currentItem) {
    if (currentItem.link_externo) {
      const openExternal = !(currentItem.link_externo.split('.pdf').length > 1);

      if ((mobile && show) || openExternal) {
        const url = openExternal
          ? currentItem.link_externo
          : `https://docs.google.com/gview?url=${currentItem.link_externo}`;

        return url;
      }
    }

    return false;
  }

  function replaceSpecialChars(str) {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/([^\w]+|\s+)/g, '-')
      .replace(/\-\-+/g, '-')
      .replace(/(^-+|-+$)/, '');
  }

  return (
    <Container className="sidebarMenu" mobile={mobile} show={show}>
      {isObservatory && (
        <Group>
          {data.contents.map(item => {
            if (checkExternalLink(item)) {
              return (
                <Item>
                  <a target="_blank" href={checkExternalLink(item)}>
                    {item.title}
                  </a>
                </Item>
              );
            }
            return (
              <Item
                onClick={() => onClickMenu(replaceSpecialChars(item.title))}
              >
                {item.title}
              </Item>
            );
          })}
        </Group>
      )}

      {data.subindexes.map(group => (
        <Group>
          <Title>{group.title}</Title>

          {group.contents.map(item => {
            if (checkExternalLink(item)) {
              return (
                <Item>
                  <a target="_blank" href={checkExternalLink(item)}>
                    {item.title}
                  </a>
                </Item>
              );
            }
            return (
              <Item
                onClick={() => onClickMenu(replaceSpecialChars(item.title))}
              >
                {item.title}
              </Item>
            );
          })}

          {group.subindex.map(subGroup => (
            <>
              <Item sub>{subGroup.title}</Item>
              <SubGroup>
                {subGroup.contents.map(subItem => {
                  if (checkExternalLink(subItem)) {
                    return (
                      <SubItem>
                        <a target="_blank" href={checkExternalLink(subItem)}>
                          {subItem.title}
                        </a>
                      </SubItem>
                    );
                  }

                  return (
                    <SubItem onClick={() => onClickMenu(subItem.id)}>
                      {subItem.title}
                    </SubItem>
                  );
                })}
              </SubGroup>
            </>
          ))}
        </Group>
      ))}
    </Container>
  );
}

Menu.defaultProps = {
  mobile: false,
  show: true,
  data: [],
  onClickMenu: () => { },
  isObservatory: false,
};

Menu.propTypes = {
  mobile: PropTypes.bool,
  show: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()),
  onClickMenu: PropTypes.func,
  isObservatory: PropTypes.bool,
};
