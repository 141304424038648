/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, FormControlled } from '@abqm/components';
import back from '@abqm/theme/assets/images/icones/back.png';
import { api } from '@abqm/services';
import { Container, MainTitle, ContentContainer, CloseButton, FilterSearch, FiltersContainer, SearchInput, Icon } from './styles';

const MONTHS = {
  0: "Janeiro",
  1: "Fevereiro",
  2: "Março",
  3: "Abril",
  4: "Maio",
  5: "Junho",
  6: "Julho",
  7: "Agosto",
  8: "Setembro",
  9: "Outubro",
  10: "Novembro",
  11: "Dezembro",
}
const mapNewsByMonth =
  (contentList) =>
    contentList
      .filter(item => item.title === "Notícias")[0]["contents"]
      .map(item => {
        const date = new Date(item["date"])
        const year = date.getFullYear()
        const month = date.getMonth()
        return {
          date: `${MONTHS[month]}/${year}`,
          item
        }
      }).reduce(
        (prev, curr) => ({
          ...prev,
          [curr.date]: [
            ...(
              prev[curr.date] !== undefined
                ? prev[curr.date]
                : []
            ),
            curr.item,
          ]
        })
        , {}
      )

function compareTitle(a, b) {
  if (a.title < b.title) {
    return -1;
  }
  if (b.title < a.title) {
    return 1;
  }
  return 0;
}

function compareDate(a, b) {
  if (a.date > b.date) {
    return -1;
  }
  if (b.date > a.date) {
    return 1;
  }
  return 0;
}

export default function ContentPage({
  mobile,
  title,
  indexId,
  currentContentId,
  isObservatory,
  isBoletim,
}) {
  const history = useHistory();

  const [reading, setReading] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState();
  const [currentPage, setCurrentPage] = useState('');
  const [contentList, setContentList] = useState({
    contents: [],
    subindexes: [],
  });

  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");

  function handleFilterChange(ev) {
    setFilter(ev.target.value)
  }

  async function loadContentList() {
    const response = await api.get(`/indexes/${indexId}`, {
      params: {
        pagesize: 99,
        getContents: true,
        language: 'pt',
      },
    });
    // eslint-disable-next-line no-console
    let list = response.data[0].subindex;
    list = list.filter(item => item.display);

    // Sort indexes by title and contents by date
    list.sort(compareTitle);

    list.map((item, index) => {
      list[index].contents = item.contents.sort(compareDate);
      list[index].subindex = item.subindex.filter(subindex => subindex.display);
      item.subindex.map((subItem, subItemIndex) => {
        list[index].subindex[subItemIndex].contents = subItem.contents.sort(
          compareDate
        );
        return true;
      });

      return true;
    });

    const data = {};
    data.contents = response.data[0].contents;
    data.subindexes = list;

    setContentList(data);
    setCurrentPage(response.data[0].url);
  }

  async function fetchContent(contentId) {
    const response = await api.get(`/contents/${contentId}`);

    return response.data;
  }

  async function loadContent(contentId) {
    setContent({});
    setLoading(true);
    const result = await fetchContent(contentId);
    setContent(result);
    setLoading(false);
  }

  useEffect(() => {
    loadContentList();
    if (currentContentId) {
      loadContent(currentContentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onMenuClick(item) {
    setReading(true);
    setScroll(window.pageYOffset);
    loadContent(item);

    let currentUrl = '';
    switch (currentPage) {
      case 'informacoes-e-impressos':
        currentUrl = 'impressos';
        break;
      case 'institucional-equoterapia':
        currentUrl = 'equoterapia';
        break;
      default:
        currentUrl = currentPage;
        break;
    }

    history.push(`/${currentUrl}/${item}`);
  }

  function onCancelRead() {
    setContent({});
    setReading(false);
  }

  useEffect(() => {
    if (reading) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, scroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reading]);

  const filterContent = contentList.subindexes.length ? mapNewsByMonth(contentList.subindexes) : []
  const filterLabels = Object.keys(filterContent)

  return (
    <Container mobile={mobile}>
      <MainTitle mobile={mobile}>
        {mobile && reading && (
          <CloseButton onClick={onCancelRead}>
            <img src={back} alt="back icon" />
          </CloseButton>
        )}
        {title}
      </MainTitle>
      <ContentContainer mobile={mobile}>
        <Menu
          mobile={mobile}
          show={!reading}
          data={contentList}
          onClickMenu={onMenuClick}
          isObservatory={isObservatory}
        />
        <FormControlled />

        <FilterSearch>
          <FiltersContainer>

            <SearchInput>
              <select onChange={handleFilterChange} value={filter}>
                <option value="">Filtrar por mês</option>
                {contentList.subindexes.length && filterLabels.map(item => <option value={item}>{item}</option>)}
              </select>
            </SearchInput>

            <div className="search">
              <SearchInput>
                <input
                  type="text"
                  placeholder="buscar em notícias"
                  value={search}
                  onChange={(ev) => setSearch(ev.target.value)} />
                <button type="button" onClick={() => { }}>
                  <Icon />
                </button>
              </SearchInput>
            </div>
          </FiltersContainer>
        </FilterSearch>
        <Menu
          mobile={mobile}
          show={!reading}
          data={filter !== "" && filterContent[filter] !== undefined
            ? ({
              contents: [],
              subindexes: [{
                ...contentList.subindexes.filter(item => item.title === "Notícias")[0],
                contents: filterContent[filter].filter(item => item.title.toLowerCase().includes(search.toLowerCase()))
              }]
            })
            : ({
              contents: [],
              subindexes: contentList.subindexes.filter(item => item.title === "Notícias").map(item => ({
                ...item,
                contents: item.contents.filter(item => item.title.toLowerCase().includes(search.toLowerCase()))
              })),
            })}
          onClickMenu={onMenuClick}
          isObservatory={isObservatory}
        />
      </ContentContainer>
    </Container>
  );
}

ContentPage.defaultProps = {
  mobile: false,
  title: 'Título',
  indexId: 1,
  currentContentId: null,
  isObservatory: false,
};

ContentPage.propTypes = {
  mobile: PropTypes.bool,
  title: PropTypes.string,
  indexId: PropTypes.number,
  currentContentId: PropTypes.number,
  isObservatory: PropTypes.bool,
};
