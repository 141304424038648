import styled from 'styled-components';
import { metrics, colors, fonts } from '@abqm/theme';

export const Container = styled.div`
  width: 100%;
  min-height: 90vh;
  // margin-bottom: -10%;

  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    min-height: 70vh;
  }
`;

export const ContentTop = styled.div`
  width: 100%;
  height: auto;
  padding: 2rem 6.87rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 200px;
  }

  @media (max-width: 1024px) {
    margin-top: -40px;
    padding: 2.5rem 3rem;

    justify-content: flex-end;

    img {
      width: 10rem;
    }
  }

  aside {
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
      display: none;
    }

    a + a {
      margin-left: 0.5rem;
    }

    p {
      margin-left: 0.5rem;

      color: #91672C;
      font-size: 2rem;
      font-family: 'Bebas Neue';
      text-transform: uppercase;
    }

    #socYoutube, #socInstagram, #socFacebook {
      height: auto;
      width: 35px;
    }
  }
`;

export const ContentCenter = styled.div`
  width: 100%;
  min-height: 40vh;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    min-height: 50vh;
  }

  div {
    width: 50%;
    height: auto;
    padding: 0 2rem;

    @media (max-width: 1024px) {
      width: 100%;
    }

    img {
      margin-left: -55%;

      width: 100%;
      // height: 100%;
      margin-top: 15px;

      object-fit: contain;

      @media (max-width: 1024px) {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  aside {
    margin-left: -3.5rem;
    margin-right: auto;

    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const ContentFooter = styled.div`
  width: 100%;
  height: auto;
  margin-top: 2%;

  display: flex;
  align-items: center;
`;
