import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { urls, api, indexes } from '@abqm/services';

import Content from './components/Content';

import { Nav, Container, ContentRight } from './styles';

export default function EventContent({ hotsite }) {
  const { idLocation } = useLocation();
  const [navContent, setNavContent] = useState([]);
  const [idContent, setIdContent] = useState(idLocation || null);

  const championsId = 941;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataChamp = await api.get(`hotsite/`, {
          params: {
            parent_id: hotsite[0].index_id,
          },
        });

        const conteudoChamp = dataChamp.data.find(obj => {
          return obj.title === 'Campeões';
        });

        const { data } = await api.get('contents', {
          params: {
            getCover: true,
            pagesize: 9999,
            page: 1,
            index_id: conteudoChamp.id,
          },
        });

        setNavContent(data.rows);
        if (!idLocation) setIdContent(data.rows[0].id);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [hotsite, idLocation]);

  return (
    <Container>
      <Nav>
        <h2>Campeões</h2>

        <select onChange={e => setIdContent(e.target.value)}>
          {navContent.map(item => (
            <option key={item.id} value={item.id}>
              {item.title}
            </option>
          ))}
        </select>

        <ul>
          {navContent.map(item => (
            <button key={item.id} onClick={() => setIdContent(item.id)}>
              <li>{item.title}</li>
            </button>
          ))}
        </ul>
      </Nav>

      <ContentRight>
        <Content id={idContent} />
      </ContentRight>
    </Container>
  );
}
