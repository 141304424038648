import styled from 'styled-components';
import { metrics, colors, fonts } from '@abqm/theme';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  a {
    padding: 0.8rem 4rem;
    border-radius: 50px;

    color: #fff;
    font-size: 20px;
    transition: 0.2s;
    text-decoration: none;
    font-family: 'SFProDisplay-Semibold';

    white-space: nowrap;

    background: #00943e;

    @media (max-width: 1024px) {
      font-size: 5vw;
      margin-top: 5vh;
      padding: 0.8rem 2rem;
    }

    + a {
      margin-top: 6px;
      margin-bottom: 0;
    }

  }

  .isMobile {
    @media (min-width: 1024px) {
      display: none;
    }
  }
`;
