/* eslint-disable no-restricted-globals */
var env = false;

try {
  env = require('../../.env.js').default;
} catch (error) {
  console.log('.envs is not loaded');
}

export default env.environment ? env.environment : 'develop';
