import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: auto;
  max-width: 1920px;
  padding-top: 20px;
  padding-bottom: 30px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  background: #91662A;

  .divWrap {
    width: 100%;
  }

  @media (max-width: 1024px) {
    font-size: 25px;
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 5rem;
  }

  > img {
    width: 70vw;
    height: auto;
    margin-left: -3vw;

    margin-bottom: 20px;

    @media (max-width: 1024px) {
      width: 100vw;
      height: auto;
      margin-top: 50px;
      margin-bottom: 0;
    }
  }

  h5 {
    color: #fff;
    line-height: 1.2;

    font-size: 12px;

    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }
`;

export const Iclouds = styled.section`
  margin: 20px 0 10px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    margin-top: 45px;
  }

  h5 {
    color: #02315a;
    margin-right: 10px;

    font-size: 12px;
    line-height: 1.1;

    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }

  img {
    width: 40px;
    height: auto;
    margin: 5px 0px;

    @media (max-width: 1024px) {
      width: 90px;
      height: auto;
      margin-top: 5px;
    }
  }
`;
export const SocialsHome = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin: 1rem;

  svg {
    margin-right: 5px;
  }

  img {
    width: 35px;
    height: auto;
    // margin: 0 5px -3px 5px;

    @media (max-width: 1024px) {
      width: 40px;
      margin: 0 10px 0 10px;
    }
  }

  a {
    text-decoration: none;
  }

  div {
    img {
      width: 110px;
      height: auto;
    }

    p {
      font-size: 25px;
      font-family: 'Bebas Neue';
      color: #fff;

      @media (max-width: 1024px) {
        font-size: 30px;
      }
    }
  }
}
`;
export const SocialsHomeTextDesk = styled.div`
  font-family: 'SFProDisplay-Regular';

  h5 {
    text-align: center;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;
export const SocialsHomeTextMobile = styled.div`
  font-family: 'SFProDisplay-Regular';
  display: none;
  
  h5 {
    text-align: center;
  }

  @media (max-width: 1024px) {
    display: block;
  }
`;
