import React from 'react';

import PropTypes from 'prop-types';

import { Spin } from 'react-loading-io';
import decode from 'unescape';

import { colors } from '@abqm/theme';

import {
  Container,
  ContentTitle,
  ContentResume,
  InnerContent,
  Loader,
  Iframe,
} from './styles';

export default function Content({
  mobile,
  show,
  content,
  loading,
  cancelRead,
  isBoletim,
}) {
  function renderLoader() {
    return (
      <Loader>
        <Spin type="Circles" color={colors.grey} size={100} />
      </Loader>
    );
  }

  function renderContent() {
    if (content && !loading) {
      if (content.link_externo) {
        return (
          <Iframe
            url={content.link_externo}
            width="100%"
            height="100%"
            id="pdfViewer"
            className="pdfView"
            display="initial"
            position="relative"
          />
        );
      }

      if (content.title) {
        return (
          <>
            <ContentTitle mobile={mobile}>{content.title}</ContentTitle>
            {content.resume && <ContentResume>{content.resume}</ContentResume>}
            <InnerContent
              dangerouslySetInnerHTML={{
                __html: decode(content.text),
              }}
            />
          </>
        );
      }
    }

    return (
      <ContentResume>
        {isBoletim ? (
          <>
            Com intuito de tornar público informações que necessitam de
            divulgação, a ABQM cria seu boletim corporativo para
            compartilhamento de informações relevantes.
          </>
        ) : (
          <>
            {/* Nosso compromisso é a excelência em qualidade, objetivando o pleno
            atendimento e satisfação dos associados com melhorias contínuas dos
            processos e valorizando os colaboradores. Teste Milton */}

            <p>Fundada em 15 de agosto de 1969, a Associação Brasileira de Criadores de Cavalo Quarto de Milha (ABQM), por expressa concessão do Ministério da Agricultura, Pecuária e Abastecimento (MAPA), executa em todo o país, o Serviço de Registro Genealógico do Cavalo Quarto de Milha (SRG).</p>
            <br />
            <p>Seu objetivo é manter o registro genealógico, a identidade e propriedade do Cavalo Quarto de Milha, zelando pela pureza da Raça e para tais fins, mantendo relações com entidades estrangeiras congêneres, exercendo o controle e a fiscalização da procriação, gestação, nascimento, identificação e filiação, nacionalização de animais importados, de identificação, de propriedade e qualquer outra documentação correspondente às finalidades acima mencionadas.</p>
          </>
        )}
      </ContentResume>
    );
  }

  return (
    <Container mobile={mobile} show={show}>
      {loading ? renderLoader() : renderContent()}
    </Container>
  );
}

Content.defaultProps = {
  content: {},
  show: false,
  mobile: false,
  loading: false,
  cancelRead: () => { },
};

Content.propTypes = {
  mobile: PropTypes.bool,
  show: PropTypes.bool,
  content: PropTypes.shape(),
  loading: PropTypes.bool,
  cancelRead: PropTypes.func,
};
