import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { SearchInput as SearchInputComponent } from '@abqm/components';
import { metrics, colors, fonts } from '@abqm/theme';
import { pxToRem } from '@abqm/functions';

export const Container = styled.header`
  z-index: 1009;

  width: 100vw;
  height: ${pxToRem(130)};
  /* padding-left: ${pxToRem(16)}; */
  margin-bottom: ${metrics.baseSpacing(2)};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);

  position: fixed; /* Set the navbar to fixed position */
  overflow: hidden;

  display: flex;
  align-items: center;
  flex-direction: column;

  transition: 0.3s;
  background-color: #ffffff;


  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
      visibility: hidden;
    `}

  ${media.lessThan('huge')`
    height: ${pxToRem(110)};
  `}

  ${media.lessThan('large')`
    height: auto;
  `}


  ${media.lessThan('medium')`
    height: auto;
  `}
`;

export const MenuWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;

  transition: 0.5s;

  ${media.lessThan('medium')`
    overflow: hidden;

    ${({ type }) =>
      type === 'play' &&
      css`
        overflow: unset !important;
      `}
  `}

  ${({ show }) =>
    !show &&
    css`
      margin-top: ${pxToRem(-94)};
      margin-bottom: ${pxToRem(16)};

      opacity: 0;
      visibility: hidden;

      ${media.greaterThan('1000px')`
        margin-bottom: ${pxToRem(8)};
      `}
    `}
`;

export const SearchWrapper = styled.div`
  width: 50%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  transition: 0.3s;

  ${media.lessThan('medium')`
    width: 100%;
    margin-bottom: ${pxToRem(16)};
  `}

  ${media.lessThan('1024px')`
    width: 100%;
    margin-bottom: ${pxToRem(16)};

    justify-content: center;
  `}

  ${media.lessThan('768px')`
    width: 90%;
    margin-bottom: ${pxToRem(16)};

    justify-content: center;
  `}
`;

export const HeaderContent = styled.div`
  width: 100%;
  height: ${({ visible }) => (visible ? 'initial' : '0')};

  display: flex;
  flex-direction: row;
  justify-content: center;

  transition: 0.3s;

  ${SearchWrapper} {
    opacity: ${({ visible }) => (visible ? 'initial' : '0')};
  }
`;

export const Logo = styled.img`
  width: ${pxToRem(149)};
  height: ${pxToRem(50)};
  margin-bottom: ${metrics.baseSpacing(4)};


  ${media.lessThan('huge')`
    height: ${pxToRem(90)};
    margin-bottom: ${metrics.baseSpacing(3)};
  `}

  ${media.lessThan('1370px')`
    height: ${pxToRem(70)};
    margin-bottom: ${metrics.baseSpacing(1)};
  `}

  ${media.lessThan('medium')`
    margin-top: ${metrics.baseSpacing(2)};
  `}

  ${media.lessThan('1024px')`
    width: ${({ type }) => (type === 'magazine' ? pxToRem(170) : pxToRem(118))};
    margin-top: 0};
  `}

  ${media.lessThan('768px')`
    position: relative;
    top: ${pxToRem(5)};

    width: ${({ type }) => (type === 'magazine' ? pxToRem(170) : pxToRem(118))};
    margin-top: 0;

    object-fit: contain;
  `}
`;

export const LogoWrapper = styled.div`
  height: 100%;
  margin-bottom: ${({ external }) => (external ? '0' : '-16px')};

  width: 100% !important;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChannelsLink = styled.a`
  width: 10%;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${media.greaterThan('1000px')`
    position: absolute;
    right: ${pxToRem(30)};
    top: ${pxToRem(25)};
  `}
`;

export const IconWrapper = styled.div`
  transition: 0s;

  position: absolute;
  right: 0;
  top: ${pxToRem(35)};
  transform: translateY(-50%);
`;

export const IconLink = styled.a`
  position: absolute;
  right: 0;
  top: ${pxToRem(40)};
  transform: translateY(-50%);
`;

export const IconContainer = styled.img`
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};

  margin-right: ${pxToRem(12)};
  color: ${colors.lightBlue};
`;

export const IconHome = styled.img`
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};

  margin-right: ${pxToRem(12)};
  color: ${colors.lightBlue};
`;

export const SearchInput = styled(SearchInputComponent)`
  width: ${pxToRem(263)};
  height: ${pxToRem(40)};
  margin-top: ${pxToRem(4)};

  font-size: ${pxToRem(14)};
  border: 1px solid ${colors.lightGrey};

  input {
    width: 100%;
    ${fonts.size3};
    text-align: center;

    &::placeholder {
      color: ${colors.lightGrey};
    }
  }

  svg {
    display: none;
  }
`;
