import React, { useEffect, useState, useCallback } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import { urls, api, indexes } from '@abqm/services';

import {
    Header,
    LiveHome,
    Sponsors,
    NavMobile,
    LastedNews,
    FooterHome,
    Background,
} from '../../components';

import * as S from './styles';

ReactGA.initialize('UA-88283231-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default function Main() {
    const { url } = useParams();

    const [hotsite, setHotsite] = useState([]);
    const [hotsiteDados, setHotsiteDados] = useState([]);
    const [docId, setDocId] = useState(0);

    const fetchDocumentos = useCallback(async documento => {
        try {
            setDocId(documento.id);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const fetchDados = useCallback(async id => {
        try {
            const { data } = await api.get(`hotsite/`, {
                params: {
                    parent_id: id,
                },
            });

            setHotsiteDados(data);

            const doc = data.find(obj => {
                return obj.title === 'Documentos';
            });

            fetchDocumentos(doc);

            // console.log(doc.id);

            // setDocId(docId + doc.id);

            // console.log(docId);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await api.get(`hotsite/`, {
                    params: {
                        url: url,
                    },
                });
                console.log(data);
                setHotsite(data);
                fetchDados(data[0].index_id);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const conteudo = hotsiteDados.find(obj => {
        return obj.title === 'Documentos';
    });

    const conteudoVideos = hotsiteDados.find(obj => {
        return obj.title === 'Transmissões';
    });

    const conteudoNews = hotsiteDados.find(obj => {
        return obj.title === 'Notícias';
    });

    const sponsors = hotsiteDados.find(obj => {
        return obj.title === 'Patrocinadores';
    });

    //console.log(docId);
    //console.log(hotsite);

    //console.log(hotsiteDados);

    // async function getHotsite({ url }) {
    //     try {
    //         const { data } = await api.get('hotsite?url=' + url);

    //         setHotsite(data);
    //         return data
    //     } catch (error) {
    //         return console.log(error);
    //     }
    // }

    return (
        <S.Container>
            <Background hotsite={hotsite} />
            <S.Wrapper>
                <Header hotsite={hotsite} conteudos={conteudo} conteudoNews={conteudoNews} />
                <NavMobile hotsite={hotsite} />
                <LiveHome hotsite={hotsite} conteudoVideos={conteudoVideos} />
                <LastedNews hotsite={hotsite} conteudoNews={conteudoNews} />
                <Sponsors hotsite={hotsite} sponsors={sponsors} />
                <FooterHome hotsite={hotsite} />
            </S.Wrapper>
        </S.Container>
    );
}
