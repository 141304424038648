import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

// export default isoDate => {
//   return format(parseISO(isoDate), "dd'/'MM'/'yyyy • hh:mm", { locale: pt });
// };


export default isoDate => {
    const data = format(parseISO(isoDate), "dd'/'MM'/'yyyy", { locale: pt });
    const hora = format(parseISO(isoDate), "HH", { locale: pt });
    const minuto = format(parseISO(isoDate), "mm", { locale: pt });

    if (parseInt(minuto) > 0) {
        return data + ' • ' + hora + 'h' + minuto;
    }
    else {
        return data + ' • ' + hora + 'h';
    }
};