import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  top: 0;
  z-index: 9999;
  position: fixed;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  backdrop-filter: blur(6px);
  background: rgba(0, 0, 0, 0.7);

  button {
    border: none;

    color: #fff;
    background: transparent;
  }

  ${media.lessThan('small')`
    height: 110%;
  `}
`;

export const Header = styled.header`
  top: 0;
  position: absolute;

  width: 100%;
  height: 80px;

  display: flex;
  justify-content: flex-end;

  button {
    width: 80px;
    border: none;

    background-color: transparent;

    ${media.lessThan('large')`
      position: absolute;
      top: 90px;
    `}
  }
  .svgClose {
    fill: white;
  }
`;

export const Content = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin-top: 20px;

    color: white;
    font-size: 60px;
    font-family: 'SFProDisplay-Thin';
  }

  img .logoQuartodeMilha {
    top: 0;

    width: 5%;
    height: auto;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > div {
      width: 300px;
      height: 300px;
      margin: 0px 5px 0px 5px;

      img {
        width: 100%;
        padding: 5%;
        height: 100%;

        object-fit: contain;
      }
    }
  }

  ${media.lessThan('small')`
    max-width: 90%;

    h1 {
      margin-top: 16px;

      font-size: 32px;
    }

    > div {
      max-width: 100%;
      margin-top: 16px;

      > div {
        width: 100%;
        height: auto;

        img {
          width: 100%;
          margin-top:

          object-fit: contain;
        }
      }
    }
  `}
`;

export const Footer = styled.div`
  bottom: 0;
  position: absolute;

  width: 100%;
  height: 15%;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  a {
    width: 300px;
    height: 40px;
    border: none;
    border-radius: 8px;
    text-decoration: none;
    box-shadow: rgba(51, 51, 51, 0.2) 0px 3px 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-family: 'SFProDisplay-Regular';
    background-image: linear-gradient(rgb(50, 154, 88), rgb(135, 197, 0) 111%);
  }

  ${media.lessThan('small')`

    height: 22%;

    > a {
      width: 70%;
      height: 60px;
    `}
`;
