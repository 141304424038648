import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { colors, fonts, metrics } from '@abqm/theme';

const renders = {
  default: css`
    flex-direction: column;

    ${media.lessThan('large')`
      flex-direction: column-reverse;

      &:after {
        margin-bottom: ${metrics.baseSpacing()};

        ${fonts.size7};
        line-height: 1.19;
        text-align: center;
        color: ${colors.eightGrey};
        content: 'Baixe o nosso Aplicativo'
        font-family: SFProDisplay-Thin, sans-serif;
      }
    `}

    > .socials {
    }

    > .stores {
      a {
        & + a {
          margin-left: ${metrics.baseSpacing(0.5)};

          ${media.lessThan('medium')`
            margin-left: 0;
          `}
        }
      }
    }
  `,
  footer: css`
    flex-direction: column;

    &:before {
      margin-bottom: ${metrics.baseSpacing()};

      ${fonts.size7};
      line-height: 1.19;
      text-align: center;
      color: ${colors.eightGrey};
      font-family:  SFProDisplay-Thin, sans-serif;
      content: 'Baixe o nosso Aplicativo';

      ${media.lessThan('medium')`
        font-size: ${pxToRem(30)};
      `};
    }

    > .socials {
      display: flex;
      justify-content: center;
      align-items: center;
      
      a {
        ${media.lessThan('large')`
          height: ${pxToRem(44)} !important;
        `};

        ${media.lessThan('small')`
          height: ${pxToRem(30)} !important;
        `};
      }
    }

    > .stores {
      ${media.lessThan('large')`
        width: ${pxToRem(400)} !important;
        position: relative;

        display: flex;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-bottom: ${metrics.baseSpacing(3)};
        justify-content: space-between;

        &:after {
          position: absolute;
          margin-left: 50%;
          transform: translateX(-50%);
          width: 170% !important;
        }
      `}

      ${media.lessThan('medium')`
        position: relative;

        display: flex;
        justify-content: space-between;
        margin-bottom: ${metrics.baseSpacing(3)};

        &:after {
          position: absolute;
          margin-left: 50%;
          transform: translateX(-50%);
          width: 150% !important;
        }
      `}

      ${media.lessThan('767px')`
        width: ${pxToRem(320)} !important;
        position: relative;

        display: flex;
        justify-content: space-between;
        margin-bottom: ${metrics.baseSpacing(3)};

        &:after {
          position: absolute;
          margin-left: 50%;
          transform: translateX(-50%);
          width: 100% !important;
        }
      `}

      a {
        > .icon {
          ${media.lessThan('large')`
            height: ${pxToRem(54)} !important;
          `};

          ${media.lessThan('small')`
            height: ${pxToRem(44)} !important;
          `};
        }
      }
    }

    ${media.greaterThan('large')`
      width: auto;

      flex-direction: row;

      &:before {
        display: none;
      }

      > .stores,
      > .socials {
        position: relative;

        &:after {
          position: absolute;
          left: 0;

          ${fonts.size7};
          text-align: left;
          line-height: 1.19;
          color: ${colors.eightGrey};
          content: 'Baixe o nosso App';
          font-family: SFProDisplay-Thin, sans-serif;
        }
      }

      > .stores {
        position: relative;

        display: flex;
        flex-direction: row;

        margin-right: ${metrics.baseSpacing(20)};

        &:after {
          top: ${pxToRem(-42)};
          content: 'Baixe o nosso App';
        }

        .icon {
          height: ${pxToRem(34)} !important;
        }

        a {
          & + a {
            margin-left: ${metrics.baseSpacing(1.5)};
          }
        }
      }

      > .socials {
        margin-top: ${metrics.baseSpacing(1)} !important;
        align-items: flex-start !important;

        &:after {
          top: ${pxToRem(-42)};
          content: 'Acompanhe-nos';
        }

        a {
          img {
            height: 24px !important;
          }
          & + a {
            margin-left: ${metrics.baseSpacing(1)} !important;
          }
        }
      }
    `}
  `,
};

export const Container = styled.div`
  width: ${pxToRem(248)};

  display: flex;

  ${({ type }) => renders[type]};

  ${media.lessThan('large')`
    width: 100%;
  `}

  > .socials {
    margin-bottom: ${metrics.baseSpacing(0)};

    display: flex;
    align-items: flex-end;

    ${media.lessThan('large')`
      align-items: center;
    `}

    a {
      height: ${pxToRem(28)};

      ${media.lessThan('large')`
        height: ${pxToRem(28)};
      `}

      & + a {
        margin-left: ${metrics.baseSpacing(0.5)};

        ${media.lessThan('large')`
          margin-left: ${metrics.baseSpacing(2)};
        `}
      }

      > img {
        height: 100%;
      }
    }

    > .twitter {
      margin-left: ${metrics.baseSpacing(0.5)};

      ${fonts.size7};
      line-height: 1.2;
      text-transform: uppercase;
      color: ${colors.grey};
      font-family: SFProDisplay-Regular, sans-serif;
      /* font-weight: 600; */
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
      letter-spacing: -0.9px;

      ${media.lessThan('large')`
        ${fonts.size7};
        margin-left: ${metrics.baseSpacing(1)};
      `}

      ${media.lessThan('1024')`
        ${fonts.size8};
        margin-left: ${metrics.baseSpacing(1)};
      `}

      ${media.lessThan('medium')`
        ${fonts.size7};
        margin-left: ${metrics.baseSpacing(1)};
      `}
    }
  }

  > .stores {
    margin-top: ${metrics.baseSpacing(1)};

    ${media.lessThan('large')`
      position: relative;

      display: flex;
      justify-content: space-between;
      margin-bottom: ${metrics.baseSpacing(4)};

      &:after {
        position: absolute;
        bottom: -16px;

        width: 100%;
        height: ${pxToRem(1)};

        display: flex;

        content: "";
        opacity: 0.5;
        background: ${colors.lightBlue};
      }
    `}

    a {
      .icon {
        height: ${pxToRem(36)};

        ${media.lessThan('large')`
          height: ${pxToRem(42)};
        `}
      }
    }
  }
`;

export const Twitter = styled.a`
  ${props =>
    props.place === 'footer' &&
    css`
      color: ${colors.eightGrey} !important;
    `};
`;
