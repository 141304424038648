/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withSizes } from 'react-sizes';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Header } from '@abqm/components';

import { Container, Iframe } from './styles';

import data from './data';
import { apiUrl } from '../../services/api';

import { urls } from '@abqm/services';

function Main({ isMobile, match }) {
  const { page } = match.params;
  const authenticatedPages = useMemo(() => ['consulta-de-animais'], []);

  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies([]);

  const COOKIE_URL = urls.cookieURL;

  const onSetCookies = useCallback(
    ({ name, token, expireDate }) => {
      setCookie(name, token, {
        path: '/',
        domain: COOKIE_URL,
        ...(expireDate ? { expires: expireDate } : {}),
      });
    },
    [setCookie]
  );

  const onRemoveCookies = useCallback(() => {
    removeCookie('ServicosOnline[tokenABQM]', {
      path: '/',
      domain: COOKIE_URL,
    });
  }, [removeCookie]);

  useEffect(() => {
    async function validateToken() {
      try {
        if (!cookies['ServicosOnline[tokenABQM]']) {
          onRemoveCookies('ServicosOnline[tokenABQM]');
          history.push('/login/consulta?', '_self');
          return;
        }

        const { data: responseData } = await apiUrl.get(
          '/api/Site/Pessoa/PessoaSelectPorToken',
          {
            params: { token: cookies['ServicosOnline[tokenABQM]'] },
          }
        );

        if (responseData.msg) {
          onRemoveCookies('ServicosOnline[tokenABQM]');
          history.push('/login/consulta?', '_self');
        } else {
          onSetCookies({
            name: 'ServicosOnline[tokenABQM]',
            token: cookies['ServicosOnline[tokenABQM]'],
          });
        }
      } catch (error) {
        history.push('/login/consulta?', '_self');
      }
    }

    if (authenticatedPages.includes(page)) validateToken();
  }, [
    history,
    authenticatedPages,
    page,
    cookies,
    onRemoveCookies,
    onSetCookies,
  ]);

  return (
    <>
      {/* <Container>
        <Header type="services" />
        {data[page] && (
          <Iframe
            src={`${data[page].link}${authenticatedPages.includes(page)
              ? `Basic ${cookies['ServicosOnline[tokenABQM]']}`
              : ''
              }`}
          />
        )}
      </Container> */}

      <Container>
        <Header type="services" />
        {data[page] && data[page].link.includes("AtendInteligente.aspx") ? (
          <Iframe
            src={`${data[page].link}${cookies['ServicosOnline[tokenABQM]'] ? '&token=' + cookies['ServicosOnline[tokenABQM]'] : ''}`}
          />
        ) : data[page] && (
          <Iframe
            src={`${data[page].link}${cookies['ServicosOnline[tokenABQM]'] ? '?token=' + cookies['ServicosOnline[tokenABQM]'] : ''}`}
          />
        )}

        {/* {data[page] && (
          <Iframe
            src={`${data[page].link}${cookies['ServicosOnline[tokenABQM]'] ? '?token=' + cookies['ServicosOnline[tokenABQM]'] : ''}`}
          />
        )} */}
      </Container>

      {/* <BlackContainer>
        <Footer />
      </BlackContainer> */}
    </>
  );
}

Main.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1100,
});

export default withSizes(mapSizesToProps)(Main);
