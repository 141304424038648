import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import Slider from 're-carousel';

import { Line } from 'rc-progress';

import { pxToRem } from '@abqm/functions';
import { metrics, colors, fonts } from '@abqm/theme';

const watermarkText = {
  horizontalRight: css`
    top: -${pxToRem(18)};
    right: 0;

    ${media.lessThan('large')`
      top: -${pxToRem(40)};
    `}
  `,
  default: css`
    ${media.greaterThan('large')`
      top: 50%;
      left: -${metrics.baseSpacing(1)};
      transform: translate(-50%, -50%) rotate(270deg);
    `}
    
    ${media.lessThan('large')`
      top: -${pxToRem(22)};
    `}

    ${media.lessThan('small')`
      top: -${pxToRem(16)};
    `}
  `,
};

export const Container = styled.div`
  position: relative;

  width: 100%;
  height: calc((${window.innerWidth}px - 24px) / 4.57);

  ${({ containerType }) =>
    containerType === 'half' &&
    css`
      /* Adjust height according to each of the main Container's max-width
      when there are 2 ads. 64px is the margin between ads. */

      /* Default max-width is 1700px */
      height: calc(((1700px - 64px) / 2) / 4.57);

      /* max-width 1400px */
      ${media.lessThan('1536px')`
        height: calc(((1400px - 64px) / 2) / 4.57);
      `}

      /* max-width 1300px */
      ${media.lessThan('1400px')`
        height: calc(((1300px - 64px) / 2) / 4.57);
      `}

      /* Width's below the minimum max-width */
      ${media.lessThan('1300px')`
        height: calc(((${window.innerWidth}px - 64px) / 2) / 4.57);
      `}
    `}

  &:before {
    position: absolute;
    top: -100px;

    opacity: 0.4;
    ${fonts.size1};
    font-weight: 600;
    line-height: 1.71;
    text-transform: uppercase;
    color: ${colors.eightGrey};
    font-family: SFProDisplay-Regular, sans-serif;

    content: 'PUBLICIDADE';

    ${({ watermarkType }) => watermarkText[watermarkType]};
  }
`;

export const Carousel = styled(Slider)`
  width: 100%;
  border-radius: ${metrics.borderRadius()};

  overflow: hidden;

  .carousel-initialized {
    width: 100%;
  }
`;

export const Item = styled.a`
  position: relative;

  width: 100%;
  height: auto;

  & + a {
    margin-left: ${metrics.baseSpacing(7)};
  }

  > span {
  }

  > img {
    width: 100%;
    height: 100%;

    border-radius: ${metrics.borderRadius()};
    border: ${pxToRem(1)} solid ${colors.lightGrey};

    object-fit: cover;
  }
`;

export const ProgressBar = styled(Line)`
  position: absolute;
  left: 0;
  bottom: ${pxToRem(0)};
  z-index: 999;
`;
