import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { metrics } from '@abqm/theme';

export const Page = styled.div``;

export const Container = styled.div`
  width: 100%;
  max-width: ${pxToRem(1700)};
  margin: 0 auto;
  margin-top: 0;

  ${media.lessThan('1536px')`
    max-width: ${pxToRem(1400)};
  `}

  ${media.lessThan('huge')`
    max-width: ${metrics.maxWidth};
  `}

  ${media.lessThan('large')`
    padding: 0;
  `}

  ${media.between('medium', 'large')`
    padding: 0;
  `}
`;
