/* eslint-disable no-use-before-define */
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { metrics, fonts, colors } from '@abqm/theme';
import { lighten, darken } from 'polished';

export const Container = styled.div`
  display: block;
  top: ${metrics.baseSpacing(12)};

  width: ${pxToRem(400)};
  height: ${pxToRem(635)};

  ${media.lessThan('1536px')`
    height: ${pxToRem(420)};
  `}

  ${media.lessThan('huge')`
    height: ${pxToRem(550)};
  `}

  ${media.lessThan('1366px')`
    height: ${pxToRem(350)};
  `}

  ${media.lessThan('large')`
    width: 100%;
    height: 100%;
  `}

  padding: ${metrics.baseSpacing(3)};
  padding-top: ${metrics.baseSpacing(3)};
  padding-bottom: ${metrics.baseSpacing(2)};

  overflow-y: ${props => (props.mobile ? 'auto' : 'scroll')};
  border: solid 0.5px ${lighten(0.35, colors.mediumGrey)};
  border-radius: 8px;

  ${props =>
    props.mobile &&
    css`
      padding: 0;
      padding-left: ${metrics.baseSpacing(1)};
      padding-right: ${metrics.baseSpacing(1)};

      display: ${!props.show ? 'none' : 'block'};
      border: none;

      overflow-y: visible;

      ${Title} {
        padding: 0;

        ${fonts.size6};
      }

      ${Group} {
        margin-bottom: ${metrics.baseSpacing(4)};
      }

      ${Item} {
        ${fonts.size3};
        line-height: 1.2;

        padding: 0;
      }
    `}

  ::-webkit-scrollbar {
    width: ${pxToRem(10)};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    display: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    position: absolute;

    background: ${colors.grey};
    border-radius: 15px;
    height: ${pxToRem(80)};
  }

  /* &.sidebarMenu:first-of-type div:last-child,
  &.sidebarMenu:last-of-type div:first-child {
    display: none;
  } */

  &.sidebarMenu:last-of-type {
    height: auto !important;
    width: 70%;
    margin-left: 32%;
    border: none;
    margin-top: 3%;

    p:hover {
      transform: translateX(0%);
    }

    @media (max-width: 1500px) {
      height: auto !important;
      width: 60%;
      margin-left: 41%;
      border: none;
    }

    @media (max-width: 1170px) {
      height: auto !important;
      width: 100%;
      margin-left: 0%;
      border: none;
    }
  }

  &.sidebarMenu {
    @media (max-width: 1170px) {
    height: auto !important;
    width: 90%;
    margin: 0 auto;
    margin-top: 5%;
    margin-bottom: 7%;
    border: solid 0.5px #c9c9c9;
    border-radius: 10px;
    padding: 20px;
    background: #FFF;
    z-index: 999;
    }
  }
`;

export const Group = styled.div`
  margin-bottom: ${metrics.baseSpacing(6)};
`;

export const SubGroup = styled.div`
  margin: ${metrics.baseSpacing(1)};
  margin-top: ${metrics.baseSpacing(0.5)};
`;

export const Title = styled.p`
  position: relative;

  padding-left: ${metrics.baseSpacing(1)};
  margin-bottom: ${metrics.baseSpacing(2)};

  ${fonts.size6};
  color: ${colors.grey};
  line-height: 1;
  font-weight: 600;
  font-family: SFRounded-Semibold, sans-serif;

  &::before {
    position: absolute;
    left: -1%;
    bottom: -${metrics.baseSpacing(0.75)};

    height: 0.5px;
    width: 102%;

    background-color: ${lighten(0.2, colors.lightBlue)};
    content: '';
  }
`;

export const Item = styled.p`
  display: block;
  position: relative;

  padding-left: ${metrics.baseSpacing(1)};
  margin-bottom: ${metrics.baseSpacing(2)};

  transition: 0.5s;
  transform: translateX(0);
  ${fonts.size2};
  color: ${colors.grey};
  line-height: 1rem;
  ${props => !props.sub && `cursor: pointer`};
  ${props => props.sub && `font-weight: bold`};
  font-family: SFRounded-Regular, sans-serif;

  &:hover {
    transition: 0.3s;
    color: ${darken(0.5, colors.grey)};
    transform: translateX(2%);
  }

  a {
    text-decoration: none;
    color: ${colors.grey};
  }
`;

export const SubItem = styled.p`
  position: relative;
  padding-left: ${metrics.baseSpacing(1)};
  margin-bottom: ${metrics.baseSpacing(2)};

  transition: 0.5s;
  transform: translateX(0);
  cursor: pointer;
  ${fonts.size2};
  color: ${lighten(0.2, colors.grey)};
  line-height: 1;
  font-family: SFRounded-Regular, sans-serif;

  &:hover {
    transition: 0.3s;
    color: ${darken(0.5, colors.grey)};
    transform: translateX(2%);
  }

  a {
    text-decoration: none;
    color: ${lighten(0.2, colors.grey)};
  }
`;
