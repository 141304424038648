const remSize = 16;

function getDynamicSize(size) {
  const windowSize = window.innerWidth;

  const fontSize = {
    '768': size + 4,
    '1024': size + 10,
  };

  return fontSize[windowSize] || size;
}

export default {
  size1: {
    'font-size': `${getDynamicSize(12) / remSize}rem`,
  },
  size2: {
    'font-size': `${getDynamicSize(14) / remSize}rem`,
  },
  size3: {
    'font-size': `${getDynamicSize(16) / remSize}rem`,
  },
  size4: {
    'font-size': `${getDynamicSize(18) / remSize}rem`,
  },
  size5: {
    'font-size': `${getDynamicSize(20) / remSize}rem`,
  },
  size6: {
    'font-size': `${getDynamicSize(22) / remSize}rem`,
  },
  size7: {
    'font-size': `${getDynamicSize(32) / remSize}rem`,
  },
  size8: {
    'font-size': `${getDynamicSize(40) / remSize}rem`,
  },
  size9: {
    'font-size': `${getDynamicSize(50) / remSize}rem`,
  },
};
