import styled from 'styled-components';
import media from 'styled-media-query';
import { GoSearch } from 'react-icons/go';

import { pxToRem } from '@abqm/functions';
import { colors, fonts } from '@abqm/theme';

export const Container = styled.div`
  width: 100%;
  height: ${pxToRem(58)};
  padding: 0 ${pxToRem(24)};
  max-width: ${pxToRem(580)};
  border-radius: ${pxToRem(4)};
  border: 1px solid ${colors.lightBlue};

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: 0.3s;
  background: ${colors.white};

  ${media.lessThan('medium')`
    max-width: 80%;
    margin: 0 auto;
    height: ${pxToRem(40)};
    padding: 0 ${pxToRem(14)};
  `}

  > input {
    width: 80%;
    border: none;

    ${fonts.size6};
    color: ${colors.darkBlue};
    font-family: 'SFRounded-Regular', sans-serif;

    &::placeholder {
      color: ${colors.lightBlue};
    }

    ${media.lessThan('medium')`
      ${fonts.size5};
    `}
  }

  > button {
    border: none;

    background: transparent;
  }

  &:focus-within {
    transition: 0.3s;
    border-width: 2px;
  }
`;

export const Icon = styled(GoSearch).attrs({
  size: window.innerWidth <= 720 ? 20 : 32,
})`
  margin-top: 2px;
  color: ${colors.lightBlue};
`;
