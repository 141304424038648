import React, { useEffect, useState, useCallback } from 'react';
import { urls, api, indexes } from '@abqm/services';
import { useParams, useHistory } from 'react-router-dom';

import {
  NavMenu,
  ChampionsContent,
  FooterHome,
} from '../../components';

import { Container } from './styles';

const Main = () => {
  const { url } = useParams();

  const [hotsite, setHotsite] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get(`hotsite/`, {
          params: {
            url: url,
          },
        });

        setHotsite(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Container>
      <NavMenu hotsite={hotsite} />
      <ChampionsContent hotsite={hotsite} />
      <FooterHome hotsite={hotsite} />
    </Container>
  );
};

export default Main;
