import styled from 'styled-components';
import media from 'styled-media-query';

import { metrics } from '@abqm/theme';

import colors from '@abqm/theme/colors';

export const Container = styled.div`
  display: flex;

  flex-direction: ${props => (props.isVertical ? 'column' : 'row')};
  justify-content: flex-start;
  align-items: center;

  position: ${props => (props.isVertical ? 'fixed' : 'relative')};

  margin-left: ${metrics.baseSpacing(5)};

  ${media.lessThan('huge')`
    margin-left:  ${metrics.baseSpacing(4)};
  `}

  ${media.lessThan('large')`
    margin-left: ${metrics.baseSpacing(2)};
    margin-top: ${metrics.baseSpacing(2)}
  `}

  ${media.lessThan('medium')`
    margin-bottom: ${metrics.baseSpacing(2)};
  `}

  > a, img {
    margin-top: ${metrics.baseSpacing(1)};
  }

  img {
    cursor: pointer;
  }

  > a + a,
  img {
    ${media.lessThan('medium')`
      margin-left: ${metrics.baseSpacing(2)};
    `}
  }

  > a > svg {
    color: ${colors.grey};

    transition: all 0.1s;
  }

  /* Facebook */
  > :nth-child(1) svg:hover{
    color: #4267B2; 
  }

  /* Whatsapp */
  > :nth-child(2) svg:hover{
    color: #25D366;
  }

  /* Twitter */
  > :nth-child(3) svg:hover{
    color: #00ACEE;
  }

  /* Link */
  > :nth-child(4) img:hover{
    filter: brightness(0.5);
  }
`;
