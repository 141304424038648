import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function ContentWrap({ children, direction, ...rest }) {
  return (
    <Container direction={direction} {...rest}>
      {children}
    </Container>
  );
}

ContentWrap.defaultProps = {
  direction: 'column',
};

ContentWrap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  direction: PropTypes.string,
};
