import React from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';

import { Container, CategoryTitle } from './styles';

function SecondaryNew({ isMobile, title, date, category, cover, link }) {
  function renderDesktop() {
    return (
      <>
        <CategoryTitle title={category} date={date} isFeatured />

        <div className="content">
          <div className="img-wrapper">
            <img alt="Banner da Notícia" src={cover} />
          </div>

          <h4>{title}</h4>
        </div>
      </>
    );
  }

  function renderMobile() {
    return (
      <>
        <div className="content">
          <div className="img-wrapper">
            <img alt="Banner da Notícia" src={cover} />
          </div>

          <div className="wrap">
            <CategoryTitle title={category} date={date} />

            <h4>{title}</h4>
          </div>
        </div>
      </>
    );
  }

  return (
    <Container to={link}>
      {isMobile ? renderMobile() : renderDesktop()}
    </Container>
  );
}

SecondaryNew.defaultProps = {
  title: '',
  date: '',
  resume: '',
  category: '',
  cover: '',
};

SecondaryNew.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  title: PropTypes.string,
  date: PropTypes.string,
  resume: PropTypes.string,
  category: PropTypes.string,
  cover: PropTypes.string,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 1025,
});

export default withSizes(mapSizesToProps)(SecondaryNew);
