import React, { useState, useEffect, useCallback } from 'react';

import { urls, api, indexes } from '@abqm/services';

import { Container } from './styles';

export default function background({ hotsite }) {
  return (
    <Container>
      {hotsite.map(item => (
        <>
          <img src={`${urls.hotsitesImageURL}/${item?.imagem_fundo}`} alt="Background" className="mobile" />
          <img src={`${urls.hotsitesImageURL}/${item?.imagem_fundo}`} alt="background" className="desktop" />
        </>
      ))}
    </Container>
  );
}
