import React from 'react';
import PropTypes from 'prop-types';
import { Container, Icon } from './styles';
import { formatDate } from '@abqm/functions';

export default function CategoryTitle({
  title,
  date,
  isFeatured,
  withoutCategory,
  ...rest
}) {
  return (
    <Container
      isFeatured={isFeatured}
      withoutCategory={withoutCategory}
      {...rest}
    >
      {!withoutCategory && (
        <>
          <h3>
            <Icon /> {title}
          </h3>
          {isFeatured && <span>|</span>}
        </>
      )}

      <time>{date && formatDate(date)}</time>
    </Container>
  );
}

CategoryTitle.defaultProps = {
  isFeatured: false,
  withoutCategory: false,
  title: '',
  date: '',
};

CategoryTitle.propTypes = {
  date: PropTypes.string,
  title: PropTypes.string,
  isFeatured: PropTypes.bool,
  withoutCategory: PropTypes.bool,
};
