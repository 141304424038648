import React, { useEffect, useState, useCallback } from 'react';

// import api from '../../services/api';
import { urls, api, indexes } from '@abqm/services';
//import api from '../../../services/api';
//import { handleScrollTop, formatDate } from '../../../../functions';
import { handleScrollTop, formatDate } from '@abqm/functions';

import { Container, CardNews, CardContent } from './styles';

export default function MoreNews({ color, hotsite, conteudoNews }) {
  const [moreNews, setMoreNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [moreNewsFormatted, setMoreNewsFormatted] = useState([]);
  const [styleTextos, setStyleTextos] = useState({});
  const [styleNoticias, setStyleNoticias] = useState({});
  const [styleBotao, setStyleBotao] = useState({});
  const [qtdNoticias, setQtdNoticias] = useState(1);

  const filteredNews = useCallback(props => {
    const formattedNews = props.slice(3, 3 + (5 * currentPage));

    setMoreNewsFormatted(formattedNews);
  }, [moreNews]);

  useEffect(() => {
    filteredNews(moreNews);
  }, [moreNews, filteredNews]);

  useEffect(() => {
    try {
      setStyleTextos({ color: `${hotsite[0].cor_transmissoes_textos}` });
      setStyleNoticias({ backgroundColor: `${hotsite[0].cor_noticias_fundo}`, color: `${hotsite[0].cor_noticias_textos}` });
      setStyleBotao({ backgroundColor: `${hotsite[0].cor_maisnoticias_botao}`, color: `${hotsite[0].cor_maisnoticias_texto}` });

      //border: solid 15px rgba(145, 102, 42, 0.3);

    } catch (error) {
      console.log(error);
    } finally {
    }
  }, [hotsite, moreNews]);

  const fetchData = useCallback(async page => {
    try {
      const { data } = await api.get('contents', {
        params: {
          getCover: true,
          pagesize: 3 + (5 * page),
          page: 1,
          index_id: conteudoNews.id, //773
        },
      });
      setMoreNews(data.rows);
      setQtdNoticias(data.count);
    } catch (error) {
      console.log(error);
    }
  }, [conteudoNews]);

  const handleSearchMore = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage]);

  useEffect(() => {
    fetchData(currentPage);
  }, [fetchData, currentPage]);

  return (
    <Container>
      <CardNews>
        {moreNewsFormatted.map(item => (
          <CardContent
            to={{
              pathname: `/noticias/${item.url}`,
              idLocation: item.id,
            }}
            key={item.id}
            onClick={handleScrollTop}
            style={styleNoticias}
          >
            <img
              src={`${urls.hotsitesImageURL}/${item?.cover?.file}`}
              alt={item?.cover?.file}
            />

            <span style={styleNoticias}>{formatDate(item.date)}</span>

            <p style={styleNoticias}>{item.title}</p>
          </CardContent>
        ))}
      </CardNews>
      {qtdNoticias > 8 &&
        <button onClick={handleSearchMore} style={styleBotao}>Carregar Mais</button>
      }
    </Container>
  );
}
