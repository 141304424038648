import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const getIconBySocialsPlace = (icon, place) => {
  const socialIcons = {
    footer: {
      instagram: require('@abqm/theme/assets/images/icones/instagram-footer.svg'),
      facebook: require('@abqm/theme/assets/images/icones/facebook-footer.svg'),
      youtube: require('@abqm/theme/assets/images/icones/youtube-footer.svg'),
      twitter: require('@abqm/theme/assets/images/icones/twitter-footer.svg'),
    },
    mobile: {
      instagram: require('@abqm/theme/assets/images/icones/instagram-mobile.svg'),
      facebook: require('@abqm/theme/assets/images/icones/facebook-mobile.svg'),
      youtube: require('@abqm/theme/assets/images/icones/youtube-mobile.svg'),
      twitter: require('@abqm/theme/assets/images/icones/twitter-mobile.svg'),
    },
  };

  return socialIcons[place][icon];
};

const Icon = ({ iconName, link, place }) => {
  function renderIcon() {
    return {
      instagram: (
        <img alt="Instagram" src={getIconBySocialsPlace('instagram', place)} />
      ),
      facebook: (
        <img alt="Facebook" src={getIconBySocialsPlace('facebook', place)} />
      ),
      youtube: (
        <img alt="Youtube" src={getIconBySocialsPlace('youtube', place)} />
      ),
      twitter: (
        <img alt="Twitter" src={getIconBySocialsPlace('twitter', place)} />
      ),
    };
  }

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {renderIcon()[iconName]}
    </a>
  );
};

export default function Socials({ type, place, ...rest }) {
  function renderDefault() {
    return (
      <Container type={type} {...rest}>
        <div className="socials">
          <Icon
            iconName="youtube"
            link="https://www.youtube.com/channel/UCI7n-NUxB84gx-A6MgD4YJA"
            place={place}
          />

          <Icon
            iconName="instagram"
            link="https://www.instagram.com/abqmoficial/"
            place={place}
          />

          <Icon
            iconName="facebook"
            link="https://www.facebook.com/abqmoficial/"
            place={place}
          />

          <Icon
            iconName="twitter"
            link="https://www.twitter.com/abqm_oficial/"
            place={place}
          />
        </div>

        <div className="stores">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/br/app/abqm-oficial/id1084358861"
          >
            <img
              className="icon"
              alt="App Store"
              src={require('@abqm/theme/assets/images/badges/app_store.svg')}
            />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=br.com.abqm.abqm&hl=pt_BR"
          >
            <img
              className="icon"
              alt="App Store"
              src={require('@abqm/theme/assets/images/badges/play_store.svg')}
            />
          </a>
        </div>
      </Container>
    );
  }

  function renderFooter() {
    return (
      <Container type={type} {...rest}>
        <div className="stores">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/br/app/abqm-oficial/id1084358861"
          >
            <img
              className="icon"
              alt="App Store"
              src={require('@abqm/theme/assets/images/badges/app_store.svg')}
            />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=br.com.abqm.abqm&hl=pt_BR"
          >
            <img
              className="icon"
              alt="App Store"
              src={require('@abqm/theme/assets/images/badges/play_store.svg')}
            />
          </a>
        </div>

        <div className="socials">
          <Icon
            iconName="youtube"
            link="https://www.youtube.com/channel/UCI7n-NUxB84gx-A6MgD4YJA"
            place={place}
          />

          <Icon
            iconName="instagram"
            link="https://www.instagram.com/p/B7j4To7juEA/"
            place={place}
          />

          <Icon
            iconName="facebook"
            link="https://www.facebook.com/abqmoficial/"
            place={place}
          />

          <Icon
            iconName="twitter"
            link="https://www.twitter.com/abqm_oficial/"
            place={place}
          />
        </div>
      </Container>
    );
  }

  const renders = {
    default: renderDefault(),
    footer: renderFooter(),
  };

  return <>{renders[type]}</>;
}

Socials.defaultProps = {
  type: 'default',
  place: 'footer',
};

Socials.propTypes = {
  type: PropTypes.string,
  place: PropTypes.string,
};

Icon.propTypes = {
  link: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
};
