import styled from 'styled-components';
import media from 'styled-media-query';

import { metrics, colors } from '@abqm/theme';
import { pxToRem } from '@abqm/functions';
import { Header as HeaderC } from '@abqm/components';

import { CategoryNews as CategoryNewsComponent } from '../../components';

export const Page = styled.div`
  position: relative;
  z-index: 0;

  overflow: hidden;
`;

export const Container = styled.div`
  width: 100%;
  margin-top: 0;
  margin: 0 auto;
  max-width: ${pxToRem(1700)};

  ${media.lessThan('1536px')`
    max-width: ${pxToRem(1400)};
  `}

  ${media.lessThan('huge')`
    max-width: ${metrics.maxWidth};
  `}

  ${media.lessThan('large')`
    padding: 0 ${pxToRem(12)};
  `}

  ${media.between('medium', 'large')`
    padding: 0 ${pxToRem(24)};
  `}

  ${media.lessThan('large')`
    display: flex;
    flex-direction: column;
  `}
`;

export const Header = styled(HeaderC)``;

export const Advertisements = styled.div`
  width: 100%;
  margin: ${metrics.baseSpacing(2)} 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.greaterThan('1600px')`
    width: 98%;

    margin: 1rem;
  `}

  ${media.between('1280px', '1280px')`
    padding: 0 ${pxToRem(40)};
  `}

  > div {
    & + div {
      margin-left: ${metrics.baseSpacing(8)};
    }

    ${media.greaterThan('large')`    
      margin: ${metrics.baseSpacing(6)} 0 ${metrics.baseSpacing(2)};
    `}

    ${media.lessThan('medium')`
      margin-bottom: ${metrics.baseSpacing(2)};
    `}
  }
`;

export const CategoryNews = styled(CategoryNewsComponent)`
  padding-bottom: 0;

  ${media.greaterThan('large')`
    margin-bottom: ${({ isResults }) =>
      isResults ? 0 : metrics.baseSpacing(10)};
    padding-bottom: ${({ isResults }) =>
      !isResults ? 0 : metrics.baseSpacing(10)};
  `}
`;

export const GreenContainer = styled.div`
  background-color: ${colors.darkWhite};
`;
