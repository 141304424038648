import React, { useEffect, useState } from 'react';
import { urls, api } from '@abqm/services';

import { Container, Button, Iframe } from './styles';

export default function Play() {
  const [videoInfo, setVideoInfo] = useState({});

  useEffect(() => {
    async function fetchLatestVideo() {
      try {
        const { data } = await api.get('videos?pagesize=1');

        const infos = data.videos[0];

        setVideoInfo(infos);
      } catch (error) {
        console.log('erro', error);
      }
    }

    fetchLatestVideo();
  }, [setVideoInfo]);

  return (
    <Container>
      <div className="left">
        <img
          className="logo"
          alt="ABQM Play Logo"
          src={require('@abqm/theme/assets/images/logos/logo-play.svg')}
        />

        <p className="title">{videoInfo.title}</p>

        <p className="description">{videoInfo.description}</p>

        <Button href={urls.playURL}>Mais videos</Button>
      </div>

      <div className="right">
        <div className="video-shade">
          <Iframe
            url={`https://www.youtube.com/embed/${videoInfo.videoId}`}
            width="100%"
            height="100%"
          />
        </div>

        <Button isMobile href={urls.playURL}>
          Mais vídeos
        </Button>
      </div>
    </Container>
  );
}
