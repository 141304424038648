import styled from 'styled-components';
import media from 'styled-media-query';

import { pxToRem } from '@abqm/functions';
import { metrics, colors, fonts } from '@abqm/theme';

import SocialsComponent from '../Socials';

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${metrics.maxWidth};

  display: flex;
  align-items: center;
  flex-direction: column;

  ${media.lessThan('small')`
    width: 80%;
  `}

  > .logos {
    margin-bottom: ${metrics.baseSpacing(7)};

    display: flex;
    align-items: center;
    justify-content: center;

    ${media.lessThan('medium')`
      flex-direction: row;
      margin-top: ${metrics.baseSpacing(6)};
      margin-bottom: ${metrics.baseSpacing(4)};
    `}

    > .abqm-logo {
      height: ${pxToRem(125)};
      margin-right: ${metrics.baseSpacing(5)};

      ${media.lessThan('small')`
        height: ${pxToRem(78)};
        margin-right: ${metrics.baseSpacing(3)};
        margin-bottom: 0;
      `}
    }

    > .aqha-logo {
      height: ${pxToRem(96)};

      ${media.lessThan('small')`
        height: ${pxToRem(58)};
      `}
    }
  }

  > .contact {
    position: relative;

    width: 100%;
    padding: 0 ${pxToRem(40)};
    margin-bottom: ${metrics.baseSpacing(20)};

    display: flex;
    justify-content: space-between;

    ${media.lessThan('huge')`
      width: 90%;
    `}

    ${media.lessThan('large')`
      width: 100%;
      padding: 0;

      flex-direction: column;
      margin-bottom: ${metrics.baseSpacing(3)};
    `}

    &:before {
      position: absolute;
      top: ${pxToRem(60)};
      left: -10%;

      width: 120%;
      height: ${pxToRem(1)};

      content: '';
      opacity: 0.1;
      background: ${colors.white};

      ${media.lessThan('huge')`
        width: 100%;
        left: 0;
      `}

      ${media.lessThan('large')`
        display: none;
      `}
    }
  .link-wpp{
    color: ${colors.white};
    font-weight: 600;
  }
  }

  > .copyright {
    margin-bottom: ${metrics.baseSpacing(3)};

    opacity: 0.4;
    ${fonts.size1};
    line-height: 1.2;
    text-align: center;
    letter-spacing: 1.2px;
    color: ${colors.darkWhite};
    font-family: SFProDisplay-Regular, sans-serif;
  }

  > .articleLaw {
    margin-bottom: ${metrics.baseSpacing(3)};

    opacity: 0.4;
    ${fonts.size1};
    line-height: 1.2;
    text-align: center;
    letter-spacing: 1.2px;
    color: ${colors.darkWhite};
    font-family: SFProDisplay-Regular, sans-serif;

    img{
      margin-right: 0.5rem;
      margin-bottom: -0.4rem;

      ${media.lessThan('small')`
        margin-right: 0.2rem;
        margin-bottom: -0.1rem;
      `}
    }

    br{
      ${media.lessThan('small')`
        display: none;
      `}
    }
  }

  > .developers {
    display: flex;

    > .text {
      width: ${pxToRem(80)};
      margin-right: ${pxToRem(4)};

      opacity: 0.4;
      ${fonts.size1};
      text-align: left;
      line-height: 1.18;
      letter-spacing: 1.1px;
      color: ${colors.darkWhite};
      font-family: SFProDisplay-Thin, sans-serif;

      ${media.lessThan('large')`
        width: ${pxToRem(150)};
      `}

      ${media.lessThan('medium')`
        width: ${pxToRem(110)};
      `}

      ${media.lessThan('small')`
        width: ${pxToRem(83)};
      `}
    }
    ${media.lessThan('large')`
      margin-bottom: ${metrics.baseSpacing(4)};
    `}

    > .iclouds-logo {
      opacity: 0.6;
    }
  }

  ${media.between('1500px', '1540px')`
    max-width: ${pxToRem(1250)};
  `}
`;

export const Socials = styled(SocialsComponent)`
  margin-bottom: ${metrics.baseSpacing(8)};

  ${media.lessThan('large')`
    margin-bottom: ${metrics.baseSpacing(4)};
  `}
`;
